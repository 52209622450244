import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { HelpersService } from 'src/app/helpers.service'
import { LoginService } from 'src/app/login/login.service'

@Injectable({
  providedIn: 'root'
})
export class stripeService {
  private builderUrl: any

  public options = {
    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  }

  constructor(private helpers: HelpersService, private http: HttpClient, private builderService: BuilderService, private loginService: LoginService) {
    this.builderUrl = this.helpers.getBuilderUrl()
  }

  createSession(price_id: any, newLicense = false) {
    let postData = new URLSearchParams()
    let project_id: any = this.builderService.getSelectedProject()
    let user_email = this.loginService.getCurrentUser().email

    if (this.builderService.selected_pricing_id) {
      postData.set('price_id', this.builderService.selected_pricing_id)
    } else {
      postData.set('price_id', price_id)
    }
    postData.set('project_id', project_id)
    postData.set('email', user_email)
    if (this.builderService.selected_coupon_id) {
      postData.set('coupon_id', this.builderService.selected_coupon_id)
    }
    if (newLicense) {
      postData.set('newLicense', 'true')
    }

    return this.http.post(this.builderUrl + 'create_stripe_session', postData.toString(), this.options)
  }

  changePlan(price_id: any) {
    let postData = new URLSearchParams()
    let project_id: any = this.builderService.getSelectedProject()
    let user_email = this.loginService.getCurrentUser().email

    postData.set('price_id', price_id)
    postData.set('project_id', project_id)
    postData.set('email', user_email)

    return this.http.post(this.builderUrl + 'change_plan', postData.toString(), this.options)
  }

  getInvoices(stripe_subscription_id: string) {
    let postData = new URLSearchParams()

    let project_id: any = this.builderService.getSelectedProject()

    postData.set('email', this.loginService.getCurrentUser().email)
    postData.set('stripe_subscription_id', stripe_subscription_id)
    postData.set('project_id', project_id)

    return this.http.post(this.builderUrl + 'retrieve_invoices', postData.toString(), this.options)
  }

  /**
   * Retrieves detailed Stripe customer data from the back-end.
   *
   * This method constructs a POST request with the customer's email,
   * subscription ID, and project ID. It then calls the `retrieve_full_customer_details`
   * endpoint on the server side, which returns comprehensive information about the
   * customer (invoices, payment methods, subscriptions, charges, and refunds).
   *
   * Steps:
   * 1. Gather necessary data (email, project ID, and subscription ID).
   * 2. Populate a URLSearchParams object with these values.
   * 3. Send a POST request to `retrieve_full_customer_details`.
   * 4. The server response contains the customer's details, which can then be used
   *    throughout the application (e.g., to display invoices, payment methods, etc.).
   *
   * @param stripe_subscription_id The current Stripe subscription identifier.
   * @returns An Observable containing the server's response with detailed customer data.
   */

  retrieveCustomerData(stripe_subscription_id: string) {
    let postData = new URLSearchParams()

    let project_id: any = this.builderService.getSelectedProject()
    let email = this.loginService.getCurrentUser().email

    postData.set('email', this.loginService.getCurrentUser().email)
    postData.set('stripe_subscription_id', stripe_subscription_id)
    postData.set('project_id', project_id)

    return this.http.post(this.builderUrl + 'retrieve_full_customer_details', postData.toString(), this.options)
  }

  /**
   * Retrieves all invoices
   */
  getAllInvoices() {
    return this.http.post(this.builderUrl + 'retrieve_all_invoices', this.options)
  }

  /**
   * Cancels an existing Stripe subscription on the back-end.
   *
   * This method constructs a POST request including the subscription ID
   * and the project ID, then sends it to the `cancel_subscription` endpoint.
   * The back-end is expected to handle the logic for canceling the specified
   * subscription using the Stripe API, and return an appropriate response.
   *
   * Steps:
   * 1. Gather the subscription ID (`stripe_subscription_id`) and the current
   *    project ID from the service.
   * 2. Populate a `URLSearchParams` object with these values.
   * 3. Send a POST request to the `cancel_subscription` endpoint.
   * 4. The server processes the request by canceling the subscription on Stripe
   *    and returns the result (e.g., confirmation of cancellation).
   *
   * @param stripe_subscription_id The unique identifier for the Stripe subscription to cancel.
   * @returns An Observable containing the server's response regarding the cancellation status.
   */
  cancelSubscription(stripe_subscription_id: string) {
    let postData = new URLSearchParams()

    let project_id: any = this.builderService.getSelectedProject()

    postData.set('stripe_subscription_id', stripe_subscription_id)
    postData.set('project_id', project_id)

    return this.http.post(this.builderUrl + 'cancel_subscription', postData.toString(), this.options)
  }

  /**
   * Updates an existing Stripe subscription with a new price plan.
   *
   * @param stripe_subscription_id - The unique identifier of the Stripe subscription to be updated.
   * @param new_price_id - The new price ID that the subscription should be updated to.
   * @returns An Observable representing the HTTP POST request to the backend API endpoint for updating the subscription.
   */
  updateSubscription(stripe_subscription_id: string, new_price_id: string) {
    let postData = new URLSearchParams();

    let project_id: any = this.builderService.getSelectedProject();

    postData.set('stripe_subscription_id', stripe_subscription_id);
    postData.set('new_price_id', new_price_id); // Pass dynamic price ID
    postData.set('project_id', project_id);

    return this.http.post(this.builderUrl + 'update_subscription_plan', postData.toString(), this.options);
  }

  gotoBilling() {
    let postData = new URLSearchParams()
    let user_email = this.loginService.getCurrentUser().email

    postData.set('email', user_email)

    return this.http.post(this.builderUrl + 'create_stripe_billing_session', postData.toString(), this.options)
  }
}

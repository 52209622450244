<div class="headerWrapper" [ngClass]="{ 'arabic': LanguageService.getLanguage() === 'ar' }" >
  <header *ngIf="this.loginService.isLoggedIn()"></header>
  <mat-progress-bar mode="indeterminate" *ngIf="builderService.showUpdateProgress"></mat-progress-bar>
  <mat-progress-bar mode="indeterminate" *ngIf="builderService.showBillingProgress"></mat-progress-bar>
</div>
<div id="mainContentWrapper" [ngClass]="{ 'arabic': LanguageService.getLanguage() === 'ar', 'tourModeEnabled': builderService.getTour() }" (click)="closeSidebars()">
  <sidebar [class.open]="builderService.sidebarOpened" class="default" *ngIf="this.loginService.isLoggedIn() && !this.builderService.getTour() && !mobile" [ngStyle]="{'height': viewHeight}">
  </sidebar>
  <router-outlet></router-outlet>
</div>
<stacks-toastr></stacks-toastr>
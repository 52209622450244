<!-- <div class="howItWorks noselect" (click)="howItWorksDialog()">
  <span class="material-icons-outlined">play_circle_filled</span>
  {{'How it works' | translate }}
</div> -->

<section id="blocksWrapper"
[ngClass]="builderService.blocksCollapsed ? 'collapsedBlocks' : 'openedBlocks'" *ngIf="!builderService.globals">

  <block-image   class="blockWrap" ></block-image>
  <block-static   class="blockWrap blockStatic" ></block-static>
  <block-text    class="blockWrap" ></block-text>
  <stacks-products *ngIf="builderService.standalone" class="blockWrap"></stacks-products>
  <stacks-categories *ngIf="builderService.standalone" class="blockWrap"></stacks-categories>
  <block-slider class="blockWrap"></block-slider>
  <block-video class="blockWrap"></block-video>
  <block-button class="blockWrap"></block-button>
  <block-section class="blockWrap" ></block-section>
  <block-products *ngIf="!builderService.standalone" class="blockWrap"></block-products>
  <block-categories *ngIf="!builderService.standalone" class="blockWrap"></block-categories>
  <block-posts *ngIf="!builderService.standalone" class="blockWrap"></block-posts>
  <block-header class="blockWrap blockHeader" cdkDragDisabled="true"></block-header>
  <block-footer class="blockWrap blockFooter" cdkDragDisabled="true"></block-footer>
  <div *ngIf="builderService.standalone">
    <div class="wordPressText" style="display: flex;">
      <p>{{'WordPress Blocks' | translate }}</p>
    </div>
    <div class="disabledBlocksWrapper">
      <block-products class="blockWrap"></block-products>
      <block-categories class="blockWrap"></block-categories>
      <block-posts class="blockWrap"></block-posts>
    </div>
  </div>
  <div *ngIf="!builderService.standalone">
    <div class="wordPressText" style="display: flex;">
      <p>{{'Native Stacks Blocks' | translate }}</p>
    </div>
    <div class="disabledBlocksWrapper">
    <stacks-products class="blockWrap"></stacks-products>
    <stacks-categories class="blockWrap"></stacks-categories>
  </div>
  </div>

</section>

<section id="blocksWrapper"
[ngClass]="builderService.blocksCollapsed ? 'collapsedBlocks' : 'openedBlocks'" *ngIf="builderService.globals">
  <block-header class="blockWrap blockHeader" cdkDragDisabled="true"></block-header>
  <block-text    class="blockWrap" cdkDragDisabled="true"></block-text>
  <block-image   class="blockWrap" cdkDragDisabled="true"></block-image>
  <block-section class="blockWrap" cdkDragDisabled="true"></block-section>
  <stacks-products *ngIf="builderService.standalone" class="blockWrap" cdkDragDisabled="true"></stacks-products>
  <stacks-categories *ngIf="builderService.standalone" class="blockWrap" cdkDragDisabled="true"></stacks-categories>
  <block-slider class="blockWrap" cdkDragDisabled="true"></block-slider>
  <block-video class="blockWrap" cdkDragDisabled="true"></block-video>
  <block-button class="blockWrap" cdkDragDisabled="true"></block-button>
  <block-footer class="blockWrap blockFooter" cdkDragDisabled="true"></block-footer>
  <button mat-icon-button (click)="blocksHome()" class="blocksHomeMobile" *ngIf="mobile">
    <mat-icon class="material-icons material-icons-outlined">build</mat-icon>
    {{'Blocks' | translate }}
  </button>
</section>
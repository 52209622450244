import { Injectable, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { OrdersService } from '../orders.service';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { SettingsService } from '../../settings/settings.service';
import { ToastService } from 'src/app/pages/toastr/toast.service';

@Injectable({
  providedIn: 'root',
})
export class OrderNotificationService {
  private previousOrdersCount = 0;
  private newOrderSubject = new BehaviorSubject<boolean>(false);
  currency: any;
  private initialized = false; // Track whether the initial load is complete
  private notificationSound = new Audio('/assets/sounds/new-order-notification.mp3'); // Path to your sound file

  constructor(
    private ordersService: OrdersService,
    private toastr: ToastrService,
    private router: Router,
    private SettingsService: SettingsService,
    private toastService: ToastService
  ) {
    this.SettingsService.getSettings().subscribe((response: any) => {
      this.currency = response.currency;
    });

    const savedOrdersCount = sessionStorage.getItem('previousOrdersCount');
    if (savedOrdersCount) {
      this.previousOrdersCount = Number(savedOrdersCount);
    }
    this.initializeOrdersCount();

    setInterval(() => {
      this.checkForNewOrders();
    }, 60000);


  }
  private initializeOrdersCount() {
    this.ordersService.getOrders().subscribe((orders: any) => {
      this.previousOrdersCount = Object.keys(orders).length;
      this.initialized = true;
    });
  }

  checkForNewOrders() {
    if (!this.initialized) return;
    this.ordersService.getOrders().subscribe((orders: any) => {
      const currentOrders = Object.values(orders);
      const currentOrdersCount = currentOrders.length;

      if (currentOrdersCount > this.previousOrdersCount) {
        // Find new orders
        const newOrders = currentOrders.slice(this.previousOrdersCount);

        newOrders.forEach((newOrder: any) => {
          this.playNotificationSound();
          this.toastService.initiate({
            type: 'order',
            title: 'New Order',
            content: 'A new order has been received!',
            orderId: newOrder.humId,
            total: newOrder.total,
            currency: this.currency,
            imgPath: '/favicon.png',
            onClick: () => {
              window.location.href = `/orders/single-order/${newOrder.order_id}`;
            },
          });
      //     const toast = this.toastr.info(
      //       `<img src="/favicon.png" alt="Stacks">
      // A new order has been received!
      // <div class="order-details">
      // <span class="orderId">Order ID: ${newOrder.order_id}</span><br><span class="orderCost">Total: ${this.currency}${newOrder.total}</span>   
      // </div>
      //    `,
      //       'New Order',
      //       {
      //         enableHtml: true,
      //         timeOut: 5000,
      //         positionClass: 'toast-bottom-right',
      //         progressBar: true,
      //         closeButton: true,
      //         tapToDismiss: false,
      //       }
      //     );

      //     toast.onTap.subscribe(() => {
      //       if (
      //         this.router.url.includes(
      //           `/orders/single-order/${newOrder.order_id}`
      //         )
      //       ) {
      //         window.location.reload();
      //       } else {
      //         this.router.navigate([
      //           `/orders/single-order/${newOrder.order_id}`,
      //         ]);
      //       }
      //     });

          this.newOrderSubject.next(true);
        });
      }

      this.previousOrdersCount = currentOrdersCount;

      sessionStorage.setItem(
        'previousOrdersCount',
        String(this.previousOrdersCount)
      );
    });
  }

  getNewOrderNotification() {
    return this.newOrderSubject.asObservable();
  }
  private playNotificationSound() {
    this.notificationSound.load();
    this.notificationSound.play().catch((error) => {
      console.error('Error playing notification sound:', error);
    });
  }

}

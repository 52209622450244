import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { DatabaseService } from 'src/app/builder-services/database.service';
import { OrdersService } from 'src/app/e-commerce/orders/orders.service';
import { SettingsService } from 'src/app/e-commerce/settings/settings.service';
import { AppUsersService } from '../app-users.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

export interface ordersTableColumns {
  id: string;
  date: string;
  time: any;
  payment_method:string;
  user_id: string;
  status: string;
  total: string;
  humId: number;
  action: string;
}

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrl: './user-details.component.scss'
})
export class UserDetailsComponent {

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatTable)
  table!: MatTable<ordersTableColumns>;

  ELEMENT_DATA: ordersTableColumns[] = [];

  public dataSource: any = [];
  pageLoaded = false;
  public ordersColumns: string[] = [];
  orders:any = {};

  @ViewChild(MatSort, { static: true })
  matSort!: MatSort;
  orders_dates: any = [];
  orders_data_counts: any = [];
  public userIdFromRoute: any = [];
  mobile = false;
  userData:any;
  currency = '';
  allowUpdateStatus = false;
  searchText: string = '';
  totalOrdersPrice: any ;
  constructor(private route: ActivatedRoute, public orderService: OrdersService, private settingsService: SettingsService,private builderService: BuilderService,private db: DatabaseService,
    private appUsersService:AppUsersService,private router: Router
  ) { }

  ngOnInit(): void {
    let projectId = String(localStorage.getItem("selectedProject"));

    const routeParams = this.route.snapshot.paramMap;
    this.userIdFromRoute = routeParams.get('userId');
    if (this.userIdFromRoute) {
      this.appUsersService.getUser(projectId,this.userIdFromRoute).subscribe((data: any) => {
        this.userData = data
        this.userData['updated_at']=this.convertUserDate(data.updated_at)
        this.ELEMENT_DATA = [];
          this.dataSource =  this.userData.orders;
      
          this.pageLoaded = true;
          this.builderService.showUpdateProgress = true;
          this.ordersColumns = ['id', 'date','payment_method', 'status', 'total', 'action'];
          this.orders =  this.userData.orders;
          this.renderOrders(this.orders).subscribe((response: any) => {
            // this.ELEMENT_DATA.reverse();
            for (let i = 0; i < this.ELEMENT_DATA.length; i++) {
              this.ELEMENT_DATA[i].humId = i + 1;
          }

          this.totalOrdersPrice = this.ELEMENT_DATA.reduce((sum, element) => {
            return sum + Number(element.total); // Assuming `total` is a number
          }, 0);
          // Update lastIndex in the database
          const lastHumId = this.ELEMENT_DATA.length > 0 ? this.ELEMENT_DATA[this.ELEMENT_DATA.length - 1].humId : 0;
          this.orderService.lastIndex = lastHumId;
          this.orderService.addLastIndex(this.orderService.lastIndex);

          this.dataSource = new MatTableDataSource<ordersTableColumns>(this.ELEMENT_DATA);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.matSort;
          this.table.renderRows();
          });
        });

    }

    if (window.screen.width <= 768) { // 768px portrait
      this.mobile = true;
    }


  }

  
  convertDate = (dateString: string): string => {
    const parts = dateString.split('-');
    return `${parts[2]}-${parts[1]}-${parts[0]}`;
  };
  
  convertUserDate = (dateString: string): string => {
    const isoDate = dateString.split('T')[0]; // "2024-12-24"
    const parts = isoDate.split('-'); // ["2024", "12", "24"]
    return `${parts[2]}-${parts[1]}-${parts[0]}`; // "24-12-2024"
  };
  renderOrders = (result: any) => { 
    return new Observable<any>((observer) => {
      this.builderService.showUpdateProgress = false;
      let ordersList:any = Object.entries(result);
      for (const [id, element] of ordersList) {
        // if (Object.prototype.hasOwnProperty.call(ordersList, key)) {
          // const element = ordersList[key];
            this.ELEMENT_DATA.push(
              { 
                id:id,
                payment_method: element.payment_method, 
                user_id: element.user,
                status: element.status, 
                total: element.total,
                date: element.date,
                time: element.time,
                humId: element.humId,
                action: '',
              },
            );
          }

        this.dataSource = new MatTableDataSource<ordersTableColumns>(this.ELEMENT_DATA);
        setTimeout(() => {
          observer.next(); // Notify the observer that the operation is complete
          observer.complete(); // Complete the Observable
        }, 100);
    });
      
  }

  editOrder = ( id: string ) => {
    this.router.navigate([`/orders/single-order/${id}`], { relativeTo: this.route });
  }

  /**
   * Applies a filter to the data source of the user table.
   * The filter is applied to the status, payment_method,total and date columns of the table.
   * The filter value is taken from the `searchText` property, which is trimmed and converted to lowercase.
   */
  applyFilter() {
    const filterValue = this.searchText
      ? this.searchText.trim().toLowerCase(): '';
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      return (
        data.status?.toLowerCase().includes(filter) ||
        data.payment_method?.toLowerCase().includes(filter) ||
        data.date.includes(filter) ||
        data.total?.toString().includes(filter)

      );  
    };

    this.dataSource.filter = filterValue;
  }
  
}

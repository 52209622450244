<div id="PNWrapper" class="containerWrapper">
  <h1 class="pageTitle">Push Notification</h1>
  <mat-accordion>
    <!-- Main Title: Stacks Broadcaster -->
    <mat-expansion-panel hideToggle (opened)="settingsOpen" (closed)="!settingsOpen" [expanded]="true" [disabled]="true"
      #mep="matExpansionPanel">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span *ngIf="!mobile" (click)="mep.expanded = !mep.expanded; settingsOpen = !settingsOpen;"
            [class.openPanel]="settingsOpen" class="material-icons material-icons-outlined">
            expand_more
          </span>
          <h1>{{ 'Stacks Broadcaster' | translate }}</h1>
        </mat-panel-title>
        <div class="howItWorks noselect" (click)="howItWorksDialog()">
          <span class="material-icons-outlined">play_circle_filled</span>
          {{ 'How it works' | translate }}
        </div>
      </mat-expansion-panel-header>
      <div>

        <h3>{{ 'Your place for push notifications' | translate }}</h3>

    <!--firebase settings -->
    <div id="PNValidator" [formGroup]="PNForm">
      <p class="sectionHint">{{ 'To know how to get thisfields from firebase console' | translate }}
        <a href="https://console.firebase.google.com/u/0/project/_/settings/serviceaccounts/adminsdk?_gl=1*y8lhii*_ga*MTA0ODg4NDI4My4xNzMwNzk1NTkx*_ga_CW55HF8NVT*MTczNTEyNDUwMy4xNjIuMS4xNzM1MTI0NTQ5LjE0LjAuMA.."
          target="_blank">{{ 'please go here' | translate }}</a>
      </p>
          <div class="validatorContainer">
            <div class="senderContainer">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'Database URL' | translate }}</mat-label>
                <input matInput formControlName="databaseURL" placeholder="https://your-project-id.firebaseio.com" type="text">
              </mat-form-field>
            </div>
            <div class="notSettingsContainer">
              <div class="notSettings">
                <label class="fileUpload" for="service_account_keys">{{ 'Firebase Service Keys' | translate }}
                  <input type="file" formControlName="serviceAccountKeys" id="service_account_keys"
                    name="service_account_keys" accept="*"
                    (change)="processFile($event, 'application/octet-stream', 'serviceAccountKeys')" #fileInput>
                </label>
                <div *ngIf="serviceAccountFileName" class="insertedFile">
                  <img src="assets/images/file.png" alt="">
                  <h3>{{ serviceAccountFileName }}</h3>
                </div>
              </div>
            </div>
          </div>
 

  <!-- Validation Form -->
  <p class="sectionHint">{{ 'To know how those fields work' | translate }}
    <a href="https://console.firebase.google.com/u/0/project/_/settings/cloudmessaging/android?_gl=1*y8lhii*_ga*MTA0ODg4NDI4My4xNzMwNzk1NTkx*_ga_CW55HF8NVT*MTczNTEyNDUwMy4xNjIuMS4xNzM1MTI0NTQ5LjE0LjAuMA.."
      target="_blank">{{ 'please go here' | translate }}</a>
  </p>
          <div class="validatorContainer">
            <div class="senderContainer">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'Android Sender ID' | translate }}</mat-label>
                <input matInput formControlName="googleSenderId" placeholder="Android Sender ID" type="text">
              </mat-form-field>
            </div>
            <div class="notSettingsContainer">
              <div class="notSettings">
                <label class="fileUpload" for="google_service_json">{{ 'Google Service JSON' | translate }}
                  <input type="file" formControlName="googleServiceJson" id="google_service_json"
                    name="google_service_json" accept="application/JSON"
                    (change)="processFile($event, 'application/json', 'googleServiceJson')" #fileInput>
                </label>
                <div *ngIf="jsonFileName" class="insertedFile">
                  <img src="assets/images/file.png" alt="">
                  <h3>{{ jsonFileName }}</h3>
                </div>
              </div>

              <div class="notSettings">
                <label class="fileUpload" for="google_service_plist">{{ 'Google Service Plist' | translate }}
                  <input type="file" formControlName="googleServicePlist" id="google_service_plist"
                    name="google_service_plist" accept="*"
                    (change)="processFile($event, 'application/octet-stream', 'googleServicePlist')" #fileInput>
                </label>
                <div *ngIf="plistFileName" class="insertedFile">
                  <img src="assets/images/file.png" alt="">
                  <h3>{{ plistFileName }}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <!-- Push Notification Main Container -->
  <div id="PNMainContainer" *ngIf="PNForm.valid">
    <div id="PNMessageContainer" [formGroup]="PNForm">
      <div id="PNFormFieldsWrapper">
        <mat-label>{{ 'Title' | translate }}</mat-label>
        <mat-form-field appearance="outline">
          <input matInput placeholder="Awesome Offer" max-length="65" formControlName="title"
            value="{{ this.PNForm.value.title }}" (keyup)="characterCounter('title')">
        </mat-form-field>
        <span class="characterCounter" [ngClass]="titleClass">{{ titleCounter }} {{ 'Characters Left' | translate
          }}</span>
        <mat-label>{{ 'Message' | translate }}</mat-label>
        <mat-form-field appearance="outline">
          <textarea matInput placeholder="An offer that you can't miss" max-length="240" formControlName="message"
            (keyup)="characterCounter('message')">{{ this.PNForm.value.message }}</textarea>
        </mat-form-field>
        <span class="characterCounter" [ngClass]="messageClass">{{ messageCounter }} {{ 'Characters Left' | translate
          }}</span>
        <ul>
          <li>
            <mat-label>{{'Link Type' | translate}}</mat-label>

            <mat-form-field appearance="outline">
              <mat-select formControlName="route" [(value)]="PNForm.value.route">
                <mat-option value="/">
                  {{'Home' | translate }}
                </mat-option>
                <mat-option value="products" *ngIf="!builderService.standalone">
                  {{'Products' | translate }}
                </mat-option>
                <mat-option value="/native-allcats">
                  {{'All Products Categories' | translate }}
                </mat-option>
                <mat-option value="products">
                  {{'Products' | translate }}
                </mat-option>
                <mat-option value="/profile">
                  {{'Account Page' | translate }}
                </mat-option>
                <mat-option value="/authentication/native-login">
                  {{'Signup / Signin' | translate }}
                </mat-option>
                <mat-option value="/contact">
                  {{'Contact Us' | translate }}
                </mat-option>
                <mat-option value="/about">
                  {{'About Us' | translate }}
                </mat-option>
                <mat-option value="/my-account">
                  {{'Orders' | translate }}
                </mat-option>
                <mat-option value="/native-submit-order">
                  {{'Cart' | translate }}
                </mat-option>
                <mat-option value="/terms">
                  {{'Terms' | translate }}
                </mat-option>
                <mat-option *ngFor="let customView of builderService.getCustomViews()" value="{{customView.view_name}}">
                  {{customView.view_name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </li>
          <li *ngIf="PNForm.value.route == 'products' && !builderService.standalone">
            <mat-form-field appearance="outline">
              <mat-label>{{'Product' | translate }}</mat-label>
              <mat-select [(value)]="this.PNForm.value.specificRoute">
                <mat-option *ngFor="let product of storedProducts" [value]="product.id">
                  {{product.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </li>
          <li *ngIf="PNForm.value.route == 'products' && builderService.standalone">
            <mat-form-field appearance="outline">
              <mat-label>{{'Standalone Product' | translate}}</mat-label>
              <mat-select [(value)]="this.PNForm.value.specificRoute">
                <mat-option *ngFor="let product of standaloneProductsService.storedStandaloneProducts"
                  [value]="product.id">
                  {{product.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </li>
        </ul>
      </div>
      <div class="tableHeader">
        <h1 class="tableTitle">User List</h1>

        <mat-accordion>
          <mat-expansion-panel hideToggle (opened)="listOpen" (closed)="!listOpen" [expanded]="true" [disabled]="true"
            #mep="matExpansionPanel">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span *ngIf="!mobile" (click)="mep.expanded = !mep.expanded; listOpen = !listOpen;"
                  [class.openPanel]="listOpen" class="material-icons material-icons-outlined">
                  expand_more
                </span>
                <h1>{{ 'list' | translate }}</h1>
              </mat-panel-title>
              <div *ngIf="mep.expanded" class="search">
                <form (ngSubmit)="onSubmit()">
                  <input type="text" matInput placeholder="Search..." [(ngModel)]="searchKey" name="search">
                  <button type="submit">Search user</button>
                </form>
              </div>
            </mat-expansion-panel-header>
            <table mat-table [dataSource]="dataSource">
              <!-- Name Column -->
              <ng-container matColumnDef="name">
                <th class="firstHead" mat-header-cell *matHeaderCellDef><mat-checkbox type="checkbox" name="userId"
                    (click)="checked('all')" [checked]="allChecked"></mat-checkbox>
                  {{ 'name' | translate }}</th>
                <td mat-cell *matCellDef="let element">
                  <mat-checkbox type="checkbox" name="userId" (click)="checked(element)"
                    [checked]="element.isChecked"></mat-checkbox>
                  {{ element.name }}
                </td>
              </ng-container>

              <!-- Email Column -->
              <ng-container matColumnDef="email">
                <th class="lastHead" mat-header-cell *matHeaderCellDef>{{ 'Email' | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element.email }}</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row [class.demo-row-is-clicked]="clickedRows.has(row)"
                *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [length]="length" [pageSize]="50" aria-label="Select page">
            </mat-paginator>
            <div class="btnWrapper">
              <button class="senderBtn" mat-icon-button id="sendPushNotifications" (click)="sendMessage()">
                <span *ngIf="!this.mobile">Send</span>
                <mat-icon class="material-icons-outlined" *ngIf="this.mobile">done</mat-icon>
              </button>
            </div>
          </mat-expansion-panel>
        </mat-accordion>


      </div>
    </div>
    <div class="mobile">
      <div class="currentTime">
        <h2>{{formattedDate}}</h2>
        <h2 class="time">{{formattedTime}}</h2>
      </div>
      <div id="PNPreview">
        <div id="PNTitlePreview"><span *ngIf="!this.PNForm.value.title ">Awesome Offer</span>{{ this.PNForm.value.title }} <h2>now</h2></div>
        <div id="PNMessagePreview"><span *ngIf="!this.PNForm.value.message ">An offer that you can't miss</span>{{ this.PNForm.value.message }}</div>
      </div>

      <div class="appleUtils">
        <span class="material-symbols-outlined">
          flashlight_on
        </span>
        <span class="material-symbols-outlined">
          photo_camera
        </span>
      </div>
    </div>

  </div>
</div>
<form [formGroup]="PNForm" id="PNForm" (ngSubmit)="saveChanges()">
</form>
<!--Main Screen Popup-->
<div *ngIf="data.name == 'mainScreen'">
  <div class="matDialogTitle">
    <h1 mat-dialog-title>{{ 'Welcome to Stacks' | translate }}</h1>
    <span class="material-icons-outlined closeDialog" (click)="onNoClick()"> close </span>
  </div>

  <div mat-dialog-content>
    <iframe class="introVideo" src="https://www.youtube.com/embed/xsVTqzratPs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <h3>{{ 'Develop across' | translate }}</h3>
    <p>{{ 'Learn one way to build' | translate }}</p>

    <h3>{{ 'Speed' | translate }} &amp; {{ 'Performance' | translate }}</h3>
    <p>{{ 'Achieve the maximum speed' | translate }}</p>

    <h3>{{ 'Incredible tooling' | translate }}</h3>
    <p>{{ 'Build features quickly' | translate }}</p>

    <h3>{{ 'Loved by millions' | translate }}</h3>
    <p>{{ 'prototype through global deployment' | translate }}</p>

    <h3>{{ 'What is Angular' | translate }}</h3>

    <p>{{ 'Angular is platform' | translate }}</p>

    <h3>{{ 'Architecture overview' | translate }}</h3>

    <p>{{ 'Angular platform and framework' | translate }}</p>

    <p>{{ 'The basic building blocks of Angular' | translate }}</p>

    <p>{{ 'Components define views' | translate }}</p>

    <p>{{ 'Components use services' | translate }}</p>

    <p>{{ 'Both components and services' | translate }}</p>

    <p>{{ 'The metadata for component class' | translate }}</p>

    <p>{{ 'The metadata for service' | translate }}</p>

    <p>{{ 'An app components typically define' | translate }}</p>
  </div>
    <!--Restricted Page Popup-->
</div>
<div *ngIf="data.name == 'restrictedPage'">
  <div class="restrictedPage">
  <div class="matDialogTitle">
    <h1 mat-dialog-title class="subH1">{{'This package is unsupported. Subscribe now to enjoy all features!' | translate}}</h1>
  </div>

  <div mat-dialog-content>
    <img style="width: 100%" src="assets/images/restricted_page.png"   alt="">
    <button [routerLink]="['/billing']" (click)="closeDialog('mainScreen_Self_LTD')" class="stacksBtn blue sub">{{'Subscribe Now' | translate}}</button>

  </div>
</div></div> 
<!--Subscription Status Popup-->
<div *ngIf="data.name == 'subscriptionStatus'">
  <div class="mainWrapper">
    <div
      class="bgImg"
      [ngClass]="{
        bgImgEx: showMore && this.premiumData.type === 'Starter',
        bgImgExG: showMore && this.premiumData.type === 'Growth',
        bgImgOnhold: this.projectData.status === 'onhold',
        bgImgCancelled: this.projectData.status === 'cancelled'
      }"
    ></div>
    <div class="headerWrapper">
      <div
        class="statusImg"
        [ngClass]="{
          statusImgOnhold: this.projectData.status === 'onhold',
          statusImgCancelled: this.projectData.status === 'cancelled'
        }"
      >
        <img src="./assets/images/subscriptionFlow/starter-inner-icon.png" />
      </div>
      <div class="details">
        <h1 [ngClass]="{ packageName: this.projectData.status === 'suspended' }">{{ this.premiumData.type }}</h1>
        <h3 *ngIf="this.projectData.status === 'active'">Your Subscription Plan</h3>
        <h3 *ngIf="this.projectData.status === 'onhold'" class="inActiveText">Your Subscription is on hold</h3>
        <h3 *ngIf="this.projectData.status === 'cancelled'" class="inActiveText">Your Subscription has been cancelled</h3>
      </div>
      <div
        class="statusInput"
        [ngClass]="{
          statusInputOnhold: this.projectData.status === 'onhold',
          statusInputCancelled: this.projectData.status === 'cancelled'
        }"
      >
        <h2>{{ this.projectData.status }}</h2>
      </div>
    </div>

    <div class="mainContent">
      <div class="leftSection">
        <h1 id="leftH1">Included in your plan</h1>
        <ul>
          <li *ngFor="let feature of this.displayedFeatures">{{ feature.name }}</li>
          <div *ngIf="showMore">
            <li *ngFor="let feature of this.hiddenFeatures">{{ feature.name }}</li>
          </div>
        </ul>
      </div>
      <div class="rightSection">
        <h1 id="rightH1">Billing and payment</h1>
        <div class="billingDataUp">
          <span class="material-icons">credit_card</span>
          <p *ngIf="this.SubscriptionFlow.stripe?.payment_methods?.card?.last4">
            MasterCard ending ****{{ this.SubscriptionFlow.stripe?.payment_methods?.card?.last4 }}
          </p>
          <p *ngIf="!this.SubscriptionFlow.stripe?.payment_methods">
            No payment methods available.
          </p>          
        </div>
        <div class="billingDataDown">
          <span class="material-icons">calendar_today</span>
          <div class="paymentDetails">
            <p>{{this.premiumData.type}} {{this.SubscriptionFlow.stripe?.customer?.currency }} {{this.SubscriptionFlow.stripe?.charges[0].amount / 100 }} / {{this.premiumData.period }}</p>
            <p *ngIf="this.SubscriptionFlow.stripe?.upcoming_invoice?.next_payment_attempt" id="nxtPaymentDetails">{{'Next payment on' | translate}} {{ this.SubscriptionFlow.stripe?.upcoming_invoice?.next_payment_attempt * 1000 | date : 'MMMM d, y' }} {{'Annual plan, paid' | translate}} {{ this.premiumData.period }}</p>
            <p *ngIf="!this.SubscriptionFlow.stripe?.upcoming_invoice?.next_payment_attempt" id="nxtPaymentDetails">{{'No scheduled payment.' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <p (click)="toggleFeatures()">
        {{ showMore ? 'Show Less' : 'Show More' }}
      </p>

      <span *ngIf="this.projectData.status != 'cancelled'" [routerLink]="['/billing']" (click)="closeDialog('subscriptionStatus')"><p style="margin-right: 65px">Manage Plan</p></span>
      <span *ngIf="this.projectData.status === 'cancelled'" (click)="stripeService.changePlan(priceId)"><p style="margin-right: 65px">Renew your payment</p></span>
    </div>
  </div>
</div>
<div *ngIf="data.name == 'mainScreen_Self_LTD'">
  <div class="matDialogTitle">
    <h1 mat-dialog-title>{{ 'Welcome to Stacks' | translate }}</h1>
    <span class="material-icons-outlined closeDialog" (click)="onNoClick()"> close </span>
  </div>

  <div mat-dialog-content>
    <!-- <iframe class="introVideo" src="https://www.youtube.com/embed/xsVTqzratPs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
    <img style="width: 100%" src="assets/images/popups/selfLTDBanner.png" alt="" />
    <button [routerLink]="['/billing']" (click)="closeDialog('mainScreen_Self_LTD')" class="stacksBtn red">{{ 'Get Offer Now' | translate }}</button>
  </div>
  <!-- <div mat-dialog-actions>
      
  </div> -->
</div>

<div *ngIf="data.name == 'appPreview'">
  <div class="matDialogTitle">
    <h1 mat-dialog-title>{{ 'Real preview your application' | translate }}</h1>
    <span class="material-icons-outlined closeDialog" (click)="onNoClick()"> close </span>
  </div>

  <div mat-dialog-content>
    <iframe class="introVideo" src="https://www.youtube.com/embed/5U14MWNiFqw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <h3>{{ 'Preview App on real Devices' | translate }}</h3>
    <p>{{ 'After you have generated application' | translate }}</p>
    <h3>{{ 'Real Time changes' | translate }}</h3>
    <p>{{ 'Once you go to the builder and make your changes' | translate }}</p>
  </div>
</div>

<div *ngIf="data.name == 'onboardingFeedback'">
  <div class="ratingWrapper" mat-dialog-content>
    <h1 class="experience">{{ 'rate your onboarding experience' | translate }}</h1>
    <app-star-rating></app-star-rating>
    <textarea id="w3review" name="w3review" rows="4" cols="50" placeholder="Leave a comment" #commentInput></textarea>
    <div class="buttonContainer">
      <button (click)="submitFeedback(commentInput.value)" class="Send">{{ 'Send' | translate }}</button>
    </div>
  </div>
</div>

<div *ngIf="data.name == 'onboardingOffer'">
  <div class="ratingWrapper" mat-dialog-content>
    <h1 class="experience">
      {{ 'Claim Offer now' | translate }} <br />
      {{ 'increase your revenue' | translate }}
    </h1>

    <ul>
      <li>
        <img src="assets/images/popups/lifetime.png" />
        <span
          >{{ 'Limited' | translate }} <br />
          {{ 'Lifetime Offer' | translate }}</span
        >
      </li>
      <li>
        <img src="assets/images/popups/design_app.png" />
        <span
          >{{ 'We will' | translate }} <br />
          {{ 'design for you' | translate }}</span
        >
      </li>
      <li>
        <img src="assets/images/popups/app-upload.png" />
        <span
          >{{ 'Upload to Google' | translate }} <br />
          {{ 'App Stores' | translate }}</span
        >
      </li>
    </ul>
    <div class="buttonContainer">
      <button class="stacksBtn green" (click)="closeDialog('onboardingOffer')">{{ 'Skip' | translate }}</button>
      <button (click)="claimOffer('onboardingOffer')" class="Send">{{ 'Claim Offer' | translate }}</button>
    </div>
  </div>
</div>

<div *ngIf="data.name == 'globals'">
  <div class="matDialogTitle">
    <h1 mat-dialog-title>{{ 'Play with your App fonts' | translate }}</h1>
    <span class="material-icons-outlined closeDialog" (click)="onNoClick()"> close </span>
  </div>

  <div mat-dialog-content>
    <iframe class="introVideo" src="https://www.youtube.com/embed/Bz0we5EUcRs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <p>{{ 'Stacks Builder has more than 1000' | translate }}</p>
    <p>{{ 'All you need to do' | translate }}</p>
    <p>{{ 'Choose the font you would like' | translate }}</p>
    <img class="dialogImg" src="https://builder.stacksmarket.co/wp-content/uploads/2022/01/branding.gif" alt="" />
    <p>{{ 'Adjust the font size' | translate }}</p>
    <img class="dialogImg" src="https://builder.stacksmarket.co/wp-content/uploads/2022/01/branding2.gif" alt="" />

    <p>{{ 'change global header and footer' | translate }}</p>
  </div>
</div>
<div *ngIf="data.name == 'connectivity'">
  <div class="matDialogTitle">
    <h1 mat-dialog-title>{{ 'connects your website with Stacks builder' | translate }}</h1>
    <span class="material-icons-outlined closeDialog" (click)="onNoClick()"> close </span>
  </div>

  <div mat-dialog-content>
    <!-- <iframe class="introVideo" src="https://www.youtube.com/embed/Bz0we5EUcRs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
    <p>{{ 'Download our latest plugin add it to your WordPress' | translate }}</p>
    <p>{{ 'Please make sure you are connecting' | translate }}</p>
    <p>{{ 'Your server is connected to domain' | translate }}</p>
    <p>"{{ 'https' | translate }}"</p>
    <p>{{ 'Make sure Stacks plugin is active' | translate }}</p>
    <img class="dialogImg" src="https://builder.stacksmarket.co/wp-content/uploads/2022/01/connectivity.png" alt="" />
    <p>{{ 'If you received any connectivity error' | translate }}</p>
    <p>{{ 'this URL should work' | translate }}</p>
    <p>{{ 'For multisite and the URL' | translate }}</p>
  </div>
</div>

<div *ngIf="data.name == 'pushNotifications'">
  <div class="matDialogTitle">
    <h1 mat-dialog-title>{{ 'Set up the push notifications' | translate }}</h1>
    <span class="material-icons-outlined closeDialog" (click)="onNoClick()"> close </span>
  </div>

  <div mat-dialog-content>
    <iframe class="introVideo" src="https://www.youtube.com/embed/nHFT8Ufr9fI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <p>{{ 'send to all the users' | translate }}</p>
    <h3>{{ 'Here is How it works' | translate }}</h3>
    <p>{{ 'After you have followed' | translate }}</p>
    <img class="dialogImg" src="https://builder.stacksmarket.co/wp-content/uploads/2022/01/pn1.png" alt="" />
    <p>{{ 'you can reach a selected customer' | translate }}</p>
    <img class="dialogImg" src="https://builder.stacksmarket.co/wp-content/uploads/2022/01/pn2.png" alt="" />
    <p>{{ 'Write your message in the text fields' | translate }}</p>
    <img class="dialogImg" src="https://builder.stacksmarket.co/wp-content/uploads/2022/01/pn3.png" alt="" />
    <p>{{ 'Also dont forget to add emojis' | translate }}</p>
    <img class="dialogImg" src="https://builder.stacksmarket.co/wp-content/uploads/2022/01/pn4.png" alt="" />
  </div>
</div>
<div *ngIf="data.name == 'messageSent'">
  <div class="matDialogTitle closeMessage">
    <span class="material-icons-outlined closeDialog" (click)="onNoClick()"> close </span>
  </div>

  <div mat-dialog-content class="messageWrapper">
    <div class="gifWrapper">
      <img *ngIf="!gifRemoved" id="gif" class="messageSentGif" src="assets/gifs/new gifs/check.gif" alt="" />
      <img *ngIf="gifRemoved" id="gif" class="messageSentGif gifToImage" src="assets/images/popups/check.webp" alt="" />
    </div>
    <h3>{{ 'Message Sent Successfully' | translate }}</h3>
  </div>
</div>

<div *ngIf="data.name == 'templates'">
  <div class="matDialogTitle">
    <h1 mat-dialog-title>{{ 'A wide variety of templates' | translate }}</h1>
    <span class="material-icons-outlined closeDialog" (click)="onNoClick()"> close </span>
  </div>

  <div mat-dialog-content>
    <p>{{ 'Stacks is trying to make it easy' | translate }}</p>
  </div>
</div>

<div *ngIf="data.name == 'builderHome'">
  <div class="matDialogTitle">
    <h1 mat-dialog-title>{{ 'A wide variety of templates' | translate }}</h1>
    <span class="material-icons-outlined closeDialog" (click)="onNoClick()"> close </span>
  </div>

  <div mat-dialog-content>
    <img [src]="currentGifPath" alt="Template GIF" class="template-gif" style="width: 100%" />
    <button class="nextGif" (click)="loadNextGif()">{{ 'Next' | translate }}</button>
  </div>
</div>

<div *ngIf="data.name == 'android'">
  <div class="matDialogTitle">
    <h1 mat-dialog-title>{{ 'Build your Android application' | translate }}</h1>
    <span class="material-icons-outlined closeDialog" (click)="onNoClick()"> close </span>
  </div>

  <div mat-dialog-content>
    <iframe class="introVideo" src="https://www.youtube.com/embed/WrBt-GDjCZ0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <p>{{ 'Before you start generating your app' | translate }}</p>
    <p>{{ 'After you have entered your version' | translate }}</p>
    <p>{{ 'Once your application is ready' | translate }}</p>
  </div>
</div>

<div *ngIf="data.name == 'ios'">
  <div class="matDialogTitle">
    <h1 mat-dialog-title>{{ 'Generate your Application and upload' | translate }}</h1>
    <span class="material-icons-outlined closeDialog" (click)="onNoClick()"> close </span>
  </div>

  <div mat-dialog-content>
    <iframe class="introVideo" src="https://www.youtube.com/embed/Zfcz8otrcfI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <p>{{ 'In order to view your IOS Application' | translate }}</p>
    <p>{{ 'For Apple you will need' | translate }}</p>
    <p>{{ 'When you connect your developer account' | translate }}</p>
  </div>
</div>
<div *ngIf="data.name == 'templateLocked'">
  <div class="matDialogTitle">
    <h1 mat-dialog-title>{{ 'Upgrade to unlock' | translate }}</h1>
  </div>

  <div mat-dialog-content>
    <p>{{ 'Sorry this template is locked' | translate }}</p>
    <a href="#" class="stacksBtn green">{{ 'Subscribe Now' | translate }}</a>
  </div>
</div>

<div *ngIf="data.name == 'viewLocked'">
  <div class="matDialogTitle">
    <h1 mat-dialog-title>{{ 'Upgrade to unlock' | translate }}</h1>
  </div>

  <div mat-dialog-content>
    <p>{{ 'Sorry this view is locked' | translate }}</p>
    <a href="#" class="stacksBtn green">{{ 'Subscribe Now' | translate }}</a>
  </div>
</div>

<div class="checklist" *ngIf="data.name == 'customizeDesign'">
  <div class="matDialogTitle">
    <h1 mat-dialog-title>{{ 'Add Block Customize Design' | translate }}</h1>
    <span class="material-icons-outlined closeDialog" (click)="onNoClick()"> close </span>
  </div>

  <div mat-dialog-content>
    <h2 class="first">{{ 'Add Block' | translate }}</h2>
    <p>{{ 'Click any block to add' | translate }}</p>
    <img class="dialogImg" src="assets/images/checklist/Add_Blocks.gif" alt="" />

    <h2>{{ 'Relocate Blocks' | translate }}</h2>
    <p>{{ 'Once block is added' | translate }}</p>
    <img src="assets/images/checklist/dragBlocks.gif" alt="" />

    <h2>{{ 'Edit Block' | translate }}</h2>
    <p>{{ 'If you click on edit you can customize' | translate }}</p>
    <img src="assets/images/checklist/Customize_Design.gif" alt="" />

    <div class="buttonWrapper">
      <button (click)="completeTour(0)">{{ 'Done' | translate }}</button>
    </div>
  </div>
</div>
<div class="checklist" *ngIf="data.name == 'addViews'">
  <div class="matDialogTitle">
    <h1 mat-dialog-title>{{ 'Customize Add Views' | translate }}</h1>
    <span class="material-icons-outlined closeDialog" (click)="onNoClick()"> close </span>
  </div>

  <div mat-dialog-content>
    <h2 class="first">{{ 'Choose View' | translate }}</h2>
    <p>{{ 'You can customize any view of the application' | translate }}</p>
    <img class="dialogImg" src="assets/images/checklist/choose_view_to_edit.gif" alt="" />

    <h2>{{ 'Views Default Blocks' | translate }}</h2>
    <p>{{ 'The blurred sections are default blocks' | translate }}</p>
    <img src="assets/images/checklist/blured_block.gif" alt="" />

    <h2>{{ 'Customize View' | translate }}</h2>
    <p>{{ 'add unlimited views to serve different purposes' | translate }}</p>
    <img src="assets/images/checklist/add_view.gif" alt="" />

    <div class="buttonWrapper">
      <button (click)="completeTour(1)">{{ 'Done' | translate }}</button>
    </div>
  </div>
</div>
<div class="checklist" *ngIf="data.name == 'previewChanges'">
  <div class="matDialogTitle">
    <h1 mat-dialog-title>{{ 'Preview Changes' | translate }}</h1>
    <span class="material-icons-outlined closeDialog" (click)="onNoClick()"> close </span>
  </div>

  <div mat-dialog-content>
    <p class="first">{{ 'After you change the design click Update' | translate }}</p>
    <img class="dialogImg" src="assets/images/checklist/perview.gif" alt="" />

    <div class="buttonWrapper">
      <button (click)="completeTour(2)">{{ 'Done' | translate }}</button>
    </div>
  </div>
</div>
<div class="checklist" *ngIf="data.name == 'bookDemo'">
  <div class="matDialogTitle">
    <h1 mat-dialog-title>{{ 'Book Demo' | translate }}</h1>
    <span class="material-icons-outlined closeDialog" (click)="onNoClick()"> close </span>
  </div>

  <div mat-dialog-content>
    <p class="first">{{ 'need help with the design' | translate }}</p>
    <div class="buttonWrapper">
      <button [routerLink]="['help']" (click)="completeTour(3)">{{ 'Book Demo Call' | translate }}</button>
    </div>
  </div>
</div>
<div class="checklist" *ngIf="data.name == 'addProduct'">
  <div class="matDialogTitle">
    <h1 mat-dialog-title>{{ 'Add Products (Connect-Site)' | translate }}</h1>
    <span class="material-icons-outlined closeDialog" (click)="onNoClick()"> close </span>
  </div>

  <div mat-dialog-content>
    <p class="first">{{ 'Stacks integrated with WooCommerce' | translate }}</p>
    <p>{{ 'To Get started go to the Connectivity' | translate }}</p>
    <p>{{ 'Once connected' | translate }}</p>
    <img class="dialogImg" src="assets/images/checklist/add_product.gif" alt="" />

    <div class="buttonWrapper">
      <button (click)="completeTour(4)">{{ 'Done' | translate }}</button>
    </div>
  </div>
</div>
<div class="checklist" *ngIf="data.name == 'generateApp'">
  <div class="matDialogTitle">
    <h1 mat-dialog-title>{{ 'Generate-App-(Android-IOS)' | translate }}</h1>
    <span class="material-icons-outlined closeDialog" (click)="onNoClick()"> close </span>
  </div>

  <div mat-dialog-content>
    <p class="first">{{ 'Now that your App is ready to be live' | translate }}</p>

    <p>{{ 'Go to Android or IOS' | translate }}</p>

    <p>{{ 'For IOS connect iTunes account first' | translate }}</p>

    <img src="assets/images/checklist/app_name_and_build.gif" alt="" />

    <div class="buttonWrapper">
      <button (click)="completeTour(5)">{{ 'Done' | translate }}</button>
    </div>
  </div>
</div>
<div class="checklist" *ngIf="data.name == 'sendPush'">
  <div class="matDialogTitle">
    <h1 mat-dialog-title>{{ 'Send Push Notification' | translate }}</h1>
    <span class="material-icons-outlined closeDialog" (click)="onNoClick()"> close </span>
  </div>

  <div mat-dialog-content>
    <p class="first">{{ 'Since you have the App on your phone' | translate }}</p>

    <p>
      {{ 'Connect Firebase following' | translate }} <a class="guideLink" href="https://stacksmarket.co/knowledge-base/activate-push-notifications-on-your-mobile-application/"> {{ 'guide' | translate }} </a>
    </p>

    <p>{{ 'Go to Push Notification' | translate }}</p>

    <img src="assets/images/checklist/push_notification.gif" alt="" />

    <div class="buttonWrapper">
      <button (click)="completeTour(6)">{{ 'Done' | translate }}</button>
    </div>
  </div>
</div>
<div class="checklist" *ngIf="data.name == 'uploadApp'">
  <div class="matDialogTitle">
    <h1 mat-dialog-title>{{ 'Upload App' | translate }}</h1>
    <span class="material-icons-outlined closeDialog" (click)="onNoClick()"> close </span>
  </div>

  <div mat-dialog-content>
    <p class="first">{{ 'Everything is now ready to go live' | translate }}</p>

    <p>{{ 'Follow these guides to publish' | translate }}</p>

    <a href="https://stacksmarket.co/knowledge-base/apple-build-guide/" target="_blank">{{ 'IOS' | translate }}</a>

    <a href="https://support.google.com/googleplay/android-developer/answer/9859152?hl=en" target="_blank">{{ 'Android' | translate }}</a>

    <div class="buttonWrapper">
      <button (click)="completeTour(7)">{{ 'Done' | translate }}</button>
    </div>
  </div>
</div>
<div *ngIf="data.name == 'tourCompleted'">
  <div class="matDialogTitle">
    <h1 mat-dialog-title>{{ 'Congratulations Tour Completed' | translate }}</h1>
    <span class="material-icons-outlined closeDialog" (click)="onNoClick()"> close </span>
  </div>

  <div mat-dialog-content>
    <img src="assets/images/tourDone.png" />
    <h1>{{ 'Now you know all the basic views' | translate }}</h1>
    <p>{{ 'Need help Send us a message' | translate }}</p>
  </div>
</div>

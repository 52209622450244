import { BuilderService } from 'src/app/builder-services/builder.service';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/builder-services/database.service';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

/**
 * Component: MultilingualComponent
 *
 * **Description:**
 * The `MultilingualComponent` manages the multilingual functionality of an application,
 * allowing users to handle a list of languages and select a default language. It provides
 * features to load existing languages from a database, add or delete languages, and synchronize
 * the selected languages with the UI.
 *
 * **Key Features:**
 * 1. **Language Management:**
 *    - Load languages from the database.
 *    - Automatically add "English" as the default language if no languages are present.
 *    - Add new languages dynamically via a dropdown.
 *    - Remove languages from the list while ensuring at least one language remains.
 *
 * 2. **Default Language Selection:**
 *    - Allows the user to select a default language from the available options.
 *    - Saves the default language to the database.
 *
 * 3. **Form Handling:**
 *    - Utilizes Angular's `FormBuilder` to manage the form for multilingual data.
 *    - Dynamically updates the list of languages in the table and dropdown.
 *
 * 4. **UI Integration:**
 *    - Includes a table to display the list of languages.
 *    - Uses `mat-select` for selecting multiple languages with dynamic updates.
 *    - Progress bar to indicate ongoing database operations.
 *
 * **Key Properties:**
 * - `defaultLanguage`: Stores the current default language.
 * - `language`: Array of objects representing the list of languages with their titles.
 * - `languagesToDelete`: Tracks languages marked for deletion.
 * - `multilingual`: Angular `FormGroup` for managing the multilingual form.
 * - `selectedLanguages`: Array of currently selected languages in the dropdown.
 * - `productProgress`: Boolean to indicate the progress of database operations.
 * - `languageCodes`: Array of predefined language codes for selection.
 *
 * **Key Methods:**
 * - `ngOnInit`: Initializes the component by loading languages and setting up the form.
 * - `initForm`: Initializes the multilingual form group.
 * - `loadLanguages`: Loads languages from the database and synchronizes them with the UI.
 * - `onLanguagesSelected`: Handles the selection of languages from the dropdown.
 * - `deleteRow`: Deletes a language from the list after user confirmation.
 * - `onSubmit`: Saves the current list of languages and the default language to the database.
 * - `selectDefaultLanguage`: Sets a selected language as the default.
 * - `refreshTable`: Refreshes the UI table to reflect changes in the language list.
 * - `getLanguageName`: Converts language keys to user-friendly display names.
 * - `getLanguageCodeKeys`: Retrieves the array of predefined language codes.
 *
 * **Dependencies:**
 * - Services:
 *    - `BuilderService`: Manages project-specific data.
 *    - `DatabaseService`: Handles database interactions for loading and saving language data.
 * - Angular Material Modules:
 *    - `MatFormFieldModule`, `MatSelectModule`, `MatOptionModule`, `MatTableModule`, `MatProgressBarModule`.
 * - Angular Core Modules:
 *    - `CommonModule`, `FormsModule`, `ReactiveFormsModule`.
 * - Third-Party Library:
 *    - `TranslateModule`: Provides translation capabilities for the UI.
 *
 * **Purpose:**
 * This component simplifies the management of multilingual functionality, enabling seamless
 * integration of multiple languages in the application and providing an intuitive user interface
 * for language selection and default language management.
 */

@Component({
  selector: 'app-multilingual',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    TranslateModule,
    MatTableModule,
    ReactiveFormsModule,
    MatProgressBarModule,
  ],
  templateUrl: './multilingual.component.html',
  styleUrls: ['./multilingual.component.scss'],
})
export class MultilingualComponent implements OnInit {
  defaultLanguage: string = '';
  language: { title: string; isNew?: boolean }[] = [];
  languagesToDelete: string[] = [];
  multilingual!: FormGroup;
  selectedLanguages: string[] = [];
  productProgress: boolean = false;
  languageCodes: string[] = [
    'Arabic',
    'Armenian',
    'Bengali',
    'Bulgarian',
    'Burmese',
    'Chichewa',
    'Chinese_simplified',
    'Chinese_traditional',
    'Croatian',
    'Czech',
    'Danish',
    'Dari',
    'Dutch',
    'English',
    'Estonian',
    'Fijian',
    'Filipino',
    'Finnish',
    'French',
    'Frisian',
    'Gaelic',
    'Georgian',
    'German',
    'Hebrew',
    'Hindi',
    'Hungarian',
    'Indonesian',
    'Inuktitut',
    'Italian',
    'Japanese',
    'Kannada',
    'Khmer',
    'Korean',
    'Kurdish_sorani',
    'Kyrgyz',
    'Lao',
    'Latvian',
    'Lithuanian',
    'Malagasy',
    'Malay',
    'Malayalam',
    'Maltese',
    'Marathi',
    'Nauru',
    'Nepali',
    'Norwegian',
    'Pashto',
    'Persian',
    'Portuguese',
    'Punjabi',
    'Quichua',
    'Romanian',
    'Russian',
    'Samoan',
    'Serbian',
    'Serbo-croatian',
    'Sindhi',
    'Sinhala',
    'Slovak',
    'Slovenian',
    'Somali',
    'Sotho',
    'Spanish',
    'Sudanese',
    'Swahili',
    'Swedish',
    'Tagalog',
    'Tamil',
    'Telugu',
    'Thaana',
    'Thai',
    'Tigrinya',
    'Tongan',
    'Turkish',
    'Ukrainian',
    'Urdu',
    'Uyghur',
    'Uzbek',
    'Vanuatu',
    'Vietnamese',
    'Welsh',
    'Yiddish',
    'Zulu',
  ];

  constructor(
    private builderService: BuilderService,
    private db: DatabaseService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.loadLanguages();
    this.initForm();
  }

  /**
   * Initializes the form group for managing multilingual data.
   * Sets up the `multilingual` FormGroup with a `languages` FormArray
   * to dynamically handle the list of languages.
   */

  initForm(): void {
    this.multilingual = this.fb.group({
      languages: this.fb.array([]),
    });
  }

  /**
   * Function: loadLanguages
   * Loads the list of languages and the default language from the database.
   *
   * **Steps:**
   * 1. Retrieves the project ID to construct database paths:
   *    - `languagesPath`: Path to the list of translations.
   *    - `defaultLangPath`: Path to the default language.
   *
   * **For `languagesPath`:**
   * - If languages are found:
   *   - Populates `this.language` with the existing languages as an array of objects with `title`.
   *   - Synchronizes the `selectedLanguages` array with the titles of the loaded languages for the dropdown.
   * - If no languages are found:
   *   - Logs a warning message.
   *   - Initializes `this.language` with "English" as the default language.
   *   - Sets `selectedLanguages` to `['English']` to reflect the default selection in the dropdown.
   *   - Updates the database with "English" as the default language.
   *
   * **For `defaultLangPath`:**
   * - Retrieves the default language for the project:
   *   - If a default language exists, it is set to `this.defaultLanguage`.
   *   - If no default language is found, it defaults to "English".
   *
   * **Error Handling:**
   * - Logs any errors that occur while retrieving or saving data to the database.
   *
   * **Side Effects:**
   * - Updates the UI table and the dropdown to reflect the loaded or default languages.
   */

  loadLanguages(): void {
    const project_id = this.builderService.selectedProject;
    const languagesPath = `/projects/${project_id}/translations/languages`;
    const defaultLangPath = `/projects/${project_id}/translations`;

    this.db.getDatabase(languagesPath).subscribe((existingLanguages: any) => {
      if (existingLanguages && Object.keys(existingLanguages).length > 0) {
        // Populate the table with existing languages
        this.language = Object.keys(existingLanguages).map((title) => ({
          title,
        }));

        // Synchronize selectedLanguages with table data
        this.selectedLanguages = this.language.map((lang) => lang.title);
      } else {
        // No languages found; add "English" by default
        console.warn('No languages data found. Adding "English" by default.');
        this.language = [{ title: 'English' }];
        this.selectedLanguages = ['English'];
        this.db.setDatabase(languagesPath, { English: '' }).subscribe(
          (response) => {
            if (response) {
              console.log('"English" added as the default language.');
            }
          },
          (error) => {
            console.error('Error adding default language "English":', error);
          }
        );
      }

      this.refreshTable();
    });

    this.db.getDatabase(defaultLangPath).subscribe(
      (defaultLang: any) => {
        this.defaultLanguage = defaultLang?.defaultLanguage || 'English'; // Default to "English"
      },
      (error) => {
        console.error('Error retrieving default language:', error);
        this.defaultLanguage = 'English';
      }
    );
  }

  /**
   * Function: onLanguagesSelected
   *
   * Handles the selection of languages from the dropdown.
   *
   * **Parameters:**
   * - `event`: The selection change event from the `mat-select` dropdown, which contains the newly selected values.
   *
   * **Steps:**
   * 1. Extracts the selected keys (`selectedKeys`) from the event's `value` property.
   * 2. Updates the `selectedLanguages` array with the newly selected keys to synchronize with the dropdown.
   * 3. Iterates through `selectedKeys` to:
   *    - Convert each key to its display name using `getLanguageName(key)`.
   *    - Check if the language is already in `this.language`.
   *    - If not, adds it to the `this.language` array as an object with a `title` property.
   * 4. Calls `refreshTable()` to update the UI and reflect the changes in the table.
   *
   * **Purpose:**
   * - Ensures the languages selected in the dropdown are dynamically added to the table.
   * - Prevents duplicate entries in the table.
   *
   * **Side Effects:**
   * - Synchronizes the dropdown (`selectedLanguages`) with the table (`this.language`).
   * - Updates the UI table to reflect the newly selected languages.
   */


  onLanguagesSelected(event: any): void {
    const selectedKeys: string[] = event.value; // Get selected values from mat-select
    this.selectedLanguages = selectedKeys;

    // Add selected languages to the table if not already present
    selectedKeys.forEach((key: string) => {
      const languageName = this.getLanguageName(key);
      if (!this.language.some((lang) => lang.title === languageName)) {
        this.language.push({ title: languageName });
      }
    });

    this.refreshTable();
  }

  isLanguageInTable(language: string): boolean {
    return this.language.some(
      (lang) => lang.title === this.getLanguageName(language)
    );
  }

  /**
   * Function: deleteRow
   *
   * Deletes a language from the table if it meets the conditions and updates the dropdown state.
   *
   * **Parameters:**
   * - `rowTitle`: The title of the language to be deleted.
   *
   * **Steps:**
   * 1. Checks if there is more than one language in the table:
   *    - If yes:
   *      - Prompts the user for confirmation to delete the specified language.
   *      - If confirmed:
   *        - Adds the language title to the `languagesToDelete` array for future reference (e.g., database updates).
   *        - Removes the language from the `this.language` array.
   *        - Finds the corresponding key in the `languageCodes` array using the `getLanguageName` function.
   *        - Removes the corresponding key from the `selectedLanguages` array, making the language selectable again in the dropdown.
   *        - Calls `refreshTable()` to update the UI and reflect the changes.
   *    - If no:
   *      - Displays an alert message indicating that at least one language must remain in the table.
   *
   * **Purpose:**
   * - Safely removes a language from the table after user confirmation.
   * - Updates the dropdown to make the deleted language selectable again.
   * - Ensures the table always retains at least one language.
   *
   * **Side Effects:**
   * - Updates the `languagesToDelete` array for tracking purposes.
   * - Modifies the `selectedLanguages` array to reflect the removal.
   * - Refreshes the UI table and dropdown state.
   */

  deleteRow(rowTitle: string): void {
    if (this.language.length > 1) {
      const isConfirmed = confirm(
        `Are you sure you want to delete "${rowTitle}"?`
      );
      if (isConfirmed) {
        this.languagesToDelete.push(rowTitle);
        this.language = this.language.filter((lang) => lang.title !== rowTitle);
        const languageKey = this.languageCodes.find(
          (key) => this.getLanguageName(key) === rowTitle
        );
        if (languageKey) {
          this.selectedLanguages = this.selectedLanguages.filter(
            (key) => key !== languageKey
          );
        }
        this.refreshTable();
      }
    } else {
      alert('At least one language must be present.');
    }
  }

  /**
   * Function: onSubmit
   *
   * Saves the current list of languages and the default language to the database.
   *
   * **Steps:**
   * 1. Sets `productProgress` to `true` to indicate the process has started.
   * 2. Retrieves the project ID and constructs the database paths:
   *    - `languagesPath`: Path for the list of translations.
   *    - `defaultLangPath`: Path for the default language.
   *
   * **For `languagesPath`:**
   * - Fetches the existing languages from the database.
   * - If existing languages are found:
   *   - Removes languages marked for deletion (`languagesToDelete`) from the database.
   *   - Clears the `languagesToDelete` array.
   *   - Adds any new languages from `this.language` that are not already in the database.
   *   - Saves the updated list of languages back to the database.
   * - If an error occurs during the fetch or save operations, logs the error and sets `productProgress` to `false`.
   *
   * **For `defaultLangPath`:**
   * - Saves the selected default language (`this.defaultLanguage`) to the database.
   * - If successful, logs a confirmation and reloads the language data.
   * - Logs an error if the save operation fails.
   *
   * **Purpose:**
   * - Ensures the languages in the table and the selected default language are saved to the database.
   * - Handles deletions, additions, and updates to maintain consistency between the UI and the database.
   *
   * **Side Effects:**
   * - Updates the database with the current state of `this.language` and `this.defaultLanguage`.
   * - Triggers a UI refresh by calling `loadLanguages()` after successful updates.
   * - Sets `productProgress` to `false` upon completion or error.
   */

  onSubmit(): void {
    this.productProgress = true;
    let project_id = this.builderService.selectedProject;
    const languagesPath = `/projects/${project_id}/translations/languages`;

    interface Languages {
      [key: string]: string;
    }

    this.db.getDatabase(languagesPath).subscribe(
      (existingLanguages: Languages | null) => {
        if (existingLanguages) {
          this.languagesToDelete.forEach((langToDelete) => {
            delete existingLanguages[langToDelete];
          });

          this.languagesToDelete = [];

          const newLanguages: Languages = this.language.reduce(
            (acc: Languages, curr) => {
              if (!(curr.title in existingLanguages)) {
                acc[curr.title] = '';
              }
              return acc;
            },
            {}
          );

          this.db
            .setDatabase(languagesPath, {
              ...existingLanguages,
              ...newLanguages,
            })
            .subscribe(
              (response) => {
                if (response) {
                  console.log('Languages saved successfully.');
                }
                this.productProgress = false;
              },
              (error) => {
                console.error('Error saving languages:', error);
                this.productProgress = false;
              }
            );
        }
      },
      (error) => {
        console.error('Error retrieving existing languages:', error);
        this.productProgress = false;
      }
    );

    // Save the default language
    const defaultLangPath = `/projects/${project_id}/translations`;
    this.db
      .setDatabase(defaultLangPath, { defaultLanguage: this.defaultLanguage })
      .subscribe(
        (response) => {
          if (response) {
            console.log('Default language saved successfully.');
            this.loadLanguages();
          }
        },
        (error) => {
          console.error('Error saving default language:', error);
        }
      );
  }

  /**
   * Function: selectDefaultLanguage
   *
   * Sets the specified language as the default language.
   *
   * **Parameters:**
   * - `lang` (string): The title of the language to be set as the default.
   *
   * **Steps:**
   * 1. Updates `this.defaultLanguage` with the provided language.
   *
   * **Purpose:**
   * - Allows the user to set a specific language as the default.
   *
   * **Side Effects:**
   * - Updates the `defaultLanguage` property.
   */
  selectDefaultLanguage(lang: string): void {
    this.defaultLanguage = lang;
  }

  /**
   * Function: refreshTable
   *
   * Refreshes the table to reflect any changes in the `this.language` array.
   *
   * **Steps:**
   * 1. Creates a shallow copy of the `this.language` array and assigns it back to the same property.
   *
   * **Purpose:**
   * - Ensures the table UI is updated when changes are made to the `this.language` array.
   *
   * **Side Effects:**
   * - Triggers Angular's change detection to refresh the table.
   */
  refreshTable(): void {
    this.language = [...this.language];
  }

  /**
   * Function: getLanguageName
   *
   * Converts a language key to a display-friendly name.
   *
   * **Parameters:**
   * - `key` (string): The key representing the language.
   *
   * **Returns:**
   * - A string where underscores (`_`) are replaced with spaces.
   *
   * **Purpose:**
   * - Converts language keys (e.g., `Chinese_simplified`) into a user-friendly format (e.g., `Chinese simplified`).
   */
  getLanguageName(key: string): string {
    return key.replace(/_/g, ' ');
  }

  /**
   * Function: getLanguageCodeKeys
   *
   * Retrieves the list of language codes.
   *
   * **Returns:**
   * - An array of strings representing all language codes.
   *
   * **Purpose:**
   * - Provides the list of language codes for use in dropdowns or other UI components.
   */
  getLanguageCodeKeys(): string[] {
    return this.languageCodes;
  }
}

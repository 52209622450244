<div class="containerWrapper">
  <div class="general-info">
    <h1>{{ 'General Info' | translate }}</h1>
    <div class="info-row">
      <div class="info-item">
        <h2>{{ 'Email Address' | translate }}</h2>
        <p>{{ this.user?.email }}</p>
      </div>
      <div class="info-item">
        <h2>{{ 'First Name' | translate }}</h2>
        <p>{{ this.user?.first_name }}</p>
      </div>
      <div class="info-item">
        <h2>{{ 'Last Name' | translate }}</h2>
        <p>{{ this.user?.last_name }}</p>
      </div>
      <div class="info-item">
        <h2>{{ 'Application Name' | translate }}</h2>
        <p>{{ this.generalSettingsData?.application_name }}</p>
      </div>
      <div class="info-item">
        <h2>{{ 'Phone Number' | translate }}</h2>
        <p>{{ this.user?.billing_phone }}</p>
      </div>
    </div>
    <hr />
  </div>
  <div id="subscriptionDetails">
    <h1>{{ 'Billing' | translate }}</h1>
    <div class="info-row billingInfo">
      <div class="info-item">
        <h2>{{ 'Your Plan' | translate }}</h2>
        <p *ngIf="builderService.is_premium == true" class="pricingType">{{ builderService.subscription_package?.name }} - {{ builderService.subscription_package?.type }}</p>
      </div>
      <div class="info-item">
        <h2>{{ 'Plan Cycle' | translate }}</h2>
        <p *ngIf="builderService.is_premium == true" class="pricingType">
          {{ builderService.subscription_package?.period }}
        </p>
      </div>
      <div class="info-item">
        <h2>{{ 'Start Date' | translate }}</h2>
        <p *ngIf="this.SubscriptionFlow.stripe?.upcoming_invoice?.period_start; else noPayment">
          {{ this.SubscriptionFlow.stripe?.upcoming_invoice?.lines.data[0].period.start * 1000 | date : 'MMMM d, y, h:mm:ss a' }}
        </p>
      </div>
      <div class="info-item">
        <h2>{{ 'End Date' | translate }}</h2>
        <p *ngIf="this.SubscriptionFlow.stripe?.upcoming_invoice?.period_start; else noPayment">
          {{ this.SubscriptionFlow.stripe?.upcoming_invoice?.lines.data[0].period.end * 1000 | date : 'MMMM d, y, h:mm:ss a' }}
        </p>
      </div>
      <div>
        <ng-template #noPayment>
          <p>{{ 'Canceled' | translate }}</p>
        </ng-template>
      </div>
      <div class="info-item">
        <h2>{{ 'Total Price' | translate }}</h2>
        <p *ngIf="builderService.is_premium == true" class="pricingType">{{ this.SubscriptionFlow.stripe?.customer?.currency || '' }} {{ this.SubscriptionFlow.stripe?.charges?.[0]?.amount / 100 || 0 }}</p>
      </div>
    </div>
    <div class="billingData">
      <div class="leftBillingData">
        <span class="material-icons">credit_card</span>
        <h2 *ngIf="this.SubscriptionFlow.stripe?.payment_methods?.card?.last4">{{ 'MasterCard ending ****' | translate }}{{ this.SubscriptionFlow.stripe?.payment_methods?.card?.last4 }}</h2>
        <h2 *ngIf="!this.SubscriptionFlow.stripe?.payment_methods">No payment methods available.</h2>
        <p class="autoRenewal">{{ 'Auto Renewal' | translate }}</p>
        <mat-slide-toggle class="toggle" [checked]="autoRenewal" (change)="onAutoRenewalToggle($event)"></mat-slide-toggle>
        <button class="stacksBtn blue" (click)="this.cancelSubscription()" mat-raised-button>{{ 'cancel subscription' | translate }}</button>
      </div>
      <div class="rightBillingData">
        <button class="stacksBtn blue" (click)="billingRedirect()" mat-raised-button>{{ 'Edit billing and payment' | translate }}</button>
      </div>
    </div>
    <div class="refundData">
      <div class="leftRefundData">
        <span class="material-icons">refresh</span>
        <h2>{{ 'Refund Your Payment' | translate }}</h2>
        <p>{{ 'Refund requests via email are initial and subject to review per our policies before confirmation.*' | translate }}</p>
      </div>
      <div class="rightRefundData">
        <a class="stacksBtn" target="_blank" href="mailto:support@stacksmarket.co">{{ 'Request Refund' | translate }}</a>
      </div>
    </div>
    <hr />
    <h1>{{ 'Invoices' | translate }}</h1>
    <p>{{ 'Invoices are generated every 24 hours and transactions are rolled into one invoice during this period.' | translate }}</p>
    <h2>{{ 'Invoices History' | translate }}</h2>
    <div *ngIf="builderService.is_premium == true">
      <ul class="invoicesList">
        <li>
          <div class="invoicesListHeader">
            <p>{{ 'Date' | translate }}</p>
            <p>{{ 'Status' | translate }}</p>
            <p>{{ 'Amount Paid' | translate }}</p>
            <p>{{ 'Download Invoice' | translate }}</p>
          </div>
          <mat-divider></mat-divider>
        </li>
        <li *ngFor="let invoice of builderService.invoices">
          <div *ngIf="invoice.status !== 'void'">
            <p>{{ invoice.lines.data[0].period.start * 1000 | date : 'MMMM d, y, h:mm:ss a' }}</p>
            <p *ngIf="invoice.status !== 'open'"><mat-icon class="material-icons-outlined greenIcon" *ngIf="invoice.status == 'paid'">check_circle</mat-icon>{{ invoice.status }}</p>
            <a href="{{ invoice.paymentLink }}" class="unpaidInvoice" *ngIf="invoice.status == 'open'">{{ 'Pay Invoice' | translate }}</a>
            <p class="invoiceAmount">{{ invoice.amount_paid / 100 }} {{ invoice.currency }}</p>
            <p>
              <a href="{{ invoice.paymentLink }}" class="unpaidInvoice"><mat-icon class="material-icons-outlined greenIcon">cloud_download</mat-icon></a>
            </p>
          </div>
          <mat-divider *ngIf="invoice.status !== 'void'"></mat-divider>
        </li>
      </ul>
    </div>
  </div>
</div>

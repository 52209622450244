import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

export interface toastData {
  title?: string;
  content?: string;
  show?: boolean;
  progressWidth?: string;
  type?: string;
  duration?: number; 
  onClick?: () => void; 
  imgPath?: string;
  actionText?:string;
  onAction?: () => void;
  orderId?: string;
  total?: number;
  currency?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {
data!: toastData;
public open = new BehaviorSubject<toastData | null>(null); // Starts with null
constructor() { }
  initiate(data: toastData){
    this.data = {...data, show: true, progressWidth: '100%', duration: data.duration || 5000};
    this.open.next(this.data);
  }

  hide(){
    this.data = {...this.data, show: false};
    this.open.next(this.data)
  }
}

<div
  *ngIf="toastService.data"
  (mouseenter)="stopCountDown()"
  (mouseleave)="startCountDown()"
  (click)="toastOnClick()"
  class="toast_wrapper"
  [ngClass]="{
    'success': toastService.data.type === 'success',
    'error': toastService.data.type === 'error',
    'warning': toastService.data.type === 'warning',
    'info': toastService.data.type === 'info',
    'order': toastService.data.type === 'order',
    'daysLeft': toastService.data.type === 'daysLeft'
  }"
  [@openClose]="toastService.data && toastService.data.show ? 'open' : 'closed'"
>

<ng-container [ngSwitch]="toastService.data.type">
  <div *ngSwitchCase="'order'" class="order_inner_wrapper">
    <img *ngIf="toastService.data.imgPath" [src]="toastService.data.imgPath" alt="icon" class="stacks_toast_img" />
    <div class="order_toast_content">
      <h1 class="orderToastHeader">{{ toastService.data.title }}</h1>
      <div *ngIf="toastService.data.orderId && toastService.data.total" class="order_inner_details">
        <span class="order_toast_text">{{ toastService.data.content }}</span><br />
        <span class="order_id">Order ID: {{ toastService.data.orderId }}</span><br />
        <span class="order_cost">Total: {{ toastService.data.total }} {{ toastService.data.currency }}</span>
      </div>
    </div>
  </div>
  <div class="days_left_inner_wrapper" *ngSwitchCase="'daysLeft'" >
    <img *ngIf="toastService.data.imgPath" [src]="toastService.data.imgPath" alt="icon" class="days_left_toast_img" />

    <div class="days_left_toast_content" *ngIf="toastService.data">
      <h1 class="days_left_toast_title">{{ toastService.data.title }}</h1>
      <div class="days_left_inner_content">
        <span class="days_left_toast_text">{{ toastService.data.content }}</span>
      </div>
    </div>
  </div>
</ng-container>
    <div class="progress_bar" 
         [ngClass]="{'success': toastService.data.type === 'success', 
                     'error': toastService.data.type === 'error', 
                     'warning': toastService.data.type === 'warning', 
                     'info': toastService.data.type === 'info',
                     'order': toastService.data.type === 'order',
                     'daysLeft': toastService.data.type === 'daysLeft'
                     }" 
         #element>
    </div>
</div>

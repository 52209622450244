<div class="containerWrapper">
  <div class="accountDetail">
    <!-- First Panel -->
    <mat-accordion>
      <mat-expansion-panel hideToggle opened (closed)="listOpen = false" [expanded]="true" [disabled]="true" #mep1="matExpansionPanel">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div (click)="mep1.expanded = !mep1.expanded; listOpen = !listOpen" [class.openPanel]="listOpen" class="material-icons material-icons-outlined">expand_more</div>
            <h2>Account Info</h2>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <form [formGroup]="subscriberForm">
          <div class="subscriberForm">
            <div class="left">
              <mat-form-field appearance="outline">
                <mat-label>First Name</mat-label>
                <input matInput formControlName="subscriberFN" readonly />
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Phone</mat-label>
                <input matInput formControlName="subscriberPhone" readonly />
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Status</mat-label>
                <input matInput formControlName="subscriberStatus" readonly />
              </mat-form-field>
            </div>
            <div class="right">
              <mat-form-field appearance="outline">
                <mat-label>Last Name</mat-label>
                <input matInput formControlName="subscriberLN" readonly />
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput formControlName="subscriberEmail" readonly />
              </mat-form-field>
               <mat-form-field appearance="outline">
                <mat-label>Currency</mat-label>
                <input matInput formControlName="currency" readonly />
              </mat-form-field> 
            </div>
          </div>
        </form>
      </mat-expansion-panel>
    </mat-accordion>

    <!-- Second Panel -->
    <mat-accordion>
      <mat-expansion-panel hideToggle opened (closed)="listInvoiceOpen = false" [expanded]="true" [disabled]="true" #mep2="matExpansionPanel">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div (click)="mep2.expanded = !mep2.expanded; listInvoiceOpen = !listInvoiceOpen" [class.openPanel]="listInvoiceOpen" class="material-icons material-icons-outlined">expand_more</div>
            <h2>Subscription</h2>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <form [formGroup]="subscriberForm">
          <div class="subscriberForm">
            <div class="left">
              <mat-form-field appearance="outline">
                <mat-label>Start Day</mat-label>
                <input matInput type="date" formControlName="startPaymentDate" />
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Payment Every</mat-label>
                <input matInput formControlName="paymentDuration" readonly />
              </mat-form-field>
              
              <mat-form-field appearance="outline">
                <mat-label>Payment Type</mat-label>
                <input matInput formControlName="paymentType" readonly />
              </mat-form-field>
            </div>
            <div class="right">
              <mat-form-field appearance="outline">
                <mat-label>End Day</mat-label>
                <input matInput formControlName="endPaymentDate" type="date" />
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Next Payment</mat-label>
                <input matInput formControlName="nextPayent" type="date" />
              </mat-form-field>
            </div>
          </div>
        </form>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

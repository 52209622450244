import { Component } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { SubscriptionFlowService } from '../subscription-flow.service'
@Component({
  selector: 'app-subscriber-info',
  templateUrl: './subscriber-info.component.html',
  styleUrl: './subscriber-info.component.scss'
})
export class SubscriberInfoComponent {
  subscriberIdFromRoute: any
  subscriberForm!: FormGroup
  listOpen: boolean = false
  listInvoiceOpen: boolean = false
  customerData: any
  subscriptionData: any
  // paymentDuration: string[] = ['Monthly', 'Yearly', 'Quarterly']
  // paymentType: string[] = ['Starter', 'Growth']

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public builderService: BuilderService,
    private subscriptionFlowService: SubscriptionFlowService,
  ) {
    this.subscriberForm = this.formBuilder.group({
      subscriberFN: '',
      subscriberLN: '',
      subscriberEmail: '',
      subscriberPhone: '',
      subscriberStatus: '',
      num_retries: 0,
      paymentDuration: '',
      paymentType: '',
      currency:'',
      nextPayent: '',
      endPaymentDate: '',
      startPaymentDate: ''
    })
  }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap
    this.subscriberIdFromRoute = routeParams.get('id')
    this.fetchCustomerData()
  }

  fetchCustomerData(): void {
    this.subscriptionFlowService.getSubscriberDetails(this.subscriberIdFromRoute).subscribe((response: any) => {
      this.builderService.showUpdateProgress = false
      console.log('response ', response)
      this.customerData = response.customer
      this.subscriptionData = response.subscription
      // Split the name into first and last names
      const fullName = this.customerData.name.split(' ')
      const firstName = fullName[0]
      const lastName = fullName.slice(1).join(' ')
      // Update the form values
      this.subscriberForm.patchValue({
        subscriberFN: firstName,
        subscriberLN: lastName,
        subscriberPhone: this.customerData.phone,
        subscriberEmail: this.customerData.email,
        subscriberStatus: this.subscriptionData.status,
        num_retries: this.customerData.next_invoice_sequence - 1,
        startPaymentDate: this.convertUnixToDate(this.subscriptionData.current_period_start),
        endPaymentDate: this.convertUnixToDate(this.subscriptionData.current_period_end),
        nextPayent: this.convertUnixToDate(this.subscriptionData.current_period_end),
        paymentDuration: this.subscriptionFlowService.getPricingPackageById(this.subscriptionData.plan.id)?.duration,
        paymentType:  this.subscriptionFlowService.getPricingPackageById(this.subscriptionData.plan.id)?.type,
        currency: this.subscriptionData.plan.currency
      })
    })
  }

  // Converts Unix timestamp to ISO date string (YYYY-MM-DD) for the date input
  private convertUnixToDate(unixTimestamp: number): string {
    const date = new Date(unixTimestamp * 1000) // Convert to milliseconds
    return date.toISOString().split('T')[0] // Extract YYYY-MM-DD
  }
}

import { ActivatedRoute, Data, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { BlockDataService } from '../block-services/block-data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { HelpersService } from '../helpers.service';
import { ViewsService } from '../views/views.service';
import { deleteObject, getDownloadURL, getStorage, ref as refStorage, uploadBytesResumable } from "firebase/storage";
import imageCompression from 'browser-image-compression';
import { DatabaseService } from './database.service';

@Injectable({
  providedIn: 'root'
})
export class BuilderService extends BlockDataService{

  private builderUrl : string;

  projects:any = [];
  selectedProject :any = 0;
  headerBtn = '';
  starRating: any = 0;
  tourStart : any;
  options = {
    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  };
  token : any;
  showUpdateProgress = false;
  showSettingsProgress = false;

  selectedProjectDomain:any = '';

  globals = false;
  globalHeader:any = {};
  globalFooter:any = {};
  globalMenus:any = {};
  globalSettings:any = {

  };
  projectsLimit = 0;
  projectsPremiumCount = 0;

  selectedIcon = '';
  currentProjectType = '';
  projectStatus = "active";
  projectAccess = "full";
  projectStartDate: any;
  appOpenedCount = 0;
  mobile_webview_link = '';
  customViews:any = [];
  allViews:any = [];
  cacheProjectReq: any = {
    response: '',
    data: ''
  };
  is_premium = false;
  premium_status = '';
  stripe_subscription_id = '';
  premium_start_date = '';
  active_subscription_plan:any;
  subscription_package: any;
  invoices: any;
  user_type = '';
  redirect_to = '';
  toggleSidebar = false;
  blocksCollapsed = true;
  loadingTemplates = false;
  selected_coupon_id:any = '';
  selected_pricing_id:any = '';
  isListShown = true;
  trialEnded = false;
  previewStarted = false;
  onboardingHomeStarted = false;
  onboardingBlockAdded = false;
  customerProfile: any = {};
  previewUrl = '';
  showBillingProgress = false;
  checklist = [
   {
    title: "Add Block & Customize Design",
    done: false,
    dialogueName: 'customizeDesign'
   },
   {
    title: "Customize & Add Views",
    done: false,
    dialogueName: 'addViews'
   },
   {
    title: "Preview Changes",
    done: false,
    dialogueName: 'previewChanges'
   },
   {
    title: "Book a demo",
    done: false,
    dialogueName: 'bookDemo'
   },
   {
    title: "Add Products (Connect Site)",
    done: false,
    dialogueName: 'addProduct'
   },
   {
    title: "Generate App (Android & iOS)",
    done: false,
    dialogueName: 'generateApp'
   },
   {
    title: "Send Push Notifications",
    done: false,
    dialogueName: 'sendPush'
   },
   {
    title: "Upload App",
    done: false,
    dialogueName: 'uploadApp'
   }, 
  ];
  fileUploadProgress = 0;
  sidebarOpened = true;

  standalone = true;
  constructor(private http: HttpClient, private helper: HelpersService, private viewsService: ViewsService,
    private router: Router,
    private route: ActivatedRoute,
    private db: DatabaseService) {
    super();
    this.token = this.getToken();
    this.builderUrl = this.helper.getBuilderUrl();
    if(localStorage.getItem('selectedProject')) {
      this.selectedProject = localStorage.getItem('selectedProject');
    }
    this.db.getDatabase('projects/' + this.selectedProject + '/settings/standalone').subscribe((response) => {
      console.log(typeof response, response.length, 'projects/' + this.selectedProject + '/settings/standalone');
      if(typeof response == 'object') {
        this.standalone = false;
        this.db.setDatabase('projects/' + this.selectedProject + '/settings/standalone', false).subscribe((response) => {
          window.location.reload();
        });
      } else { 
        this.standalone = response;
      }
    });
   }

   getInitialTourStatus = () => {
      let postData = new URLSearchParams();
     // Make sure that the token is set correctly
      this.token = this.getToken();
      postData.set('token', this.token);
      return this.http.post(this.builderUrl + 'get-tour', postData.toString(), this.options);
   }
  /**
   * Get all projects from Backend
   */
  getProjects = (email: string): Observable<any> => {
    
    let postData = new URLSearchParams();
    postData.set('process', "sync_ui");
    postData.set('email', email);
    // Make sure that the token is set correctly
    this.token = this.getToken();
    postData.set('token', this.token);

    return this.http.post(this.builderUrl +'getProjects', postData.toString(), this.options);
  }

  /**
   * Get Certain Project id Data
   * @param id 
   */
  getProject = (id: string) => {
    
    let postData = new URLSearchParams();
    postData.set('process', "sync_ui");
    postData.set('projectId', id);
    this.token = this.getToken();
    postData.set('token', this.token);
    if(this.cacheProjectReq.data == postData.toString() && this.cacheProjectReq.response) {
      return of(this.cacheProjectReq.response);
    }
    this.cacheProjectReq = {
      data: postData.toString()
    };
    return this.http.post(this.builderUrl + 'getProject', postData.toString(), this.options);
  }

  /**
   * Update Project with Data
   */
  updateProject = (id: any, allBlocks: object, updateHome: boolean = false, view_id = 0, view_name = '') => {
    if( !localStorage.getItem('selectedView') || localStorage.getItem('selectedView') == "null" || updateHome == true ) {
      localStorage.setItem('selectedView','{"view_id":1,"view_name":"home","title":"home"}');
    }
    let localSelectedView:any = localStorage.getItem("selectedView");
    localSelectedView = JSON.parse(localSelectedView);
    let tempBlocks = JSON.parse(JSON.stringify(allBlocks));
    let newBlocks = this.cleanUpProjectObj(tempBlocks);
    if(view_id == 0) {
      view_id = localSelectedView.view_id;
      view_name = localSelectedView.view_name;
    }

    if(this.standalone)  {
      let data = { 
        'projectId'     : id,
        'allBlocks'     : newBlocks,
        'view_id'        : view_id,
        'view_name'      : view_name
      }

      return new Observable<any>((observer) => {
        this.db.setDatabase('projects/' + id + '/views/'+view_id, data).subscribe((response:any) => {
          observer.next(); // Notify the observer that the operation is complete
          observer.complete(); // Complete the Observable
        }, (error) => {
          observer.error(error); // Notify the observer of an error
        });
      }); 
    } else {
      let data = { 
        'process'       : "sync_ui",
        'projectId'     : id,
        'allBlocks'     : newBlocks,
        'token'         : this.getToken(),
        'view_id'        : view_id,
        'view_name'      : view_name
      }
  
      let optionsTemplate = {
        headers: new HttpHeaders().append('Content-Type', 'application/json')
      };
      return this.http.post(this.builderUrl + 'updateProject', JSON.stringify(data), optionsTemplate);
    }
  }

  /**
   * This function is intended to cleanup the saved object before sending to database
   */
  cleanUpProjectObj = ( tempBlocks: any ) => {
    for (const key in tempBlocks) {
      if (Object.prototype.hasOwnProperty.call(tempBlocks, key)) {
        const element = tempBlocks[key];
        if( element.type == 'products' ) {
          delete element['productsData'];
          delete element['categories'];
        }
        tempBlocks[key] = element;
      }
    }
    return tempBlocks;
  }
  updateAllViews = async () => {
    for await (const element of this.viewsService.allViews) {
      if(element.view_id) {
        this.getView( element.view_id ).subscribe(async (blocksData: any) => {
          this.updateProject( this.selectedProject, JSON.parse( blocksData ), false, element.view_id, element.view_name ).subscribe(async (result: any) => {
            
          });
        });
      }
    }
  }

  updateProjectWithNewViews = () => {
    if(this.standalone) {
      this.loadingTemplates = true;
      // formats the allviews of wp to be standalone compatible
      let originalObject = this.viewsService.allViews;
      const updatedObject = Object.keys(originalObject).reduce((acc:any, key) => {
        const viewIdValue = originalObject[key].view_id;
        acc[viewIdValue] = originalObject[key];
        return acc;
      }, {});
      this.viewsService.submitCustomerView(this.selectedProject, updatedObject).subscribe(() => {
        this.templateChoiceDone();
      });
    } else {
      // Delete old Views
      let postData = new URLSearchParams();
      this.loadingTemplates = true;
      postData.set('process', "sync_ui");
      postData.set('project_id', this.selectedProject);
      this.http.post(this.builderUrl + 'delete-all-views', postData.toString(), this.options).subscribe((response: any) => {
        this.submitViews(this.viewsService.allViews[0], this.viewsService.allViews.length, 0);
      });
    }

  }

  submitViews = (element: any, length: number, key: number ) => {
    if(element.view_id) {
      let data = element.data ? element.data : element.allBlocks;
      this.viewsService.submitView(element.view_name,this.selectedProject, element.view_id, data).subscribe((result: any) => {
        key = key+1;
        if( key < length) {
          this.submitViews(this.viewsService.allViews[key], length, key );
        } else {
          this.templateChoiceDone();
        }
      })
    }
  }

  templateChoiceDone = () => {
    this.showUpdateProgress = false;
    this.customViews = [];
    this.loadingTemplates = false;
    this.sendSelectedView({view_name: 'home', view_id: 1, title: 'home'});
    // disable the mobile view users from navigating to the builder and send them an email to use the app from the desktop
    // if (window.screen.width <= 768) { // 768px portrait
    //   // this.router.navigate(['/mobile-trials'], { relativeTo: this.route });
    // } else {
      if(this.getTour()) {
        window.location.href = '/products';
      } else {
        window.location.href = '/';
      }
    // disable the mobile view users from navigating to the builder and send them an email to use the app from the desktop
    // if (window.screen.width <= 768) { // 768px portrait
    //   // this.router.navigate(['/mobile-trials'], { relativeTo: this.route });
    //   window.location.href = '/';
    // } else {
    //   window.location.href = '/';
      // this.setTour(false).subscribe(() => {
          
      //   // // this.router.navigate(['/'], { relativeTo: this.route });
      //   // if you want to remove the window location refresh and go to the router navigate, uncomment the line above
      //   // and start handling getting the global_header and global_footer and save them in the database if not found, this is a replica from the app.component.ts
      //   // i deleted this code for now, refer to the history for backups
      // }, (err) => {

      // });  
    // }
    // }
  }

  /**
   * Delete Project
   * @param id 
   */
   deleteProject = (id: any) => {
    let postData = new URLSearchParams();
    postData.set('process', "sync_ui");
    postData.set('projectId', id);
    this.token = this.getToken();
    postData.set('token', this.token);
    return this.http.post(this.builderUrl + 'deleteProject', postData.toString(), this.options);
  }

  /**
   * Activate Project
   * @param id 
   */
   activateProject = (id: any) => {
    let postData = new URLSearchParams();
    postData.set('process', "sync_ui");
    postData.set('projectId', id);
    this.token = this.getToken();
    postData.set('token', this.token);
    return this.http.post(this.builderUrl + 'activateProject', postData.toString(), this.options);
  }

  /**
   * Tour Setter and Getter
   */
  setTour = ( tourStatus: boolean ) => {
    this.tourStart = tourStatus;
    localStorage.setItem('tourStart', this.tourStart);
    let postData = new URLSearchParams();
    this.token = this.getToken();
    postData.set('token', this.token);
    if (tourStatus) {
      postData.set('tourStart', 'true');
    } else {
      postData.set('tourStart', 'false');
    }
    return this.http.post(this.builderUrl + 'set-tour', postData.toString(), this.options);
  }

  getTour = () => {
    this.tourStart = localStorage.getItem('tourStart') == 'false' ? false : true;
    return this.tourStart;
  }

  public uploadFileCustomerServer(file: File, id: any, type: string): Observable<any> {
    const formData = new FormData();
    this.token = this.getToken();
    formData.append('file', file);
    formData.append('token', this.token);
    formData.append('projectId', id);
    formData.append('file_type', type);
    let project_domain = this.selectedProjectDomain;
    if(!project_domain) {
      project_domain = 'https://demo.stacksclients.com'
    }
    let customerURL = project_domain + '/wp-json/avaris-wc-rest/v3/';
    return this.http.post(customerURL + 'receive_builder_image', formData);
  }

  /**
   * Token Getter, Gets the Token from the Localstorage to maintain modularity of the function
   */
  getToken = () => {
    return localStorage.getItem('token');
  }

  /**
   * Project Getter
   */

  getSelectedProject = () => {
    return Number(localStorage.getItem('selectedProject'));
  }
  
  /**
   * retrieve the limit of the number of projects that we can create
   */
  getProjectsLimit = () => {
    const formData = new FormData();
    this.token = this.getToken();
    formData.append('token', this.token);
    return this.http.post(this.builderUrl + 'getLimit', formData);
  }

  /**
   * Add New Project Request
   */
  createProject = () => {
    const formData = new FormData();
    this.token = this.getToken();
    formData.append('token', this.token);
    formData.append('projectId', String(localStorage.getItem("selectedProject")));
    return this.http.post(this.builderUrl + 'createProject', formData);
  }

  /**
   * Add New Project Request
   */
   createFreeProject = (email: string, coupon_description: string = '', self_ltd = false) => {
    const formData = new FormData();
    this.token = this.getToken();
    formData.append('token', this.token);
    formData.append('email', email);
    // formData.append('website_url', "https://demo.stacksclients.com");
    formData.append('website_url', "");
    if( coupon_description ) {
      formData.append('coupon', coupon_description);
      formData.append('type', 'ltd');
    }

    if( self_ltd ) {
      formData.append('type', 'self_ltd');
    }
    
    return this.http.post(this.builderUrl + 'create_free_project', formData);
  }

  convertToManualPaymentProject = (email: string, project_id: any, paypal = false, paypal_sub_id = '', pricing_id = '') => {
    const formData = new FormData();
    this.token = this.getToken();
    formData.append('token', this.token);
    formData.append('email', email);
    formData.append('project_id', project_id);
    if(paypal) {
      formData.append('paypal', 'true');
      formData.append('paypal_sub_id', paypal_sub_id);
      formData.append('pricing_id', pricing_id);
    }
    
    return this.http.post(this.builderUrl + 'manual_payment', formData);
  }

  

  /**
   * Get Project Config, woocommerce active or not
   */
   getProjectConfig = () => {
    const formData = new FormData();
    this.token = this.getToken();
    formData.append('token', this.token);
    formData.append('projectId', String(localStorage.getItem("selectedProject")));
    return this.http.post(this.builderUrl + 'getProjectConfig', formData);
   }

  logData = (message: string, event_name: string, event_action: string ) => {
    const formData = new FormData();
    this.token = this.getToken();
    let documentCookie = document.cookie?.match(/_ga=(.+?);/);
    formData.append('token', this.token);
    formData.append('project_id', String(localStorage.getItem("selectedProject")));
    formData.append('message', message);
    formData.append('event_name', event_name);
    formData.append('event_action', event_action);
    formData.append('path', 'App Builder');
    formData.append('ids', 'all');
    if( documentCookie ) {
      let gaUserId = documentCookie[1].split('.').slice(-2).join(".");
      formData.append('cid', gaUserId);
    }
    
    return this.http.post(this.builderUrl + 'stacks-log', formData);
  }

  getView = ( view_id: any = 0, view_name = '') => {
    if(this.standalone) {
      let project_id = String(localStorage.getItem("selectedProject"));
      return new Observable<any>((observer) => {

        this.db.getDatabase('projects/' + project_id + '/views/'+view_id).subscribe((response:any) => {
          if(response) {
            observer.next(response); // Notify the observer that the operation is complete
            observer.complete(); // Complete the Observable
          } else {
            console.log("No data available");
            observer.next({}); // Notify the observer that the operation is complete
            observer.complete(); // Complete the Observable
          }

        });
      });
    } else {
      const formData = new FormData();
      this.token = this.getToken();
      formData.append('token', this.token);
      formData.append('projectId', String(localStorage.getItem("selectedProject")));
      if(view_name) {
        formData.append('view_name', view_name);
      } else {
        formData.append('view_id', view_id);
      }
      return this.http.post(this.builderUrl + 'getProjectView', formData);
    }
  }

  defaultViewsChecker = () => {
    const formData = new FormData();
    this.token = this.getToken();
    let data = { 
      'token'         : this.token,
      'projectId'     : String(localStorage.getItem("selectedProject")),
      'categoryView'          : this.categoryView,
      'singleCategoryView'          : this.singleCategoryView,
      'singleView'          : this.singleView,
      'loginView'          : this.loginView,
      'signupView'          : this.signupView,
      'forgotView'          : this.forgotView,
      'myAccountView'          : this.myAccountView,
      'singleBlogPostView'          : this.singleBlogPostView,
      'cartView'          : this.cartView,
      'checkoutView'          : this.checkoutView,
      'aboutView'          : this.aboutView,
      'contactView'          : this.contactView,
      'termsView'          : this.termsView,
    }
    let optionsTemplate = {
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    };
    return this.http.post(this.builderUrl + 'defaultViewsChecker', JSON.stringify(data), optionsTemplate);
  }
  /**
   * Login to stacks sites
   */
  loginDemoSite = () => {
    const formData = new FormData();
    this.token = this.getToken();
    formData.append('token', this.token);
    formData.append('projectId', String(localStorage.getItem("selectedProject")));
    return this.http.post(this.builderUrl + 'loginDemoSite', formData);
  }

  /**
   * Get all views and save in customViews Variable
   */
  getViews = () => {
    this.showUpdateProgress = true;
    this.viewsService.getViews(this.selectedProject).subscribe((response: any) => {
      this.customViews = [];
      this.showUpdateProgress = false;
      for (const key in response.data) {
        if (Object.prototype.hasOwnProperty.call(response.data, key)) {
          let count = Number(key);
          const element = response.data[count];
          let data = element.data ? JSON.parse(element.data) : element.data;
          this.customViews.push({'view_id':element.view_id, 'view_name':element.view_name, 'data': data})
        }
      }
    });

    this.viewsService.getViews(this.selectedProject, 'get_all_views').subscribe((response: any) => {
      this.allViews = [];
      this.showUpdateProgress = false;
      for (const key in response.data) {
        if (Object.prototype.hasOwnProperty.call(response.data, key)) {
          let count = Number(key);
          const element = response.data[count];
          let data = element.data ? JSON.parse(element.data) : element.data;
          this.allViews.push({'view_id':element.view_id, 'view_name':element.view_name, 'data': data})
        }
      }
    });
  }

  /**
   * Gets the premium package details
   */
  getPackage = () => {
    let postData = new URLSearchParams();
    let project_id:any = this.getSelectedProject();

    postData.set('project_id', project_id);

    return this.http.post(this.builderUrl + 'get_package', postData.toString(), this.options);
  }



  uploadImage(selectedFile:any, id: any, folder: string, randomizeName: boolean = true) {
    // Get the file name and extension from the original file
    const originalFileName = selectedFile.name;
    // Generate a random string and use it as a prefix
    const randomString = this.helper.generateRandomString(10);
    const newFileName = randomizeName ? randomString + originalFileName : originalFileName;

    // Create a new File object with the new name
    selectedFile = new File([selectedFile], newFileName, { type: selectedFile.type });
    const storage = getStorage();
    const fileRef = refStorage(storage, `/project_assets/${id}/images/${folder}/${selectedFile.name}`);
    return new Observable<any>((observer) => {
      this.optimizeFileSize(selectedFile).subscribe((optimizedFile:any) => {
      selectedFile = optimizedFile;
      const uploadTask = uploadBytesResumable(fileRef, selectedFile);
    

      uploadTask.on('state_changed', 
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          this.fileUploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // console.log('Upload is ' + progress + '% done');
          // switch (snapshot.state) {
          //   case 'paused':
          //     console.log('Upload is paused');
          //     break;
          //   case 'running':
          //     console.log('Upload is running');
          //     break;
          // }
        }, 
        (error) => {
          // Handle unsuccessful uploads
          observer.error(error); // Notify the observer of an errorrandomizeName
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break;
            case 'storage/canceled':
              // User canceled the upload
              alert("user cancelled");
              break;
      
            // ...
      
            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        }, 
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log('File available at', downloadURL);
            observer.next(downloadURL); // Notify the observer that the operation is complete
            observer.complete(); // Complete the Observable
          });
        }
      );
    }, (error) => {
      console.log(error);
      observer.error(error); // Notify the observer of an error

    });
    })
  }

  /**
   * Optimize image sizes before uploading to the server to maintain space and speed
   * @param selectedFile 
   */
  optimizeFileSize = (selectedFile:any) => {
    var options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1024,
      useWebWorker: true
    }
    return new Observable<any>((observer) => {
      if(selectedFile.type == 'image/gif') {
        observer.next(selectedFile); // Notify the observer that the operation is complete
        observer.complete();
      }
      imageCompression(selectedFile, options)
        .then(function (compressedFile) {
          console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
          console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
    
          observer.next(compressedFile); // Notify the observer that the operation is complete
          observer.complete(); // Complete the Observable// write your own logic
        })
        .catch(function (error) {
          console.log(error.message);
          observer.error(error.message); // Notify the observer that the operation is complete
          observer.complete(); // Complete the Observable// write your own logic
        });
    });
  }

  deleteImage = (fileURL: string) => {
    return new Observable<any>((observer) => {
      let protectedImages = [
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2F4RxEOS64uPRectangle%2024.png?alt=media&token=5ec3a9d5-60fc-424d-8148-a440cd5b38ba',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2FNxQwwEVWC7Group%2039.png?alt=media&token=ac2da272-156a-478e-9f96-48e3de78917e',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2FPxP0Dyzr8uGroup%2040.png?alt=media&token=2ac92908-fbe3-400b-94c7-2faa807083d1',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2FAYNZ0bXROhGroup%2041.png?alt=media&token=171d96ba-ae4c-4b53-b4c0-3816d1226a8e',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2FAYNZ0bXROhGroup%2041.png?alt=media&token=171d96ba-ae4c-4b53-b4c0-3816d1226a8e',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2FXrCcfLt6IwGroup%2045.png?alt=media&token=64c63a68-b4ec-4711-ba43-bc1218d07167',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fcustom-images%2FdPGepnFi0bbanner.png?alt=media&token=b0aeb4a9-773b-4606-a433-69e5c0ad5ca2',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fcustom-images%2FL98kb8PI0Gburger-shot.gif?alt=media&token=479ab16e-982a-4741-8740-d0ce3bda9787',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fcustom-images%2FV6Nr6LSd5n34y9.gif?alt=media&token=08125524-bfca-4030-8edd-09bdac493ac9',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fcustom-images%2FARGnqCtwkHhero-taste-tracker-mar6.gif?alt=media&token=6e27622c-e434-44b2-ab61-00b5e6b2f340',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2FaAZK4yeO0Ugrilled-chicken-recipe-25.png?alt=media&token=d36a3de3-9466-41d9-8fa7-3df7f85581e6',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2F03dy1BQ4m9Nyama-Choma-IG-1.png?alt=media&token=774b27ae-bdce-4bb9-ab97-4413ad0a6641',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2Fa4vRzHPmYnGrilled-Marinated-Leg-of-Lamb-118032019.png?alt=media&token=1b12fbab-3820-4b4d-b0e7-a947d80a9799',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2F3TLBDliGh5spicy-chicken-sandwich-recipe-500x500.png?alt=media&token=18947a1f-1110-4a4e-b144-1872e66f82bd',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2FeWbXzNYHy7banh-mi-turkey-burger-secondary-6578982fea00a.png?alt=media&token=9b4cd717-21c9-4300-a01f-5d8498a790f4',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2FyTzShcsFlPone-pot-chicken-pasta-with-cajun-seasonings-efca59b03fe84ebb8495c31b4a3fdada.png?alt=media&token=4fdb793b-bcb3-4f2e-9f3c-c1d436fde84e',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2F7n8OoMNuFx09112015_15minute_pastasauce_meatsauce.png?alt=media&token=c91038a6-fd4c-4a25-a482-f09e04bd0e30',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9787%2Fimages%2Fcustom-images%2F7mShFbcxkWLevel-Shoes-Dubai-Mall-Fashion-Trust-Arabia-1.gif?alt=media&token=8703faed-e15f-42f0-bcfc-8563baf2bb85',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9787%2Fimages%2Fcustom-images%2FYTktcSrcfeSHOE_SPIN_MASTER-16.gif?alt=media&token=c7980f8b-ace1-4a24-99a9-86fe32acb4dc',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9418%2Fimages%2Fcustom-images%2FmVsvc8hfYb8b3340124597183.6107d0ce1bad7.png?alt=media&token=a8f85298-69df-497b-9b73-48ef64358b59',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9418%2Fimages%2Fcustom-images%2Fzf2GJQxT9ladidas-case-study-inner-banner.png?alt=media&token=0707120d-0685-455c-8e22-372f29dbd899',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9418%2Fimages%2Fproducts%2FRYcPQjaO8Ncourt-vision-low-next-nature-womens-shoes-ZkMMBG.png?alt=media&token=c1ff2c83-7e14-48b7-813a-44f2b7eb5f94',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9418%2Fimages%2Fproducts%2FdiRuO9dZFR0592f0ad-b230-48e2-a03f-704148d4eea1_1.48e6b774b49aff2a70fa6db8c9521066.png?alt=media&token=d1057aaa-c63e-41fa-b886-5c8069f9cba8',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9418%2Fimages%2Fproducts%2FQtMBFBpieyfree-rn-2018-womens-running-shoes-zE8Je3.png?alt=media&token=3a2c7777-e483-4e1e-9165-531a33167c20',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9418%2Fimages%2Fproducts%2FJkqfGSL89Eimage.png?alt=media&token=13c25a5b-41c4-4b57-a4ce-c17fe6079070',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9418%2Fimages%2Fcategories%2F984CX77QSNGroup%20210.png?alt=media&token=18c785b4-96a5-4991-95ff-4c95ef78af63',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9418%2Fimages%2Fcategories%2FhbPMn0igbBGroup%20211.png?alt=media&token=df772cf5-5a60-4424-a20d-79e5f08a76ca',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9418%2Fimages%2Fcategories%2FasxoJfGf0fGroup%20209.png?alt=media&token=4175a8cd-e4a4-46cd-9663-2c59277b9bea',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9418%2Fimages%2Fcategories%2FcIYCBtIWRfGroup%20205.png?alt=media&token=32c63fe3-a666-4ce3-9821-9a8545fe19ba',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9418%2Fimages%2Fcategories%2FqcjHVxjrVoGroup%20208.png?alt=media&token=466d2020-0d45-483c-b6e6-eb7e82cec465',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9418%2Fimages%2Fcategories%2FPMidnaLzK4Group%20206.png?alt=media&token=0016dfe0-c5f9-4862-a53e-ed1a259ff7fb',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9418%2Fimages%2Fcategories%2FtTpufFrYhmthe-best-nike-high-top-sneakers-you-can-buy-right-now-articles-ogc.png?alt=media&token=c4f9a30e-4927-40eb-944b-42f6033b123b',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9418%2Fimages%2Fcategories%2Fan10AjSzdmimage.png?alt=media&token=9ab84463-ceaa-44b3-96cd-09f9121d4a31',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9418%2Fimages%2Fcategories%2FykXcOUhwZTshakur-stevenson-reebok-float-trainer-shoe-2024-1.png?alt=media&token=d4c22a2e-8260-460f-9161-bd8445bce462',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9418%2Fimages%2Fcustom-images%2Ff1k4i9TSTUGroup-208.png?alt=media&token=8c4a6f1d-339e-4ca6-95e7-4c70440a512f',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9418%2Fimages%2Fcustom-images%2FOcQKc4PeyFGroup-210.png?alt=media&token=3053389f-d3a2-43fa-9256-41c229e1200b',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9418%2Fimages%2Fcustom-images%2Fi5In3AFtSHGroup-206%20(1).png?alt=media&token=2762d239-8acf-4e3c-8937-d56738c38c21',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9787%2Fimages%2Fcustom-images%2F3DGrzzpRyp2deG937Cz7gif.gif?alt=media&token=1b8a2a02-c8db-4750-bf26-96482aff0229',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9787%2Fimages%2Fcustom-images%2FD3dLjx665Xbanner-3.png?alt=media&token=5e52e916-144d-4ef2-8b33-23770ebc8110',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9787%2Fimages%2Fcustom-images%2FLxwEH6YCg8banner-2.png?alt=media&token=570daa3b-982d-4030-acf5-8bf835eba89d',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9787%2Fimages%2Fcustom-images%2F5pRbhEc2Trbanner-1.png?alt=media&token=b30ec734-e87b-4313-8c5d-ae28bf9ff137',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9787%2Fimages%2Fcustom-images%2FcpWUm2gZ9Mproject_assets_2F9420_2Fimages_2Fcustom-images_2FWNEMpG3HlJicons.png?alt=media&token=fa85defd-657d-47a8-bfce-63b7275c7f71',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2Fikrevy09611.png?alt=media&token=c7e02cc2-f340-44d9-9168-88881617ce1c',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2FO6EuuWugjl2.png?alt=media&token=f2037989-0291-45c2-88a1-76a67c1bd4e2',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2FFWflfLuVEj3.png?alt=media&token=3ba17510-f7c6-406c-9648-0db6db3c6610',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2FkWOPKeXlZD_D8_AD_D9_85_D8_A7_D9_85__D9_85_D8_AD_D8_B4_D9_8A__D9_85_D8_B4_D9_88_D9_8A.png?alt=media&token=c3332e7c-eb5e-4cf6-b785-c42938616cb1',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2FPbbmp5icKPd8btPR6CpJ6dWTOAuFCppZ8wP95Ov4DU8I6eie6r.png?alt=media&token=dc3a90cf-d726-4696-963b-6d0522f36ddd',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2Fsbrw3yNIBQ1137608-_D8_A7_D9_84_D9_85_D9_82_D9_84_D9_88_D8_A8_D8_A9.png?alt=media&token=6c8f65c6-a64d-4cb6-8c2c-d490023a6881',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2FFcmdiqNjjR402c196232f274e512677c09a9465260_w361_h361.png?alt=media&token=d2689f2f-670b-44ae-b598-a6280fe8e8bf',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2Fz18SiBndvlimage%20(2).png?alt=media&token=457ff381-355e-4fc7-aa08-89046d0a0c43',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F6336%2Fimages%2Fcustom-images%2FKiwOFGaKBkbest-season-sale-banner-design-template_2239-1175.png?alt=media&token=e88829b9-465f-4ebd-a97a-44a3e4273e87',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F6336%2Fimages%2Fcustom-images%2FwpwQJpk007summer-fashion-sale-banner-design-template_2239-1174.png?alt=media&token=7ae011ae-9b7f-423c-9334-4de7453d6bc0',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F6336%2Fimages%2Fcustom-images%2FSNwRVi4oGIshipping.png?alt=media&token=8605bbcb-5da2-4006-84e2-43b8ce2325d3',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F6336%2Fimages%2Fcustom-images%2FjDrDV3ct6cflashsale.png?alt=media&token=6a53cf8f-ae3b-498b-80c2-1862c59df0fa',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F6336%2Fimages%2Fcustom-images%2FvU5RUfRJ9r1236.png?alt=media&token=5a3b2093-f792-4d6a-8fbe-afa7619bf2d5',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F6336%2Fimages%2Fcustom-images%2Frg5n6sWQ2wGroup-300.png?alt=media&token=ae3b505e-1f19-45e3-bb3e-d8c879c1fae5',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F6336%2Fimages%2Fcustom-images%2FTlNJUDNdcoflashsale.png?alt=media&token=1f210550-e865-47fc-9487-abc0fcd642e8',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F6336%2Fimages%2Fcustom-images%2F69pGIMdCrjf3.png?alt=media&token=f201d917-67dd-41af-8e7f-95b409a31584',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F6336%2Fimages%2Fcustom-images%2FJdpfVhlu6af2.png?alt=media&token=4c78fe09-12e0-4b94-861f-686756d690e1',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F6336%2Fimages%2Fcustom-images%2FmCZSTgW9Tsf1.png?alt=media&token=8cb85b9f-1939-4af1-924c-8d0941089342',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F6336%2Fimages%2Fcustom-images%2FqmNvnBazZEshipping.png?alt=media&token=3a964805-0df6-4f3d-8338-b5a1d8620897',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F6336%2Fimages%2Fcategories%2FGroup-180-1.png?alt=media&token=e0380575-ea58-46c9-891e-e2ae4d9ccda3',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F6336%2Fimages%2Fcategories%2FGroup-179-1.png?alt=media&token=66119ab4-e1c6-42e0-8270-f9778857e9ff',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F6336%2Fimages%2Fcategories%2FGroup-181-1.png?alt=media&token=628544a4-97b5-40fb-a8fa-bc4bdf243e15',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F6336%2Fimages%2Fcategories%2FepkPl1XEqYas23101s_290_a.jpg?alt=media&token=7fc14e94-77dc-4676-a65d-00877472bbac',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F6336%2Fimages%2Fcategories%2F95c0zhZnPPman.png?alt=media&token=3baf12d8-f02e-4a06-947a-f369467e2a8f',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F6336%2Fimages%2Fcategories%2FsJyy5rZiDjkids.png?alt=media&token=c42877fe-f31d-4898-8b0a-c98624ccf7d1',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F6336%2Fimages%2Fcategories%2FYchh1E0CDvwoman.png?alt=media&token=27a9fc32-ecc3-4e72-b6bd-a2961fa3a229',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2F0mjQynGhQlHS3531_21_model.png?alt=media&token=429d3284-f9d3-49de-b192-7999b8a44be1',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2FLEjztzphiUGramercyBlazers_BlueHour_03_bea90fe8-63b1-427a-aa96-5acd9246a4c6.png?alt=media&token=c7a2b7ad-9bd8-4e1f-86ab-a63151e62583',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2FO0KadnMOho1024x1024-Men-Jacket-Keanu-BlackMatte-091721-1_4cc1c53b-dec4-478b-8f17-434f5f3d27e1_1024x1024.png?alt=media&token=27fb184d-575d-4048-b853-9add17e59647',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2FeBVRc5Ge8Wwomens_pink_barbie_feather_bandage_bodycon_long_sleeve_dress_loreta_4_1200x1200.png?alt=media&token=256e9188-0c62-496f-949b-38a0797e5e2c',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2F3FuBwm9sEV1.png?alt=media&token=8c325725-664b-479a-8bf8-9e8b6b58b343',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2F2RGe69cla0161875-full_product.png?alt=media&token=6ec03fc3-38fa-4ad7-be4a-911cddcd9c4d',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2FJz4F297VUis-l1200.png?alt=media&token=f920acf7-d9b0-4a59-81ee-bde998d75b7a',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2FnRwpavh7WMcdfa42e1-f17a-45ec-8b09-eac85d66eb5a.6a8c4dfa525eea37e210fe12df978957.png?alt=media&token=2c57d024-9693-447d-af68-f691e7151faf',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2FlWanUmrgoa899826908_max.png?alt=media&token=a16d811f-3de9-43f4-b530-d478a84c7155',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2FXpbjuJugSWmen-black-3-piece-suit.png?alt=media&token=4f2bf43e-7d41-41bf-b357-4bd4d6a43e36',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9422%2Fimages%2Fcustom-images%2FV8bXjcdlYWclassshoes%20s1.jpg?alt=media&token=db143779-7b9c-49c7-814d-f9a11679a017',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9422%2Fimages%2Fcustom-images%2FjI2cxfjP7Nclassshoes%20banner%201.png?alt=media&token=d952c350-16dc-4b6a-8bcd-d9e5c15dc1cc',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9422%2Fimages%2Fcustom-images%2FJZ6hzAqTS4classshoes%20banner2.png?alt=media&token=fc20e8d1-a219-4bda-9d9b-419d88058660',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9422%2Fimages%2Fcustom-images%2F0pGDZAdXnwclassshoes%20s2.jpg?alt=media&token=27f6c3b4-7bb0-49fd-b0b2-dfacd4723afa',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2FtSWhnmwNzL1%20(1).png?alt=media&token=5d574860-dc5a-4341-bf42-1ad7afe1e31c',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2FV8aDWKLaAj1%20(2).png?alt=media&token=45065909-e711-45e0-acaf-2add5ffc1343',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2Fq02NakLDem1%20(3).png?alt=media&token=0afa8390-4c63-4bb5-a9d2-061b4ba83e62',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2FXVCsru1ziO1%20(4).png?alt=media&token=19cee1b4-e704-45a2-ad1e-763d5ded5fd5',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2FPBfWai07au1%20(5).png?alt=media&token=2f65fc90-72d1-41af-ade4-e92b2ff65d9c',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2FWAReY0vq5L1%20(6).png?alt=media&token=afb7996b-d714-48cb-a903-544d76547534',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2FexoMqze4iXc3.png?alt=media&token=db28e398-44df-40e1-a7c2-d2b42140ef50',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2FyQuvKPyzeDc4.jpg?alt=media&token=11e50b8f-d4f2-4284-af8c-56aa23918fce',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9787%2Fimages%2Fcustom-images%2FChKlmMYyfgpractical_furniture_banner_1024x1024.gif?alt=media&token=c688dfd8-b3b0-491c-8738-da7623016fc7',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9423%2Fimages%2Fcustom-images%2FXLEdI614rEinterior-design-concept-sale-of-home-decorations-and-furniture-during-promotions-and-discounts-it-is-surrounded-by-beds-sofas-armchairs-and-advertising-spaces-banner-pastel-background-3d-render-photo.png?alt=media&token=9233c1d4-40c6-4860-a184-57e71e366797',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9423%2Fimages%2Fcustom-images%2FbSw0tIpQGointerior-design-concept-sale-of-home-decorations-and-furniture-during-promotions-and-discounts-it-is-surrounded-by-beds-sofas-armchairs-and-advertising-spaces-banner-pastel-background-3d-render-video.png?alt=media&token=d1292f79-8dd8-4e75-ba2d-95586555a342',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9423%2Fimages%2Fproducts%2F8qO6EEwr9Obeeed.png?alt=media&token=337f3ae0-f0cd-4afe-85cf-5569921d7913',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9423%2Fimages%2Fproducts%2FCBSZMr8bFTchaiiiir.png?alt=media&token=1247ff2a-68af-422a-aa38-7ce349093290',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9423%2Fimages%2Fproducts%2F7IQdi44AZFsofffa.png?alt=media&token=d2e181c4-ce35-4302-9191-3e3515a6b4f4',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9423%2Fimages%2Fproducts%2FQda70pn5e5tabllle.png?alt=media&token=8224809e-0e30-402a-abc9-ffe577e15e23',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9423%2Fimages%2Fproducts%2FugwwCOQu2Plight.png?alt=media&token=56f83e35-2840-4193-b29c-65be1ccf0c82',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9787%2Fimages%2Fproducts%2F5wlLzA4niHnojig-organiser-plastic-beige__0954158_pe804500_s5.png?alt=media&token=982f08ca-fa54-479b-904f-9e1376b5a7ae',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9787%2Fimages%2Fproducts%2FRRGtRTNniapokal-glass-clear-glass__0713251_pe729361_s5.png?alt=media&token=452df26a-fb65-4758-9228-bff7a96b48b3',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9787%2Fimages%2Fproducts%2FcLTFaZoOL4ragodling-box-with-compartments-natural-colour-beige__1214901_pe911777_s5.png?alt=media&token=73be9ab0-01c3-4ed4-9e43-c25cbf938de8',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9787%2Fimages%2Fproducts%2FzQrehiVptVlillhavet-multifunctional-dish-rack-anthracite__1057177_pe848766_s5.png?alt=media&token=dbeed598-ed01-45d4-a1e0-5b93f36036d6',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9423%2Fimages%2Fcategories%2Fj2KtTwUMyXverona_cornersofa_2022_1500x1021.png?alt=media&token=bfd85d22-65b2-492e-8fed-a29eed5a23c6',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9423%2Fimages%2Fcategories%2F5dLd6BCP8Echair.png?alt=media&token=9cbc3768-95b6-4468-a436-f6a55b7aa170',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9423%2Fimages%2Fcategories%2FxzO6WinEUYtable.png?alt=media&token=e1d310ac-94b3-45e5-bddf-9c14dc6bb858',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9423%2Fimages%2Fcategories%2FVXpuhG6WNmbed.png?alt=media&token=67753d02-9084-47ad-a948-36493e34b983',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9423%2Fimages%2Fcategories%2FGALuhj1dghunder-100.png?alt=media&token=b0083dc2-a4ce-4fdf-a4fe-d945dab6de0f',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9423%2Fimages%2Fcategories%2FKxwLa15sPaunder-200.png?alt=media&token=1afa751a-812f-4823-bc41-a7fb1ae3d029',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9423%2Fimages%2Fcategories%2F8xK17sp8mpunder-300.png?alt=media&token=c63f41a0-32aa-4d71-8392-eaa3f16463f4',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9423%2Fimages%2Fcategories%2F1WpL0F83Tpunder-400.png?alt=media&token=948d0c63-29d8-4ef7-854e-11a903e6e366',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9423%2Fimages%2Fcategories%2Ff6Btig9HAHPH179171.png?alt=media&token=cb7906a8-9fad-47e6-9094-167895d5f650',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9423%2Fimages%2Fcategories%2FOFFehIt781PH172954-crop002.png?alt=media&token=d80d0c39-ee36-41ff-9c7d-e715968ff416',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9423%2Fimages%2Fcategories%2FMQccnwSeaKPH184302.png?alt=media&token=490dc2c5-fe33-4380-b30d-52c9bc47db86',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9424%2Fimages%2Fcustom-images%2FufF2BVYkLxZXCDAS.png?alt=media&token=c4e7b385-3f8d-4349-915a-461cc33a88d1',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9424%2Fimages%2Fcustom-images%2FDZ9LvxiQ3Hcatesss.png?alt=media&token=536028c2-1368-4049-9999-a0386ba90d7d',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9424%2Fimages%2Fcustom-images%2Fz28fCoqe7AcatesasaDS.png?alt=media&token=cc7dcf68-9744-4b7f-ba25-8a09627f0b5b',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9424%2Fimages%2Fcustom-images%2Fw2Pf99Hsv9furnituregify.gif?alt=media&token=eb066419-8d68-4a70-a687-b3e1aee7cbfb',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9424%2Fimages%2Fcustom-images%2Fp61zBVvq7rFurniturebanner.png?alt=media&token=3e12b3d7-d695-4fc7-865f-bdebe95b07b2',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9424%2Fimages%2Fcustom-images%2FlXC8OpaSXHali-nouman-3.png?alt=media&token=cd659c03-ed8b-4248-b85b-4bca74695adc',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9424%2Fimages%2Fcustom-images%2F8qKPLEmxJGali-nouman-5.png?alt=media&token=1443fe64-e1d6-4dd2-ac87-5f0f8d109950',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2FyBF25y8OzZ71eV6jJsMXL._AC_UF894%2C1000_QL80_.png?alt=media&token=ca0f3dd6-48d6-4c9d-985e-56fe1d509927',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2FZD1huZCKBAlight-wood-glass-round-b22-holder-set-of-2-sa23-afast-original-imafj2dnhz9xykcz.png?alt=media&token=8174f855-1152-4321-89b9-d16972cb6c13',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2FPamIKcbz1081ryHfAzmmL._AC_UF894%2C1000_QL80_DpWeblab_.png?alt=media&token=fad49f20-0034-4c85-bf5b-31d292bc2dc6',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2FRqktwmghA8queen_size_wooden_bed_1.png?alt=media&token=e3570e7a-cc2c-452a-b2d5-a40e47de3597',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2FvwhhBbk3Dxf621c66a-42f3-44a0-b63b-9f1c27b8b7ca.1f78ffe3b7f9e7e4b4e65e272897fcc2.png?alt=media&token=ec2fa980-eee9-4085-b101-b86009a6d722',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9787%2Fimages%2Fcustom-images%2FnapTn6iIOW77352b96bb8815adb5141d63d33ecb5a.png?alt=media&token=17123812-98e6-4a97-9b53-9e3b77d7d0e4',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9425%2Fimages%2Fcustom-images%2Fn0Fg4H0yCB34f106c9503b2__w47-mc-22nov2023-allmarket-web-refresh-hp-slider-newborn_copy.png?alt=media&token=5318c3f8-0585-4b32-911d-164d58b6303e',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9425%2Fimages%2Fcustom-images%2FIpc5EsCzB41d52518dea6d0__w47-mc-22nov2023-allmarket-web-refresh-hp-slider-boys_copy_2.png?alt=media&token=5bbbe5ec-843e-4f0a-827e-b14b90351139',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9425%2Fimages%2Fcustom-images%2FJX6b9pmunQScreenshot%202024-02-28%20094658.png?alt=media&token=e184597d-27ec-4519-ab4d-88feddd6a889',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9425%2Fimages%2Fcustom-images%2FpYn9tcqJ4mkidslider1.jpg?alt=media&token=fc5e9342-c5c1-48fe-92d6-00e355c1a2d4',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9425%2Fimages%2Fcustom-images%2Fpwg6pp4P0ukidsbanner.png?alt=media&token=f5551078-5d17-447b-9653-d376825fd5db',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9425%2Fimages%2Fcustom-images%2FVyIINCvoYnkids2.jpg?alt=media&token=8df367dc-620a-4d97-8ce2-5a64f02e824c',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2FnbfQKnAZ6Ukidslider1%20copy.jpg?alt=media&token=6ee1707e-c445-41d8-80df-56a7b962b795',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2FxytAB5nHi3kidsbanner%20copy.png?alt=media&token=a36f3696-ef3c-49da-b9d2-996bc13e97bb',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2F36s8ArnApvkids2%20copy.jpg?alt=media&token=b80fdfc6-3cea-450f-a454-ac468b416c2b',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2Fq1YqXnEzSrWinter-Boys-Pyjamas-Set-Kids-Pijama-Boys-Clothes-Cotton-Teens-Sleepwear-Suit-For-9-19Y-Big.png?alt=media&token=a31cdcc7-5d28-4a30-acf4-cf91605aaf2d',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2FZn7Wd5Z1pt564ec61a95069915e193120f-large.png?alt=media&token=452c181e-e99a-4211-801b-dfb00c9e4354',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2FY5kqQWICZGDSC04201BG_RBE5_15.png?alt=media&token=70fbcf30-a4b4-4676-be0d-09c54d6460d7',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fproducts%2FT77KJTWdIzCute%20Baby%20girl%20dress.jpeg?alt=media&token=1122e338-59d6-4906-a6be-420e73eb4859',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fproducts%2FaLtVY32Ez6cute%20short%20sleeve.jpg?alt=media&token=f0027621-05b6-451d-9d9c-00463c6714fd',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2FuP7VwtWyHz1%20(7).png?alt=media&token=de072b07-8642-4676-a761-d75a265693fc',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2FpqZR7PBE5K71fYWsU2SML._AC_UY900_.png?alt=media&token=356c4779-69c9-4748-b3c3-ccd9b505b96b',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9426%2Fimages%2Fcustom-images%2FAiUaoJt1Wqsuits1.jpg?alt=media&token=13e61d31-783f-4812-94f4-0180dcad8bf4',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9426%2Fimages%2Fcustom-images%2FJtXCyELJYnsuits2.jpg?alt=media&token=fb5bf018-7aed-4a05-8d33-4e06a09ec9f7',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9426%2Fimages%2Fcustom-images%2FgDwckxczlnsuits3.jpg?alt=media&token=e81a35d2-a62d-4dc6-bb04-d59b7f3942ff',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9426%2Fimages%2Fcustom-images%2FQouzhVsTA7online%20now.gif?alt=media&token=e8267d8b-49d5-4af2-b145-cd9065d43780',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9426%2Fimages%2Fcustom-images%2Fl2PJIbm60ssuitbanner.jpg?alt=media&token=880d9152-b52f-4880-b6b5-7c4ce0ff618c',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9426%2Fimages%2Fcustom-images%2FcmL4zKKuW0suits3.jpg?alt=media&token=5e257e72-fa13-40d3-9d8b-88373c9c6711',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9426%2Fimages%2Fproducts%2Fh9PqmVFhTPBlack-Tuxedo.png?alt=media&token=36380e5a-2bb3-42cc-8c79-66aa19b0d383',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9426%2Fimages%2Fproducts%2FLWN6CcZ69ewhitw-Tuxedo.png?alt=media&token=5b138d16-257b-41c2-aac0-b309fe121468',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9426%2Fimages%2Fproducts%2F64VvRHomPF230404_Ecom_Ramraj01369_600x600_crop_center.png?alt=media&token=93977aca-cdde-4954-8740-38b5142f76dc',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9426%2Fimages%2Fproducts%2F3aZl33tAxc1%20(7).png?alt=media&token=ee78a1ac-83d9-4525-9765-7e6e0e10e46c',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9426%2Fimages%2Fproducts%2FWX7pWBXkWcgg.png?alt=media&token=897168a8-92b5-408a-a9c3-473d25067cb5',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9426%2Fimages%2Fproducts%2FGGcrFVcsr1cvdscv.png?alt=media&token=2d2538ee-4017-46fd-b47f-15dc34228965',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2FnnifKB9IoCimage.png?alt=media&token=d2c9cde3-c221-4d91-ade6-8587f3851e04',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2F27wnuCDZMKIMG_8081_1wdeb2.png?alt=media&token=3db28245-eb02-47c5-a2ab-54c27f4025e0',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2FBu0qxLfShQimage%20(2).png?alt=media&token=8e53e82a-cdf8-48e5-a566-e6f99056ccbf',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9787%2Fimages%2Fcustom-images%2FPX6bXTSIfJproject_assets_2F9428_2Fimages_2Fcustom-images_2FeXahTJMTDyGroup-228.png?alt=media&token=eb9510b0-c2a8-469f-a724-10370ad65c37',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9428%2Fimages%2Fcustom-images%2FkJCUdqi8g67bad8c93546277.5f48a570f12f8.jpg?alt=media&token=486fc169-c33d-45cd-a0f9-84158f482bef',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2F2zfWM2HeqmGroup%20228.png?alt=media&token=4a55d012-6dfb-4424-aedc-b3cb1b6bd806',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2Fk0fjseZzcfGroup%20233.png?alt=media&token=5eb52de7-9c36-4d6c-92a9-5105c97c56ab',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2FRFzg1ZDvRcGroup%20242.png?alt=media&token=6a775b92-bae8-4789-a819-3df42ad70f9b',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2Fjwfr5gYIKEGroup%20232.png?alt=media&token=ab6db7b2-4735-467b-a8f0-5c80ae3ccc6f',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2FF83blllcKvGroup%20241.png?alt=media&token=583d3810-815d-4761-8648-9e7e24a400c2',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2FGatgP9f9TiLG-24-UltraGear-FHD-1920-x-1080-1ms-165Hz-Gaming-Monitor-Black-24GQ40W-B-New_cfbb0213-e5d9-4402-a793-62e12a29f175.a51d4cb3cdc4034d0cc4879014227b1d.png?alt=media&token=399f6cb1-f4d8-4fb8-ae2b-16c5d88c621f',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2Fbtq70XpxVg1653353121_1708099.png?alt=media&token=a530c354-5c9a-4777-ba31-2661962eac67',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2FmvbW30lJkNiLive-Bluetooth-Tailgate-Speaker-ISB380B-Black_ebbda3e2-cdaf-4785-bc1c-0328a53ad0cf_1.1dd621993d3659f22f5a4075dcb95d9c.png?alt=media&token=43b1bb01-9a23-4f89-a7ff-0870a6105093',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2FKtGbOUVwbCadcd28d17da5e7a362745c06ef037b3076bb1a21fc1455a4c42e0b3c3374af0b.png?alt=media&token=ca2e3002-494b-4ed5-a946-7fa5043a6db2',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2FfnzpOd6Idk61nqgl4atFL._AC_SL1500_.png?alt=media&token=5fed3f6e-5a96-4a6c-8889-0977331f2632',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2F4uj2EcjYnM71AY2Pd3MHL.png?alt=media&token=9c75112d-a4d5-42fc-ae59-b5c8f9278ea9',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9429%2Fimages%2Fcustom-images%2FRGcOIl8YhfcomputerS1.jpg?alt=media&token=ef442a71-7bf4-479a-ac3d-6669bf1a6f6f',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9429%2Fimages%2Fcustom-images%2F7ae1K6J7yacomputerS2.jpg?alt=media&token=56f1f9e9-d3aa-4537-acdb-ddfb4a0840ac',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9429%2Fimages%2Fcustom-images%2FV1NoNYOZXXsl_dirtylaptop-superJumbo-v2.gif?alt=media&token=db79dbb8-a554-4d95-be2b-c76705b86db8',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9429%2Fimages%2Fcustom-images%2FsAbFdZ9rgtgadstyle-home-banner1-950x475.png?alt=media&token=a571cabe-d81b-4dd0-b706-bd2c20447d67',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9429%2Fimages%2Fcustom-images%2FQMyje6ng10HP_BN_M_Flash-Sale_700x300.png?alt=media&token=565caa71-4b60-4385-8800-4e02be1ca129',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9429%2Fimages%2Fproducts%2FY9OJHJrnP5microsoft_surface_laptop_surface_laptop_3_pkh-00013_image_1.png?alt=media&token=3a68dad7-3cfd-4895-8d31-8c2e87718ce3',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9429%2Fimages%2Fproducts%2F5Cm4AJPWJO480Wx480H_604905_4.png?alt=media&token=c53d73dc-605b-4ea5-b939-e54db5e038e0',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9429%2Fimages%2Fproducts%2FhY5QD1x0K271T65kfBkXL._AC_SL1500_.png?alt=media&token=c01bf538-4060-436f-98d2-c70b98204dd7',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9429%2Fimages%2Fproducts%2FZ9LtAFkUgbAUESPEP4PASSIVESPEAKERBLACK5_1000x1000.png?alt=media&token=11f1abaf-b6e4-46ce-837b-5d5a76c56c2c',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9429%2Fimages%2Fproducts%2FnSwc6MEN3Uwfqs_gfx100-ii_thum.png?alt=media&token=d947ecc4-fe87-4ca8-8e1d-807ccf165ade',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9429%2Fimages%2Fproducts%2FeeCIxrh2oJ11-1653822165.png?alt=media&token=8f51f585-759a-46a3-9db9-c4a64f4b0cef',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2F2zfWM2HeqmGroup%20228.png?alt=media&token=4a55d012-6dfb-4424-aedc-b3cb1b6bd806',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2Fk0fjseZzcfGroup%20233.png?alt=media&token=5eb52de7-9c36-4d6c-92a9-5105c97c56ab',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2FRFzg1ZDvRcGroup%20242.png?alt=media&token=6a775b92-bae8-4789-a819-3df42ad70f9b',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2Fjwfr5gYIKEGroup%20232.png?alt=media&token=ab6db7b2-4735-467b-a8f0-5c80ae3ccc6f',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2FF83blllcKvGroup%20241.png?alt=media&token=583d3810-815d-4761-8648-9e7e24a400c2',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9430%2Fimages%2Fcustom-images%2FcbHxHgVS0mUntitled1_6b409a71-3766-4ff7-bf6a-fa5922017e7d.png?alt=media&token=87dd4d34-b761-44a3-a03e-94174b545997',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9430%2Fimages%2Fcustom-images%2FsfY0xbMaa64ba87af7-0f64-49ca-94bf-d717c20020d1.png?alt=media&token=9cd31fa9-8a65-48ae-98c0-a46142b86a5b',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9430%2Fimages%2Fproducts%2Fr4ACRVXMlo1.png?alt=media&token=4e281c22-82af-4fb8-b4a4-119eff4d0a7b',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9430%2Fimages%2Fproducts%2F1uFA0933kR5303-BK_3.png?alt=media&token=2b9b73b8-d8d0-4b45-858e-de944afe6069',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9430%2Fimages%2Fproducts%2Fni2lfjqSUx61hxc39exNL.png?alt=media&token=c19354b8-a742-43af-b942-51f3c20c38f1',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9430%2Fimages%2Fproducts%2FFLYEqbiyhj1%20(1).png?alt=media&token=0d4e119a-1833-4807-9b40-26cb9c627b0a',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2FkrgnQr1e2mGroup%20242.png?alt=media&token=dfb3f2a2-22bf-446e-ab90-83fa182f07e4',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2F9cwOf2sszYGroup%20243.png?alt=media&token=823b36a0-81d8-4b96-b702-1b00879979ae',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2FUYgprC9nwKGroup%20244.png?alt=media&token=54e0b70c-6f6f-4cc6-85af-122a49b065ec',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2FDgM6UFBpUAGroup%20245.png?alt=media&token=ffc56a83-6043-4f3e-9791-b37ce40fafa4',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9431%2Fimages%2Fcustom-images%2Fq1VzYKtvd4bags%20s1.jpg?alt=media&token=705200fd-d761-46c5-bbfd-ac70b5c8395a',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9431%2Fimages%2Fcustom-images%2FSO6G7NvFMAbags%20s2.jpg?alt=media&token=b02cb4d8-8b0b-491b-a0a8-7d395ccb0bf9',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9431%2Fimages%2Fproducts%2FPAdoV3U7gt1%20(2).png?alt=media&token=50ea1cb6-8e75-4b38-a62b-f13ece3d872a',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9431%2Fimages%2Fproducts%2FOh66XlyU4Bleather-backpack-black-calgary.png?alt=media&token=ab44d2dc-a560-427b-a8c2-5239d799c142',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9431%2Fimages%2Fproducts%2FzC0mz4vq5Bsquare_FALL23_Kanken_1X5A6949_1100x.png?alt=media&token=944c05f5-10b4-41a7-a65c-358754f21385',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9431%2Fimages%2Fproducts%2FaVn4G4ZVUbs-l1600.png?alt=media&token=5f8fd849-be26-4047-bb62-b70625a6de2e',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2FjBGp6ZXFz3777465442_max.png?alt=media&token=936b7b9a-a464-432d-bdf7-7756b58365ff',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2Fdyt5tD5zj4Backpack-Men-prime-S-Backpack-Large-Capacity-Backpack-Leisure-Trend-Sports-Bag-Computer-Bag-Male-Compute-Backpack-College-Schoolbag-Travel-Bag-Laptop-Backpack.png?alt=media&token=e45f316c-7d11-4fb6-8f1f-e8ae29b54723',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9432%2Fimages%2Fproducts%2F5RY2IGi8v34f2e52bc0846568599308e709146ddcf.png?alt=media&token=4a69a1bd-28ab-443f-a467-c1eae07091c8',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9432%2Fimages%2Fproducts%2FkwkgkP8EV76.png?alt=media&token=07aa0480-e7c6-4d7e-875c-b2fe7e471321',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9432%2Fimages%2Fproducts%2Fyz7EfgrMxy10.png?alt=media&token=e8abc187-dfc2-44c9-9b0b-0fd6aa5e2edf',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9432%2Fimages%2Fproducts%2FeKNFlQJjwH12.png?alt=media&token=90b84e06-1916-4fb4-bf11-d9e6a15eeb62',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9432%2Fimages%2Fproducts%2FEXrYxTDVYT51rjT6KTUAL.png?alt=media&token=ebb10610-b6d0-412e-90bd-c2572e5e6899',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2FPrdNswXHQBGroup%208201.png?alt=media&token=bd16d588-b3d6-42b5-aa21-db55d2b70a50',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2FAgvxEsg9SnGroup%208203.png?alt=media&token=44b27662-32ed-4193-bfa1-4fd856a85fc4',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2FUDE0jiIyWcGroup%208205.png?alt=media&token=2b628445-56bf-4c98-af7a-d4c615cf7dc7',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2FM0y3VKYWAgGroup%208202.png?alt=media&token=ad48a92e-4701-4ff8-bb48-623e756d00f9',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2FDFYR4SrJ7NGroup%208204.png?alt=media&token=fd7036af-8b74-424d-9b5e-5256226258ee',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9453%2Fimages%2Fcustom-images%2F71OKbHfZk6s1.png?alt=media&token=52988322-b25a-4983-abd2-269fd6d411fb',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fcustom-images%2FOiJRBVxDedproject_assets_2F9453_2Fimages_2Fcustom-images_2F47yX04Es7uS2.png?alt=media&token=f0752a11-671f-4efd-af50-efbaccde9ae5',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fcustom-images%2FYHLVj5W9bUproject_assets_2F9453_2Fimages_2Fcustom-images_2F9u5soY1c80do-a-nice-company-id-card.png?alt=media&token=cdcab493-8af0-4e42-8042-1470423b8028',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fcustom-images%2FBWL3GafsAfpRLkVRvHfepngwing.png?alt=media&token=d746d151-d25b-46cc-b019-455233cfabb8',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9787%2Fimages%2Fproducts%2FGUs1uKru5Cimage.png?alt=media&token=cc5e8020-df0f-4802-875b-b879595e647d',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9787%2Fimages%2Fproducts%2F4QQ441cf5wimage%20(1).png?alt=media&token=e097169a-39a5-4e8e-97bb-1367c8c5436c',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9787%2Fimages%2Fproducts%2FodAbtVWmTgimage%20(2).png?alt=media&token=9d266acf-d1ba-4c6b-8c9d-315d0f0f4d78',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9787%2Fimages%2Fproducts%2FxV2Kq9IQgn480Wx480H_372197_main.png?alt=media&token=c0e83088-42c4-4532-93ab-adcd9bac4dde',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9787%2Fimages%2Fproducts%2FMRNxvbCdZmimage%20(3).png?alt=media&token=1b0e8053-a9d3-463f-a2bf-599e7f461c47',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9787%2Fimages%2Fproducts%2FMRNxvbCdZmimage%20(3).png?alt=media&token=1b0e8053-a9d3-463f-a2bf-599e7f461c47',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9787%2Fimages%2Fproducts%2FKf0EfrJUmfimages.freshop.ncrcloud.png?alt=media&token=4791d0f6-834b-4df2-8076-c3ce828db4be',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9787%2Fimages%2Fproducts%2Ff0ttzOwQrevader-prod.s3.amazonaws%20(1).png?alt=media&token=c203a1e2-6d72-420a-8182-b58ab94dbeda',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9787%2Fimages%2Fproducts%2FmTEkvXC8SR6223001342779-1.png?alt=media&token=3faa67fb-dfe9-4ed9-9a20-48a1babfcc3f',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9787%2Fimages%2Fproducts%2FEU0BLw4Y8Yimage%20(4).png?alt=media&token=cbbc117f-fd7f-4e91-bcd9-cfebb472a18f',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F4184%2Fimages%2Fcategories%2ForqLWvHX4mGroup%2020.png?alt=media&token=582d73e3-4386-4f97-9ab9-4de424cbab71',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F4184%2Fimages%2Fcategories%2FWPnR6rcREnGroup%2022.png?alt=media&token=7857613d-4399-4ad7-9d53-42b6a52535f4',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F4184%2Fimages%2Fcategories%2FzF8FoLCoziGroup%2019.png?alt=media&token=a071bd53-0182-475a-8e05-75917ef6eec5',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F4184%2Fimages%2Fcategories%2Fsl3ySZ1nMYGroup%2018.png?alt=media&token=b29022e5-e9ac-4b11-a1e0-ecd5bf5cec1f',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9787%2Fimages%2Fcustom-images%2FNat6b7Om92opticsbanner.png?alt=media&token=05c662dc-3b27-4aee-a635-471553f3f1ea',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fcustom-images%2FH2CBimdIoK79RYdW2BLkApp-banner-2.png?alt=media&token=7b81eee4-d831-4b1e-bd89-5ac92a7f60cc',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2FbC0suB2omk617UBb-F4AL._AC_UF894%2C1000_QL80_.png?alt=media&token=1a1bd675-89c7-44ff-b9e8-0bb4389a379d',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2Fvb1GD86SbW1.png?alt=media&token=05909296-354c-48d8-819c-fe57f28b5337',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2F61tDP3iwr71a66221e4a73143b35dc07da71823bfe.png?alt=media&token=3c0a297a-d380-4198-8f72-2d9dc8c9187b',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2F3h4cWAaAPibrunoslider2_1200x630.png?alt=media&token=ca71d18b-bb96-4b6c-b4e6-a52d008cc569',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2Fij9WrJNl2WScd9051abfc9945b4939b00caea6c281aM.jpg_640x640Q90.jpg_.png?alt=media&token=43b1e247-41b4-4d08-9ca6-131835ce6671',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2FIPgCKpgzarimage%20(3).png?alt=media&token=075e61fe-6627-4927-baa2-83552ef179d9',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2FJaAkmhI4Pl1%20(9).png?alt=media&token=da8db7ff-7e43-42fb-ad19-80ec1e80db37',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2FijC8FHtttKBarbiecore_blog_feature.png?alt=media&token=7eeed0d5-9599-4991-a72f-edf1bdf71b98',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fproducts%2FW3kZId6TYe1%20(8).png?alt=media&token=a657e123-ac0f-4d36-9500-0378941db844',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9787%2Fimages%2Fcustom-images%2FUxFqAcEno1opticss1.jpg?alt=media&token=66770aca-a9dd-42d4-aa1a-3422651d8ecd',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fcustom-images%2FfmpDvYvND1project_assets_2F9787_2Fimages_2Fcustom-images_2FmNhK9D7jN2opticss2.png?alt=media&token=3b08eb86-6206-499e-a76b-3df1fc6c3d91',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fcustom-images%2Fv4wwYrd4PN79RYdW2BLkApp-banner-2.png?alt=media&token=c21464a1-fd7b-440b-9a1e-13860c7f7a74',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F1401%2Fimages%2Fcustom-images%2F1CKUr4K5GGproject_assets_2F9455_2Fimages_2Fcustom-images_2FgIddG7UMU4Special_Offers_Banner_Eye.glasse_Mobile.png?alt=media&token=8ebb1740-596d-48c3-911c-d184cb6fca27',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9457%2Fimages%2Fcustom-images%2F62MjHBYxnS5.png?alt=media&token=7778bfed-a550-4925-ac6e-2192803645a2',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9457%2Fimages%2Fcustom-images%2FrFwafnd0t3Untitled-2Artboard-1.png?alt=media&token=8fd061cc-8036-40cf-b140-919031b1385e',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9457%2Fimages%2Fcustom-images%2FM4le7FT721Untitled-2Artboard.png?alt=media&token=43c687d2-f41c-4662-b90f-4bc24490adf2',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9457%2Fimages%2Fcustom-images%2Fwx4sAJ9WKnUntitled-2Artboar.png?alt=media&token=9444c89b-86a6-4b68-b52d-2a3297e5a4e5',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9457%2Fimages%2Fcustom-images%2FohDPbshIUB3.png?alt=media&token=0c8e5f7c-641e-4531-b3e6-d1ea6b5ebe79',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9457%2Fimages%2Fcustom-images%2FHu3Da5OwVs1.png?alt=media&token=65ac176b-74ad-4e62-94ac-2f4e0b0a1d55',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9457%2Fimages%2Fcustom-images%2FP1Xa45huFJ2.png?alt=media&token=756eaa29-d88e-4ea2-9a9a-dff7c188d910',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9457%2Fimages%2Fcustom-images%2F45EKzNxRxt4.png?alt=media&token=3de919e1-27c0-4830-8fcb-b84d96632c40',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9457%2Fimages%2Fcustom-images%2F1lJGGgbEkT1.png?alt=media&token=f7e0233b-e66d-4531-9de3-97212f50f564',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9457%2Fimages%2Fcustom-images%2FXevjaZ3Cyr2.png?alt=media&token=f09dc319-8423-4c50-b7e9-ff5103d3a477',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9457%2Fimages%2Fcustom-images%2FWsI8cucnmf3.png?alt=media&token=3008eb76-b776-4b2e-a3c2-388ab89d8672',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9457%2Fimages%2Fcustom-images%2FFxMrgZGCo0Untitled-3.png?alt=media&token=5e8f2758-3335-48b5-bc83-a10e215187ca',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2F7QgndrwP8dmedicine.png?alt=media&token=54776024-babe-4911-9bad-c6af58bf95ee',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2F9t2gVC8N2Uvitamins.png?alt=media&token=d31c4a38-79c4-4f87-b128-908c568dc8da',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2FZAqlLceev9beaty-care.png?alt=media&token=491108e8-c978-40ae-bd7c-ebbecd0e2c4f',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2Fl7CjchUHE3baby-care.png?alt=media&token=00c47696-98ca-405a-8b70-0edc6ec20b6d',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fproducts%2F3JfPENPjdBSuperkids-Kids-Shampoo-strawberry-Milkshake-Fragrance-300ml-_1_1f5339df-8aa5-44f3-9733-f53877e083ae_400x.png?alt=media&token=6ae282e5-5e7a-49e0-8285-6341e739f342',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fproducts%2FLvBtgtEosMjns_381372022082_us_first_touch_gift_set_1set_00000_2000wx2000h.png?alt=media&token=e27d3bdd-753f-46f1-95a0-607c345da582',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fproducts%2F4WSdkB7yNj15000309008693-MAIN.png?alt=media&token=c0d9f8f3-18be-4659-88a5-20efe3ec092f',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fproducts%2F6WQO8CWmKySemenax-2-Bottles-120ct-Supplement-With-Vitamin-E-and-Swedish-Flower-Pollen_263d7eff-df5b-4d3c-af91-c832b61c6b04.6468f11819cf260132297f80db543fec.png?alt=media&token=504f203e-5052-4980-a3a0-a59b1bd42f7f',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fproducts%2Fznsj7mq5igCO_SCKMJC2.png?alt=media&token=9b3967d8-6f5d-4fd1-9ce6-776d4bcdcecf',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9787%2Fimages%2Fproducts%2FEIuwGuImAbimage%20(5).png?alt=media&token=e72f9e0e-340f-41ec-98cf-b4fdb8fb0fc0',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fproducts%2FUCMm7tmmEN1.png?alt=media&token=2fb4203e-1877-4dad-af23-64e905125968',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fproducts%2FGuMwlYYoVq71QbcP49A3L.png?alt=media&token=6b94d56a-4a54-48b4-85ba-21ae968873a7',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9457%2Fimages%2Fcustom-images%2Fz8aYoJDfOx2924624b72ad602afebc66b4cb862a11.png?alt=media&token=7cc969c4-77b3-49aa-8f21-6dfcfc115ac6',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9458%2Fimages%2Fcustom-images%2Fprl5d09yUjvbanner.png?alt=media&token=51452666-5dc2-41fa-b5f8-56e9e167423d',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9787%2Fimages%2Fcustom-images%2Ff7DervrCQpcaraccs1.jpg?alt=media&token=2cd9b870-e5a1-476d-bdb1-a5af547cd924',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9458%2Fimages%2Fcustom-images%2FsuMwUvGpR5caraccs3.png?alt=media&token=2b6fb894-22c2-496f-86ee-104b61f2212f',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9458%2Fimages%2Fcustom-images%2FsuMwUvGpR5caraccs3.png?alt=media&token=2b6fb894-22c2-496f-86ee-104b61f2212f',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9458%2Fimages%2Fcustom-images%2FGOAytyucfwcaraccs1.png?alt=media&token=0167b456-13b8-4fb2-acd8-405be778e24f',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9458%2Fimages%2Fproducts%2FBMj202ziP81%20(10).png?alt=media&token=3d2c4559-f0fe-4e51-bbd4-ce99d973ddf7',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fproducts%2FL3TJVOcgeCimage.png?alt=media&token=937a471f-ffdc-498b-928f-1828d43722bd',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fproducts%2FHR3eB9JcHT1%20(1).png?alt=media&token=bf4f5d64-4b0a-4ccb-a82f-1ea739c3ee7c',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9458%2Fimages%2Fproducts%2FcxVtf2hCYG1%20(11).png?alt=media&token=6f0632a0-632b-4698-a84e-248823d76151',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fproducts%2F3s29AjifMts-l1200.png?alt=media&token=8aafc651-aafc-4707-b1a8-6110cc2103f8',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fproducts%2FrUi0OmL8K0PRO-X6.4BM-RIGHT-2000X2000_1200x.png?alt=media&token=99faee89-cbcb-4c9c-8b85-bb9990e6f506',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fproducts%2FGaBb0VhVaJ81-lWwhepeL._AC_SX466_PIbundle-4_TopRight_0_0_SH20.png?alt=media&token=2b4a2ef3-03cc-4850-9460-f7d3373a4376',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fproducts%2FGFOejjVG2W81DFnVr7FqS._AC_UL600_SR600%2C600_.png?alt=media&token=7cb909a4-93ef-40c2-af93-496c3fc96107',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2FIp2MUY5COP6105iHDrYRL.png?alt=media&token=99f49668-1315-494a-a50d-e35cff4ef9e1',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2FLONTtfLtQBPRO-X6.4BM-RIGHT-2000X2000_1200x.png?alt=media&token=4af556c6-d530-4584-87db-1f1caec7d677',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2FbIdOjeKAj21.png?alt=media&token=cb10dcbc-1416-4593-88cc-842270253f1c',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2F77ypSyDyeQcustom-tail-lights_6.png?alt=media&token=ba25ae48-fa61-4f6c-bd4a-823d2687739a',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9459%2Fimages%2Fcustom-images%2FsIDFRIrqP5s5.png?alt=media&token=ca260a06-8ce6-44a2-9b56-4b23c48d4b03',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9459%2Fimages%2Fcustom-images%2FPSRqwhArKvss1.png?alt=media&token=00b8ff3a-5059-4855-9bba-50086203ca18',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9459%2Fimages%2Fcustom-images%2FjjUQBx6Dztss2.png?alt=media&token=f6b522ed-aee6-4625-bf88-d0b4a9c8886c',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9459%2Fimages%2Fcustom-images%2FjjUQBx6Dztss2.png?alt=media&token=f6b522ed-aee6-4625-bf88-d0b4a9c8886c',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9459%2Fimages%2Fcustom-images%2FjjUQBx6Dztss2.png?alt=media&token=f6b522ed-aee6-4625-bf88-d0b4a9c8886c',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9459%2Fimages%2Fcustom-images%2FTR3Z8vXrdoss5.png?alt=media&token=938c560b-15e4-47e1-8b54-2bc885b9e469',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F9459%2Fimages%2Fcustom-images%2FAvEHiJ8goPandsplash.png?alt=media&token=7542cd50-a0b2-436d-8245-7f86fe60e5a0',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fproducts%2FW1rtC7vFH0images.ctfassets.png?alt=media&token=b40a0fba-d098-4a63-8852-ac9f0d7f3618',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fproducts%2FY4tFymR8tyWhat-Breed-Are-Black-Cats.png?alt=media&token=c408b91e-ab95-4af3-aba2-5c0beb9e64fe',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fproducts%2FI2AYTwwfrhdog-jumping-air-small-orange-600nw-2339878403.png?alt=media&token=3e584650-08e1-4590-9787-a2dc1feb3e39',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fproducts%2FnMT0u8RyOW2e4152510fefe2f1f32cfc247766f373.png?alt=media&token=d30cafd1-ba82-40d8-b483-b83e69dcc9bd',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fproducts%2FOkVDYRfiLuShop_By_Pet_Bird_2022_450x.png?alt=media&token=72a14c87-5638-4d4e-8609-b315529bd27f',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fproducts%2Fy9zO1gX3GXblue-fronted-redstart-the-beautiful-blue.png?alt=media&token=167d1c7d-530d-4b92-ac18-0bc89eb72b9d',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fproducts%2Fl3kwaXty77fish-bowl-6-a-vijyas-original-imafyhxryran2fup.png?alt=media&token=07d03ed5-e625-45fc-80e5-3968535fb4ae',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fproducts%2FNRxtliDEVv61mqFDXb3GL._SL1500.png?alt=media&token=3712b489-69b2-4777-88c5-e337fc47e06f',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2FRhT0mOHUBScat.png?alt=media&token=bca6f2ac-6877-4467-8efe-6583736914ef',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2F9GqGb1PvHSdog.png?alt=media&token=0235baec-fe3f-44af-a196-5aade8e239fd',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2Fdhd3hV1EARbird.png?alt=media&token=903e69ee-e6aa-4464-8bb6-b005baeda1de',
        'https://firebasestorage.googleapis.com/v0/b/neat-planet-378416-live/o/project_assets%2F8764%2Fimages%2Fcategories%2FscrEc7bhJ5fish.png?alt=media&token=8e21949e-bcfd-4298-bd89-5be72f238b5a'
      ];
      // search for imaeg name in protectedImages array
      for (let index = 0; index < protectedImages.length; index++) {
        const element = protectedImages[index];
        // if element is part of the fileURL, then return
        if(fileURL.includes(element)) {
          observer.next("image deleted"); // Notify the observer that the operation is complete
          observer.complete(); // Complete the Observable
          return;
        }
      }
    const storage = getStorage();
    const fileRef = refStorage(storage, fileURL);
      // Delete the file
      deleteObject(fileRef).then(() => {
        observer.next("image deleted"); // Notify the observer that the operation is complete
        observer.complete(); // Complete the Observable
      }).catch((error) => {
        observer.error(error); // Notify the observer of an error
      });
    });
    
  }

  getCustomViews = () => {
    return this.customViews.filter((view: any) => {
      if(view.view_name == 'global_header' || view.view_name == 'global_footer') {
        return false;
      } else {
        return true;
      }
    });
   }

  importDummyProducts = () => {
    return new Observable<any>((observer) => {
    this.db.getDatabase('dummy_products').subscribe((dummyProducts) => {
      let newProducts:any = [];
      this.db.getDatabase('projects/' + this.selectedProject + '/products/').subscribe((currentProducts: any) => {
        // add the currentProducts and the dummyProducts to newProducts
        for (const key in currentProducts) {
          if (Object.prototype.hasOwnProperty.call(currentProducts, key)) {
            const element = currentProducts[key];
            newProducts[key] = element;
          }
        }
        for (const key_dummy in dummyProducts) {
          if (Object.prototype.hasOwnProperty.call(dummyProducts, key_dummy)) {
            const element_dummy = dummyProducts[key_dummy];
            newProducts[key_dummy] = element_dummy;
          }
        }
        this.db.setDatabase('projects/' + this.selectedProject + '/products/', newProducts).subscribe((response) => {
          observer.next(); // Notify the observer that the operation is complete
          observer.complete(); // Complete the Observable
        });
      });
    });
  });
  }

   importDummyCategories = () => {
    return new Observable<any>((observer) => {
    this.db.getDatabase('dummy_categories').subscribe((dummyCats:any) => {
      // get all the categories of the project and append the response to those categories after adjusting the index of the dummy categories
      this.db.getDatabase('projects/' + this.selectedProject + '/categories/').subscribe((currentCategories: any) => {
        // make the dummyCats keys start after the keys of currentCategories and assign to newCategories
        let newCategories = [];
        let currentCategoriesIndex = 0;
        for (const key in currentCategories) {
          if (Object.prototype.hasOwnProperty.call(currentCategories, key)) {
            const element = currentCategories[key];
            newCategories[currentCategoriesIndex] = element;
            currentCategoriesIndex++;
          }
        }
        dummyLoop: for (const keyDummyCats in dummyCats) {
          if (Object.prototype.hasOwnProperty.call(dummyCats, keyDummyCats)) {
            const element_dummyCats = dummyCats[keyDummyCats];
            // if newCategories already has this category, then skip it, checking with the category id
            for (let index = 0; index < newCategories.length; index++) {
              const newCategory = newCategories[index];
              if(newCategory.categoryId == element_dummyCats.categoryId) {
                continue dummyLoop;
              }
            }
            newCategories[currentCategoriesIndex] = element_dummyCats;
            currentCategoriesIndex++;
          }
        }
        this.db.setDatabase('projects/' + this.selectedProject + '/categories/', newCategories).subscribe((response) => {
          observer.next(); // Notify the observer that the operation is complete
          observer.complete(); // Complete the Observable
        });
      });

    });
    });
   }

   cookieSetter = (name: string, value: any, days: any) => {
    var expires = '';
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + value + expires + '; path=/'; // Set path to root '/'
 }

 cookieGetter = (name: string) => {
    var nameEQ = name + '=';
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1, cookie.length);
        }
        if (cookie.indexOf(nameEQ) === 0) {
            return cookie.substring(nameEQ.length, cookie.length);
        }
    }
    return null;
 }
}

import { BuilderService } from 'src/app/builder-services/builder.service'
import { Component, OnInit } from '@angular/core'
import { DatabaseService } from 'src/app/builder-services/database.service'
import { FormBuilder, FormGroup } from '@angular/forms'
import { HttpClient } from '@angular/common/http'
import { MatTableDataSource } from '@angular/material/table'
import { SettingsService } from 'src/app/e-commerce/settings/settings.service'
import { forkJoin, from, Observable, of } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'
import { MultilingualPipe } from '../multilingual.pipe'
import { BlockDataService } from 'src/app/block-services/block-data.service'
import { ProductsService } from 'src/app/e-commerce/products/products.service'
import { ShippingService } from 'src/app/e-commerce/shipping/shipping.service'

interface TableRow {
  [key: string]: any
  Currency?: string
  En: string
}

@Component({
  selector: 'app-txt-translations',
  templateUrl: './txt-translations.component.html',
  styleUrl: './txt-translations.component.scss'
})
export class TxtTranslationsComponent implements OnInit {
  currency: any
  currentPageIndex: number = 0
  displayedColumns: string[] = []
  editingCell: { row: TableRow | null; lang: string | null } = {
    row: null,
    lang: null
  }
  filteredData: TableRow[] = []
  languages: { title: string }[] = []
  pagedTableData: MatTableDataSource<TableRow> = new MatTableDataSource<TableRow>([])
  pageSize: number = 10
  pageSizeOptions: number[] = [5, 10, 25, 50, 100]
  searchText: string = ''
  stacksTranslation = false
  tableData: TableRow[] = []
  txtTranslations!: FormGroup
  productProgress: boolean = false
  allSectionTexts: string[] = []
  project_id: string | undefined
  constructor(
    private BuilderService: BuilderService,
    private db: DatabaseService,
    private http: HttpClient,
    private blockDataService: BlockDataService,
    private translatePipe: MultilingualPipe,
    private settingsService: SettingsService,
    private productService: ProductsService,
    private shippingService: ShippingService,
    private fb: FormBuilder
  ) {
    this.project_id = this.BuilderService.selectedProject
  }

  /*
   * ngOnInit: Initializes the component.
   * - Sets up the form group for translations.
   * - Initializes an array for languages.
   * - Loads available languages and retrieves currency data.
   */

  ngOnInit(): void {
    this.txtTranslations = this.fb.group({
      languages: this.fb.array([])
    })
    this.loadLanguages()
    this.getCurrency()
  }

  /*
   * updatePageData: Updates the displayed data in the paginated table.
   * - Takes a pageIndex as a parameter to determine which data to display.
   * - Calculates start and end indices based on the current page index and page size.
   * - Slices the filtered data to set the data for the paginated table.
   */

  updatePageData(pageIndex: number): void {
    const startIndex = pageIndex * this.pageSize
    const endIndex = startIndex + this.pageSize
    this.pagedTableData.data = this.filteredData.slice(startIndex, endIndex)
  }

  /*
   * onPageChange: Handles the page change event for the paginated table.
   * - Updates the page size and current page index based on the event.
   * - Calls updatePageData to refresh the displayed data for the current page.
   */

  onPageChange(event: any) {
    this.pageSize = event.pageSize
    this.currentPageIndex = event.pageIndex
    this.updatePageData(this.currentPageIndex)
  }
  /*
   * getTranslate: Fetches translations for the selected project and aggregates
   * the data from multiple languages.
   * - Retrieves the selected project ID from the BuilderService.
   * - Checks if any languages are available; logs a message and exits if none.
   * - Initializes a counter and an object to hold aggregated translation data.
   * - Iterates over each language to construct the unique database path for translations.
   * - Subscribes to the database observable to fetch translations for each language.
   * - Aggregates translations into the allData object, using the 'En' key as a reference.
   * - When all language data is loaded, converts the aggregated data to an array,
   *   adds a currency row, filters the data, and updates the displayed page data.
   * - Logs an error if the fetched data is not in the expected format or if the fetch fails.
   */

  getTranslate(): void {
    if (!this.languages.length) {
      console.log('No languages available.')
      return
    }

    let dataLoadedCount = 0
    const allData: { [key: string]: any } = {}

    this.languages.forEach((lang) => {
      const uniquePath = `/projects/${this.project_id}/translations/languages/${lang.title}/texts`
      this.db.getDatabase(uniquePath).subscribe(
        (translationsData: any) => {
          if (translationsData && typeof translationsData === 'object') {
            // Aggregate data from each language
            for (const key in translationsData) {
              if (translationsData.hasOwnProperty(key)) {
                if (!allData[key]) {
                  allData[key] = { En: key } // Assume 'En' key is your reference
                }
                allData[key][lang.title] = translationsData[key].value
              }
            }
            // Check if all languages are loaded
            dataLoadedCount++
            if (dataLoadedCount === this.languages.length) {
              this.tableData = Object.values(allData) // Convert aggregated data to array
              this.fetchAndAddTextRows()
              this.addCurrencyRow()
              this.filterData()
              this.updatePageData(this.currentPageIndex)
            }
          } else {
            console.error(`Translations data for ${lang.title} is not in the expected format:`, translationsData)
          }
        },
        (error: any) => {
          console.error(`Failed to fetch translations for ${lang.title}:`, error)
        }
      )
    })
  }

  /*
   * handleMissingTranslations: Processes translation requests for missing language translations in the data.
   * @param tableDataObject - The table data in object format where each key corresponds to a row.
   * @returns The updated table data object with missing translations filled in.
   * - Translates data column by column (language by language) instead of row by row for improved network efficiency.
   * - Iterates over the defined languages and identifies any missing translations for each column (language) in the tableDataObject.
   * - For each missing translation in the column, it calls a translation pipe to fetch the appropriate translation.
   * - Uses `forkJoin` to combine observable translation requests for all rows in the current column, ensuring all translations
   *   for that column are retrieved before proceeding to the next language.
   * - Handles languages sequentially using `mergeMap` with a concurrency limit of 1, processing one column at a time.
   */

  handleMissingTranslations(allData: { [key: string]: { [key: string]: string | undefined } }): void {
    const translateColumn = (lang: { title: string }) => {
      const translationRequests = Object.keys(allData).map((key) => {
        const row = allData[key]
        if (!row[lang.title]) {
          return this.translatePipe.transform(row.En || '', lang.title).pipe(
            map((translatedText) => {
              row[lang.title] = translatedText
            })
          )
        } else {
          return of(null)
        }
      })

      return forkJoin(translationRequests)
    }

    // Process columns (languages) one by one
    from(this.languages)
      .pipe(
        mergeMap((lang) => translateColumn(lang), 1) // Process one column at a time
      )
      .subscribe(
        () => {
          this.tableData = Object.values(allData) as TableRow[]
          this.addCurrencyRow()
          this.filterData()
          this.updatePageData(this.currentPageIndex)
        },
        (error) => {
          console.error('Error translating data:', error)
        }
      )
  }

  /*
   * callStacksTranslation: Initiates the translation process by setting a loading indicator.
   * - Sets the stacksTranslation flag to true to indicate that translation is in progress.
   * - Calls the loadData() method to fetch the necessary translations.
   */
  callStacksTranslation() {
    this.stacksTranslation = true
    this.loadData()
  }

  /**
   * Loads data from multiple sources including products, menu items, section texts, button texts,
   * and country names. Integrates the fetched data into the `tableData` structure and ensures
   * duplicates are removed. Handles asynchronous fetching of:
   * - Product variation values and attribute names
   * - Menu items, section texts, and button texts
   * - Country names
   * - Translation data
   *
   * Ensures the `tableData` is updated with the latest combined information.
   */
  loadData(): void {
    this.productService.getProducts().subscribe(
      (productsObject) => {
        const { productVariationValues, productAttributeNames } = this.extractProductData(productsObject)

        const menuItems = this.getMenuItems()
        const blocksData = this.blockDataService.getAllBlocksData()
        const buttonTexts = this.extractButtonTexts(blocksData)
        const newSectionTexts = this.extractSectionTexts(blocksData)

        // Flatten so we get strings, not arrays
        const flattenedSectionTexts = newSectionTexts.flat()

        const uniqueNewSectionTexts = flattenedSectionTexts.filter((text) => !this.allSectionTexts.includes(text))

        // Push unique items
        this.allSectionTexts.push(...uniqueNewSectionTexts)

        this.getCountryAndGovernorateNames().subscribe(
          ({ countryNames, governorateNames }) => {
            this.http.get<string[]>('assets/i18n/txtTranslation.json').subscribe(
              (translationData: string[]) => {
                this.integrateDataIntoTable({
                  translationData,
                  menuItems,
                  buttonTexts,
                  sectionTexts: this.allSectionTexts,
                  productVariationValues,
                  productAttributeNames,
                  countryNames,
                  governorateNames
                })

                this.postProcessUpdates()
              },
              (error) => {
                console.error('Error fetching translation data:', error)
              }
            )
          },
          (error) => {
            console.error('Error fetching countries:', error)
          }
        )
      },
      (error) => {
        console.error('Error fetching products:', error)
      }
    )
  }

  /* ---------------------------------------------------------------------
   Helper Methods
   --------------------------------------------------------------------- */

  /**
   * Extracts product variation values and attribute names from the given products array.
   *
   * @param productsObject Array of product objects returned from the productService.
   * @returns An object containing arrays of productVariationValues and productAttributeNames.
   */
  private extractProductData(productsObject: any[]): {
    productVariationValues: string[]
    productAttributeNames: string[]
  } {
    const productVariationValues: string[] = []
    const productAttributeNames: string[] = []

    productsObject.forEach((product: any) => {
      if (product.productVariations) {
        product.productVariations.forEach((variation: any) => {
          if (typeof variation.values === 'string' && variation.values.trim().length > 0) {
            const trimmedVal = variation.values.trim()

            // Check if multiple values are present
            if (trimmedVal.includes(',')) {
              // Split by comma and push each value
              trimmedVal.split(',').forEach((val: string) => {
                productVariationValues.push(val.trim())
              })
            } else {
              // Single value scenario
              productVariationValues.push(trimmedVal)
            }
          }
        })
      }

      // Extract product attribute names
      if (product.productAttributes && Array.isArray(product.productAttributes)) {
        product.productAttributes.forEach((attr: any) => {
          if (attr.name) {
            productAttributeNames.push(attr.name)
          }
        })
      }
    })

    return { productVariationValues, productAttributeNames }
  }

  /**
   * Retrieves menu items from the BuilderService global footer data.
   */
  private getMenuItems(): string[] {
    return (this.BuilderService.globalFooter.data.menuItems as { menuText: string }[] | undefined)?.map((item) => item.menuText) || []
  }

  /**
   * Extracts button texts from any blocks of type "button".
   *
   * @param blocksData The array of blocks from the blockDataService.
   */
  private extractButtonTexts(blocksData: any[]): string[] {
    return blocksData.filter((block: any) => block.type === 'button' && block.data?.btnText).map((block: any) => block.data.btnText) || []
  }

  /**
   * Extracts textual content (including button text) from section blocks.
   *
   * @param blocksData The array of blocks from the blockDataService.
   */
  private extractSectionTexts(blocksData: any[]): string[] {
    return blocksData
      .filter((block: any) => block.type === 'section')
      .flatMap((block: any) => {
        return block.elements.flatMap((column: any) => {
          return column.elements.map((element: any) => {
            const btnText = element.data?.btnText || ''
            const value = element.data?.value ? this.extractTextFromHTML(element.data.value) : ''
            // Filter out empty strings
            return [btnText, value].filter(Boolean)
          })
        })
      })
  }

  /**
   * Combines all data sources, removes duplicates, maps them to table rows,
   * and updates the existing `tableData`.
   */
  private integrateDataIntoTable({
    translationData,
    menuItems,
    buttonTexts,
    sectionTexts,
    productVariationValues,
    productAttributeNames,
    countryNames,
    governorateNames
  }: {
    translationData: string[]
    menuItems: string[]
    buttonTexts: string[]
    sectionTexts: string[]
    productVariationValues: string[]
    productAttributeNames: string[]
    countryNames: string[]
    governorateNames: string[]
  }): void {
    // Combine data
    const allData = [...translationData, ...menuItems, ...buttonTexts, ...sectionTexts, ...productVariationValues, ...productAttributeNames, ...countryNames, ...governorateNames]
    // Remove duplicates
    const uniqueData = Array.from(new Set(allData))

    // Map to TableRow objects
    const newData: TableRow[] = uniqueData.map((text) => ({ En: text }))

    // Merge new data with existing tableData
    newData.forEach((newRow) => {
      const existingRow = this.tableData.find((row) => row.En === newRow.En)
      if (!existingRow) {
        this.tableData.push(newRow)
      }
    })
  }

  /**
   * Retrieves a list of country names and their governorate names by fetching data from the shipping service.
   */
  private getCountryAndGovernorateNames(): Observable<{ countryNames: string[]; governorateNames: string[] }> {
    return this.shippingService.getCountries().pipe(
      map((countries: any[]) => {
        const countryNames = countries.map((country) => country.countryName)
        const governorateNames: string[] = []

        countries.forEach((country) => {
          if (country.governorates && Array.isArray(country.governorates)) {
            country.governorates.forEach((governorate: any) => {
              governorateNames.push(governorate.governorateName)
            })
          }
        })
        return { countryNames, governorateNames }
      })
    )
  }

  /**
   * postProcessUpdates: Performs necessary post-processing steps after `this.tableData` has been updated.
   * - Fetches and adds additional text rows by calling `fetchAndAddTextRows()`.
   * - Incorporates currency-specific data into the table by invoking `addCurrencyRow()`.
   * - Applies any active filters to the updated data using `filterData()`.
   * - Refreshes the displayed data for the current page index with `updatePageData()`.
   * - If `stacksTranslation` is enabled:
   *   - Converts `tableData` (an array of rows) into an object format with unique keys for compatibility with `handleMissingTranslations`.
   *   - Calls `handleMissingTranslations()` to process and fill in any missing translations in the data.
   */

  private postProcessUpdates(): void {
    this.fetchAndAddTextRows()
    this.addCurrencyRow()
    this.filterData()
    this.updatePageData(this.currentPageIndex)

    if (this.stacksTranslation) {
      // Convert tableData array into an object
      const tableDataObject = this.tableData.reduce((acc, row, index) => {
        acc[index] = row
        return acc
      }, {} as { [key: string]: { [key: string]: string | undefined } })

      this.handleMissingTranslations(tableDataObject)
    }
  }

  /*
   * toggleEdit: Toggles the edit mode for a specific cell in the table.
   * - Accepts a row of type TableRow and a language string as parameters.
   * - If the specified row and language are already being edited, it resets the editingCell to null.
   * - Otherwise, it sets the editingCell to the provided row and language, enabling editing for that cell.
   */

  toggleEdit(row: TableRow, lang: string): void {
    if (this.editingCell.row === row && this.editingCell.lang === lang) {
      this.editingCell = { row: null, lang: null }
    } else {
      this.editingCell = { row, lang }
    }
  }

  /*
   * filterData: Filters the table data based on the user's search input.
   *
   * 1. If there is a search string, convert it to lowercase for case-insensitive matching.
   * 2. For each row in `tableData`, check the following fields:
   *    - `En` (English field), if present.
   *    - `Currency`, if present.
   *    - Each language-specific field (from `this.languages`), if present.
   *    If any of these contains the search string, include the row in `filteredData`.
   * 3. If no search string is provided, copy the entire `tableData` into `filteredData`.
   * 4. Call `updatePageData(0)` to reset pagination to the first page.
   */

  filterData(): void {
    if (this.searchText) {
      const searchLower = this.searchText.toLowerCase()
      this.filteredData = this.tableData.filter((row) => {
        const enValue = row.En ? String(row.En).toLowerCase() : ''
        const currencyValue = row.Currency ? String(row.Currency).toLowerCase() : ''
        const matchEn = enValue.includes(searchLower)
        const matchCurrency = currencyValue.includes(searchLower)

        // Check across your languages array:
        const matchLang = this.languages.some((lang) => {
          const langValue = row[lang.title] ? String(row[lang.title]).toLowerCase() : ''
          return langValue.includes(searchLower)
        })

        return matchEn || matchCurrency || matchLang
      })
    } else {
      // Copy all data if no search text
      this.filteredData = [...this.tableData]
    }
    this.updatePageData(0) // Reset to the first page after filtering
  }

  /*
   * loadLanguages: Loads available languages for the selected project from the database.
   * - Constructs a unique database path using the selected project ID.
   * - Fetches language data and logs it to the console.
   * - Checks if the fetched data is an object; if so, transforms it into an array of language objects,
   *   each containing a title and its corresponding translation.
   * - Searches for the index of the "English" language and, if found and not already first,
   *   moves it to the beginning of the languages array.
   * - Updates the displayedColumns to reflect the available languages and calls getTranslate() to
   *   load the translations.
   * - Logs an error if the data format is unexpected or if the fetch operation fails.
   */

  loadLanguages(): void {
    const uniquePath = `/projects/${this.project_id}/translations/languages`

    this.db.getDatabase(uniquePath).subscribe(
      (languagesData: any) => {
        if (languagesData && typeof languagesData === 'object') {
          // Transform languagesData object into an array of language objects
          this.languages = Object.keys(languagesData).map((key) => ({
            title: key,
            translation: languagesData[key]
          }))

          // Find the index of "English" language
          const englishIndex = this.languages.findIndex((lang) => lang.title === 'English')

          // If "English" is found and it's not already the first element
          if (englishIndex !== -1 && englishIndex !== 0) {
            // Move "English" to the beginning of the array
            const englishLanguage = this.languages.splice(englishIndex, 1)[0]
            this.languages.unshift(englishLanguage)
          }
          this.displayedColumns = [...this.languages.map((lang) => lang.title)]
          this.getTranslate()
        } else {
          console.error('Languages data is not in the expected format:', languagesData)
        }
      },
      (error) => {
        console.error('Error fetching languages data:', error)
      }
    )
  }

  /*
   * saveChanges: Saves updated translations for all languages associated with the selected project.
   * - Sets a loading indicator to indicate that the save operation is in progress.
   * - Defines an interface for translations to ensure proper typing.
   * - Iterates over each row in tableData, initializing language-specific fields if they are undefined.
   * - For each language, creates a translationsObject where each key is a valid English term and
   *   its value is an object containing the translation.
   * - Special handling is applied for the English language, where the value is set to the term itself.
   * - Constructs a unique database path for each language's translations and saves the translationsObject
   *   to the database.
   * - Logs success messages upon saving or error messages if the save operation fails,
   *   and updates the loading indicator accordingly.
   */

  saveChanges(): void {
    this.productProgress = true
    interface Translations {
      [key: string]: { value: string }
    }
    // Iterate over each row in tableData and update local values if needed
    this.tableData.forEach((row) => {
      this.languages.forEach((lang) => {
        row[lang.title] = row[lang.title] || ''
      })
    })

    this.languages.forEach((lang) => {
      let translationsObject: Translations = {} // Define the type of translationsObject

      // Create an object where key is the English term and value is the nested translation
      this.tableData.forEach((row) => {
        const key = this.generateValidKey(row.En)

        // Set the translation using the valid key
        if (lang.title === 'English') {
          // If the language is English, set the value to be equal to the key
          translationsObject[key] = { value: key.replace(/-/g, ' ') }
        } else {
          // Otherwise, set the value normally
          translationsObject[key] = { value: row[lang.title] }
        }
      })

      const uniquePath = `/projects/${this.project_id}/translations/languages/${lang.title}/texts`

      this.db.setDatabase(uniquePath, translationsObject).subscribe(
        () => {
          // No `response` expected
          console.log(`Translations for ${lang.title} saved successfully.`)
          this.productProgress = false
        },
        (error) => {
          this.productProgress = false
          console.error(`Failed to save translations for ${lang.title}:`, error)
        }
      )
    })
  }
  /*
   * generateValidKey: Generates a valid key from the input string by replacing
   * specific characters (such as #, $, /, [, ], and spaces) with spaces.
   * - Returns the sanitized string, which can be used as a valid key in translations.
   */
  generateValidKey(input: string): string {
    return input.replace(/[.#$\/\[\] ]/g, ' ')
  }

  /*
   * getCurrency: Fetches the currency settings for the application.
   * - Subscribes to the settingsService to retrieve currency data.
   * - On successful retrieval, assigns the currency data to the currency property
   *   and calls addCurrencyRow() to incorporate the currency into the table.
   * - Logs an error message if fetching the currency data fails.
   */

  getCurrency() {
    this.settingsService.getCurrencySettings().subscribe(
      (data) => {
        this.currency = data
        this.addCurrencyRow()
      },
      (error) => {
        console.error('Error fetching currency data:', error)
      }
    )
  }

  /*
   * addCurrencyRow: Adds a new row for the currency to the table data.
   * - Checks if currency data is available; if so, creates a new currencyRow
   *   object with the currency symbol.
   * - For each language, constructs a unique database path to fetch the currency
   *   translation.
   * - Subscribes to the database to retrieve the currency translation for each language,
   *   adding it to the currencyRow.
   * - If the currencyRow does not already exist in the tableData, it adds it.
   * - Calls filterData() to apply any active search filters after updating the table.
   * - Logs an error if fetching the currency translation fails for any language.
   */
  addCurrencyRow() {
    if (this.currency) {
      const currencySymbol = this.currency
      const currencyRow: TableRow = { En: currencySymbol }

      // Add the currency translations for each language
      this.languages.forEach((lang) => {
        const uniquePath = `/projects/${this.project_id}/translations/languages/${lang.title}/texts/${currencySymbol}`
        this.db.getDatabase(uniquePath).subscribe(
          (currencyTranslation: any) => {
            if (currencyTranslation) {
              currencyRow[lang.title] = currencyTranslation.value
              if (!this.tableData.some((row) => row.En === currencyRow.En)) {
                this.tableData.push(currencyRow)
              }
              this.filterData()
            } else {
              console.error(`Currency translation for ${lang.title} is not in the expected format:`, currencyTranslation)
            }
          },
          (error: any) => {
            console.error(`Failed to fetch currency translation for ${lang.title}:`, error)
          }
        )
      })
    }
  }

  // Helper function to sanitize text so it can be used as a Firebase key
  private sanitizeKey(key: string): string {
    // Replace invalid Firebase path characters with underscores (or any valid character)
    return key.replace(/[.#$[\]]/g, '')
  }

  /*
   * addTextRows: Adds new rows to the table data for text blocks retrieved from the database.
   *
   * 1. Iterate over the keys in `textBlocks`, and for each key, retrieve the `allBlocks` array.
   * 2. Within `allBlocks`, look for blocks of type 'text':
   *    - Extract the text content (e.g., from HTML) and skip if empty.
   *    - Sanitize the text to create a valid key path for database queries.
   *    - Create a new table row object (`textRow`) with this text under `En`.
   * 3. For each configured language:
   *    - Build the path to fetch an existing translation from the database.
   *    - If a translation is found, use it; otherwise, default to "Not Translated."
   *    - Check if this exact `textRow` (by `row.En`) is already in `tableData`.
   *      If not, push it.
   * 4. After updating the row with translations, call `filterData()` to re-apply any active filters.
   */

  addTextRows(textBlocks: any) {
    if (!textBlocks) return

    Object.keys(textBlocks).forEach((key) => {
      const allBlocks = textBlocks[key].allBlocks
      if (!Array.isArray(allBlocks)) return

      allBlocks.forEach((block: any) => {
        if (block.type === 'text') {
          // Extract and trim text
          const textValue = this.extractTextFromHTML(block.data.value)

          // Skip if empty
          if (!textValue) {
            return
          }

          // Sanitize the extracted text to avoid invalid Firebase path characters
          const sanitizedTextValue = this.sanitizeKey(textValue)

          // Prepare a row with the English text
          const textRow: TableRow = { En: sanitizedTextValue }

          // Loop through languages and fetch existing translations
          this.languages.forEach((lang) => {
            const uniquePath = `/projects/${this.project_id}/translations/languages/${lang.title}/texts/${sanitizedTextValue}`

            // Get translation from Firebase (or any other DB you’re using)
            this.db.getDatabase(uniquePath).subscribe((translation: any) => {
              // If translation doesn't exist, use a fallback
              textRow[lang.title] = translation ? translation.value : 'Not Translated'

              // Only add the new row if it doesn’t already exist
              if (!this.tableData.some((row) => row.En === textRow.En)) {
                this.tableData.push(textRow)
              }

              this.filterData()
            })
          })
        }
      })
    })
  }

  /*
   * extractTextFromHTML: Helper function to extract plain text from HTML strings.
   * - Uses DOMParser to parse the HTML content and extract the text content.
   */
  extractTextFromHTML(html: string): string {
    const parser = new DOMParser()
    const doc = parser.parseFromString(html, 'text/html')

    // Select all <p> elements
    const pElements = doc.querySelectorAll('p')

    // Loop through and remove <p> that contain only <br> or are empty
    pElements.forEach((p) => {
      if (p.innerHTML.trim() === '<br>' || p.innerHTML.trim() === '') {
        p.remove()
      }
    })

    // Extract text content of remaining <p> tags, filtering out empty strings
    const paragraphs = Array.from(doc.querySelectorAll('p'))
      .map((p) => p.textContent?.trim() || '')
      .filter((text) => text !== '') // Filter out empty strings

    return paragraphs.join(' ').trim()
  }

  /*
   * fetchAndAddTextRows: Fetches text blocks from the database and processes them.
   * - Constructs the database path based on the selected project.
   * - Subscribes to the database to retrieve text blocks.
   * - If text blocks are found, passes them to the `addTextRows` function for processing and translation.
   * - Logs a message if no text blocks are found.
   */

  fetchAndAddTextRows() {
    const uniquePath = `/projects/${this.project_id}/views`
    this.db.getDatabase(uniquePath).subscribe((textBlock: any) => {
      if (textBlock) {
        this.addTextRows(textBlock)
      } else {
        console.log('No text blocks found.')
      }
    })
  }
}

<form [formGroup]="androidBuildForm" id="androidBuildWrapper" class="containerWrapper buildWrapper" (ngSubmit)="onSubmit()">
    <div id="titleContainer">
      <h1>{{'Android Builder' | translate }}</h1>
      <div class="howItWorks noselect" (click)="howItWorksDialog()">
        <span class="material-icons-outlined">play_circle_filled</span>
        {{'How it works' | translate }}
      </div>
    </div>
    <div class="containerSecondLine" *ngIf="(!buildStart || value == 100 || error) && !checkingStatus">
      <div class="buildSettingcontainer">
        <!--android version -->
         <div>
           <h2>{{'Android Version' | translate }}</h2>
           <div class="androidVersionWrapper">
             <mat-form-field appearance="outline">
               <mat-label>{{'Android Version' | translate}}</mat-label>
               <input matInput placeholder="1.0" type="number" min="0.1" step="0.1" max="9999" formControlName="androidVersion">
             </mat-form-field>
           </div>
           <p>{{'version of your Application' | translate}}</p>

         </div>
  
        <!--android codeversion -->  
         <div>
           <h2>{{'Android Version Code' | translate }}</h2>
           <div class="androidVersionWrapper">
             <mat-form-field appearance="outline">
               <mat-label>{{'Android Version Code' | translate}}</mat-label>
               <input matInput placeholder="1" type="number" min="1" step="1" max="9999" formControlName="androidVersionCode" (input)="onInput($event)">
             </mat-form-field>
             <button class="stacksBtn lightBtn red" (click)="onSubmit()" [disabled]="androidBuildForm.invalid" >{{"Generate App" | translate}}</button>
           </div>
           <p>{{'version of your Application Code' | translate}}</p>

         </div>
         </div>
      <div *ngIf="warningMessage">
        <p class="warning">{{ warningMessage }}</p>
      </div>
    </div>
    <div *ngIf="buildStart && !checkingStatus && !error" class="buildStart">
      <h1 *ngIf="value < 100">{{'Generating Android' | translate}}</h1>
      <h1 *ngIf="value == 100">{{'Android Application Generated' | translate}}</h1>
      {{value}}%
      <mat-progress-bar mode="determinate" class="buildingBar" [value]="value"></mat-progress-bar>
      <div *ngIf="value == 100 && ((!this.builderService.standalone && this.builderService.selectedProjectDomain) || this.builderService.standalone)" id="qrWrapper">
        <h2>{{'Open your Mobile camera' | translate }}</h2>
        <img src="{{qrLink}}" alt="" class="qrCode" />
        <div class="actionBtns">
          <a *ngIf="playType == 'apk'" href="{{downloadBtn}}" class="downloadBtn">{{'Download Android' | translate }}</a>
          <a *ngIf="playType == 'apk'" class="rebuildApp" [routerLink]='["/app-preview"]'>{{'Preview App' | translate }}</a>
        </div>
      </div>
    </div>
    <div *ngIf="error" class="buildError">
      <h2 *ngIf="buildErrorMsg && buildErrorMsg !== ''">{{buildErrorMsg}}</h2>
      <h2 *ngIf="!buildErrorMsg && buildErrorMsg !== ''">{{'Error occured while building' | translate}}<br />
      {{'please try again' | translate}} <br />{{'If the error' | translate }}</h2>
      <div class="actionBtns">
        <a class="rebuildApp" (click)="rebuildApp()">{{'Rebuild App' | translate }}</a>
      </div>
    </div>
    <div *ngIf="checkingStatus && !error" class="checkingStatus">
      <h1>{{'Checking Build Status' | translate }}</h1>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </form>   
<div id="signupLogo">
    <img src="assets/images/stacks.png" />
</div>
<div id="middleContainerWrapper">
    <div class="header">
        <button class="langSwitcher" mat-button [matMenuTriggerFor]="menu">
          <!-- Display flag or language code based on selected language -->
          <span *ngIf="languageService.getLanguage() === 'en'">🇺🇸 English</span>
          <span class="arabicText" *ngIf="languageService.getLanguage() === 'ar'">العربية 🇸🇦</span>
        </button>
        <mat-menu #menu="matMenu">
          <!-- Language options -->
          <button mat-menu-item *ngIf="languageService.selectedLanguage === 'ar'" (click)="changeLang('en')">🇺🇸 English</button>
          <button class="arabicText" mat-menu-item *ngIf="languageService.selectedLanguage === 'en'" (click)="changeLang('ar')">🇸🇦 العربية</button>
        </mat-menu>
    </div>
    <mat-progress-bar mode="indeterminate" class="topLoader" *ngIf="signupProgress"></mat-progress-bar>
    <!-- <button id="g_id_onload"
        data-client_id="339787967114-ikkals4eq2r58s7i70up9f406q01vbd6.apps.googleusercontent.com"
        data-context="signin"
        data-ux_mode="popup"
        data-login_uri="http://localhost:4200/signup"
        data-auto_prompt="false">
        google signup
    </button>

    <div class="g_id_signin"
        data-type="standard"
        data-shape="rectangular"
        data-theme="outline"
        data-text="signin_with"
        data-size="large"
        data-logo_alignment="left">
    </div> -->
    <div class="signupContainer">
    <form id="signupContainer" [formGroup]="signupForm" (ngSubmit)="onSubmit()">
        <h1 [ngClass]="{'arabicTextBold arabicText' : languageService.selectedLanguage === 'ar'}">{{'Create an account' | translate }}</h1>
        <h2 *ngIf="paymentIncluded">{{'to claim the offer' | translate }}</h2>
        <h3 [ngClass]="{'arabicText' : languageService.selectedLanguage === 'ar'}">{{'You are one step away from elevating your business' | translate }}</h3>
        <p *ngIf="type == 'self_ltd'">{{'Signup to create a project and claim the Offer' | translate }}</p>
        <!-- <div class="socialsignup" (click)="signInWithGoogle()">
            <img src="assets/images/google_login.png" />
            <p>Signup with google</p>
        </div> -->
        <ul>
            <li>
              <mat-error *ngIf="signupError">
                {{signupErrorMsg}}
              </mat-error>
            </li>
            <li>
                <mat-form-field appearance="outline">
                    <mat-label [ngClass]="{'arabicText' : languageService.selectedLanguage === 'ar'}">{{'First Name' | translate }}*</mat-label>
                    <input matInput placeholder="" formControlName="firstname" type="text">
                </mat-form-field>
                <div *ngIf="signupForm.controls.firstname.hasError('required') && ( signupForm.controls.firstname.touched )"
                  class="box-error box-error--style1-icon" class="formError" >{{'First name is required' | translate }}</div>
            </li>
            <li>
                <mat-form-field appearance="outline">
                    <mat-label [ngClass]="{'arabicText' : languageService.selectedLanguage === 'ar'}">{{'Last Name' | translate }}*</mat-label>
                    <input matInput placeholder="" formControlName="lastname" type="text">
                </mat-form-field>
                <div *ngIf="signupForm.controls.lastname.hasError('required') && ( signupForm.controls.lastname.touched )"
                  class="box-error box-error--style1-icon" class="formError" >{{'Last name is required' | translate }}</div>
            </li>
            <li>
                <mat-form-field appearance="outline" id="countryCode">
                    <mat-label [ngClass]="{'arabicText' : languageService.selectedLanguage === 'ar'}">{{ 'Country Code' | translate  }}</mat-label>
                    <mat-select formControlName="country_code">
                      <mat-option value="{{code.dial_code}}" *ngFor="let code of countries.countryDialCodes">{{code.emoji}} ({{code.code}}) {{code.dial_code}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                <mat-form-field appearance="outline" id="phoneNumber">
                    <mat-label [ngClass]="{'arabicText' : languageService.selectedLanguage === 'ar'}">{{'Phone Number' | translate }}</mat-label>
                    <input matInput placeholder="" formControlName="phone_number" type="number">
                </mat-form-field>
            </li>
            <li>
                <mat-form-field appearance="outline">
                    <mat-label [ngClass]="{'arabicText' : languageService.selectedLanguage === 'ar'}">{{'Email' | translate }}*</mat-label>
                    <input matInput placeholder="" formControlName="email" type="email" email="true">
                </mat-form-field>
                <div *ngIf="signupForm.controls.email.hasError('required') && ( signupForm.controls.email.touched )"
                  class="box-error box-error--style1-icon" class="formError">{{'Email is required' | translate }}</div>
                    <div *ngIf="signupForm.controls.email.hasError('email') && ( signupForm.controls.email.touched)"
                  class="box-error box-error--style1-icon" class="formError">{{'Please provide a valid email' | translate }}</div>
            </li>
            <li>
                <mat-form-field appearance="outline">
                    <mat-label [ngClass]="{'arabicText' : languageService.selectedLanguage === 'ar'}">{{'Password' | translate }}*</mat-label>
                    <input matInput type="password" formControlName="password">
                </mat-form-field>
                <div *ngIf="signupForm.controls.password.hasError('required') && ( signupForm.controls.password.touched )"
                  class="box-error box-error--style1-icon" class="formError" >{{'Password Is Required' | translate }}</div>
                  <div *ngIf="signupForm.controls.password.hasError('minlength') && ( signupForm.controls.password.touched )"
                      class="box-error box-error--style1-icon" class="formError" >{{'Password length cannot be less than 6' | translate}}</div>
            </li>
            <li style="display:none;">
                <mat-form-field appearance="outline">
                    <mat-label [ngClass]="{'arabicText' : languageService.selectedLanguage === 'ar'}">{{'Email2' | translate }}*</mat-label>
                    <input matInput type="text" formControlName="email2">
                </mat-form-field>
            </li>
        </ul>
        <button class="submitsignup" type="submit" [class]="signupProgress ? 'disabledSignup' : ''">
          <span [ngClass]="{'arabicText' : languageService.selectedLanguage === 'ar'}" *ngIf="!signupProgress">{{'Signup' | translate }}</span>
          <span [ngClass]="{'arabicText' : languageService.selectedLanguage === 'ar'}" *ngIf="signupProgress">{{'Signing Up' | translate }}</span>
        </button>
        <asl-google-signin-button text="signup_with" shape="rectangular" size="large" type="standard" style="margin-top: 17px; align-self: center;"></asl-google-signin-button>

        <a class="altAccess" [routerLink]="['/login']" *ngIf="preservedReturnURL" [queryParams]="{ returnUrl: preservedReturnURL }">{{'Already have an account?' | translate }} <span>{{'Login' | translate }} </span></a>
        <a class="altAccess" [routerLink]="['/login']" *ngIf="!preservedReturnURL">{{'Already have an account?' | translate }} <span>{{'Login' | translate }} </span></a>
    </form>
  </div>
    <mat-progress-bar mode="indeterminate" class="bottomLoader" *ngIf="signupProgress"></mat-progress-bar>
</div>

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BuilderService } from '../builder-services/builder.service';
@Injectable({
  providedIn: 'root'
})
export class BlockDataService {

  private blockComponentSubject = new Subject<any>();
  private blockDataSubject      = new Subject<any>();
  private blockRenderSubject    = new Subject<any>();
  private tokenSubject          = new Subject<any>();
  private projectIdSubject      = new Subject<any>();
  private projectCachedSubject  = new Subject<any>();
  public productsCached  = false;
  private postsCachedSubject  = new Subject<any>();
  private productsCachedSubject  = new Subject<any>();
  private categoriesCachedSubject  = new Subject<any>();
  private viewDataSubject      = new Subject<any>();
  private globalHeaderSubject      = new Subject<any>();
  private globalMenueSubject = new Subject<any>();
  private globalFooterSubject      = new Subject<any>();
  private stripeSubscriptionSubject      = new Subject<any>();
  public blocksData : any;
  public mappedBlocksData : any;
  builderBlocksIndex = 0; // Hack to solve the issue of "adding blocks inside the section increments the Index by one"
  currentBlockData : any;
  gAdMobActive = false;
  gAdMobActiveFooter = false;
  woocommerceActive = false;
  blockId : any;

  // List of all columns ids which is available in the view to be linked with the droppable connectors
  columnsIds : any;
  selectedView :any;
  tempHeader = {
    "data": {
      "bgColor": "#002aff",
      "cartIcon": true,
      "disable_header": false,
      "global_header": true,
      "menuIcon": true,
      "searchIcon": true,
      "sidemenuIcon": true,
      "src": "",
      "type": "color"
    },
    "id": "7285-527b",
    "style": {
      "color": "#ffffff"
    },
    "type": "header"
  };

  tempFooter = {
    "id": "168c-ae5d",
    "type": "footer",
    "data": {
      "src": "",
      "type": "color",
      "bgColor": "#000000",
      "footerMenu": "active",
      "menuItems": [
        {
          "index": 0,
          "icon": "home_work",
          "color": "#ffffff",
          "link": "home",
          "menuText": "Home",
          "product": 269
        }
      ],
      "externalURL": "https://stacksmarket.co",
      "global_footer": false
    },
    "style": {}
  };

  tempBlocksTxt = [{
    "id": 0,
    "type": "text",
    "data": {
      "value": "bassam"
    },
    "style": {}
  },
  {
    "id": 1,
    "type": "text",
    "data": {
      "value": "Tarek"
    },
    "style": {}
  },
  {
    "id": 2,
    "type": "text",
    "data": {
      "value": "Gamal"
    },
    "style": {}
  }];

  tempBlocksImg = [{
        "id": 0,
        "type": "image",
        "data": {
          "src": "https://via.placeholder.com/800"
        },
        "style": {}
      },
      {
        "id": 1,
        "type": "image",
        "data": {
          "src": "https://via.placeholder.com/700"
        },
        "style": {}
      },
      {
        "id": 2,
        "type": "image",
        "data": {
          "src": "https://via.placeholder.com/600"
        },
        "style": {}
      }];
    productsDataClone = [];
    defaultView: any = [
      {
        "id": "7285-527b",
        "type": "header",
        "data": {
          "src": "https://alphabuilder.stacksclients.com/wp-content/uploads/stacks-uploads/builder/toy header-02.png",
          "type": "color",
          "bgColor": "#ffffff",
          "menuIcon": true,
          "searchIcon": true,
          "cartIcon": true,
          "sidemenuIcon": true,
          "global_header": false
        },
        "style": {
          "color": "#000000"
        },
      },
      {
        "id": "e881-f630",
        "type": "footer",
        "data": {
          "src": "",
          "type": "color",
          "bgColor": "#ffffff",
          "footerMenu": "active",
          "readGlobalFooter": true,
          "menuItems": [
            {
              "index": 0,
              "icon": "home",
              "color": "#000000",
              "link": "home",
              "menuText": "Home"
            }
          ],
          "gFooterBanners": [
            {
              "index": 0,
              "status": "inactive",
              "bannerAdId": "",
              "gFooterBannerPage": "home"
            }
          ],
          "global_footer": true
        },
        "style": {}
      }
    ];
  categoryView: any = [
    {
      "id": "7285-527b",
      "type": "header",
      "data": {
        "src": "https://alphabuilder.stacksclients.com/wp-content/uploads/stacks-uploads/builder/toy header-02.png",
        "type": "color",
        "bgColor": "#000000",
        "menuIcon": true,
        "searchIcon": true,
        "cartIcon": true,
        "sidemenuIcon": true
      },
      "style": {
        "color": "#ffffff"
      }
    },
    {
      "id": "6dd4-89a7",
      "type": "static",
      "data": {
        "view": "categories",
        "block": "shop_filters"
      },
      "style": []
    },
    {
      "id": "6dd4-89a9",
      "type": "static",
      "data": {
        "view": "categories",
        "block": "shop_products",
        "variations": {
          "oneColumn": "shop_products_1col",
          "TwoColumns": "shop_products_2col",
          "List": "shop_products_list1",
          "List2": "shop_products_list2",
        }
        
      },
      "style": {
        "columns_number": "TwoColumns"
      }
    },
  ];

  singleCategoryView: any = [
    {
      "id": "7285-527b",
      "type": "header",
      "data": {
        "src": "https://alphabuilder.stacksclients.com/wp-content/uploads/stacks-uploads/builder/toy header-02.png",
        "type": "color",
        "bgColor": "#000000",
        "menuIcon": true,
        "searchIcon": true,
        "cartIcon": true,
        "sidemenuIcon": true
      },
      "style": {
        "color": "#ffffff"
      }
    },
    {
      "id": "6dd4-89a9",
      "type": "static",
      "data": {
        "view": "singleCategory",
        "block": "single_category",
        "cart_visibility": "show_cart",
        "variation_1": {
          'gridStyle':"grid",
          'listStyle':"list",
        },
        "variation_2": {
          'oneColumn':"oneColumn",
          'twoColumns':"twoColumns",
          'threeColumns':"threeColumns",
        },
        "variation_3": {
          'simpleStyle':"simpleStyle",
          'roundedStyle':"roundedStyle",
          'sharpStyle':"sharpStyle",
          'glassStyle':"glassStyle",
        }
      },
      "style": {
        "grid_list": "gridStyle",
        "columns_number": "twoColumns",
        "products_style": "simpleStyle",
        "cart_visibility": "show_cart"
      }
    }, 
  ];
  singleView: any = [
    {
      "id": "7285-527b",
      "type": "header",
      "data": {
        "src": "https://alphabuilder.stacksclients.com/wp-content/uploads/stacks-uploads/builder/toy header-02.png",
        "type": "color",
        "bgColor": "#000000",
        "menuIcon": true,
        "searchIcon": true,
        "cartIcon": true,
        "sidemenuIcon": true
      },
      "style": {
        "color": "#ffffff"
      }
    },
    {
      "id": "6dd4-89b0",
      "type": "static",
      "data": {
        "view": "single",
        "block": "single_image"
      },
      "style": []
    },
    {
      "id": "6dd4-89b1",
      "type": "static",
      "data": {
        "view": "single",
        "block": "single_title"
      },
      "style": []
    },
    {
      "id": "6dd4-89b8",
      "type": "static",
      "data": {
        "view": "single",
        "block": "single_sku"
      },
      "style": []
    },
    {
      "id": "6dd4-89b2",
      "type": "static",
      "data": {
        "view": "single",
        "block": "single_price"
      },
      "style": []
    },
    {
      "id": "6dd4-89b3",
      "type": "static",
      "data": {
        "view": "single",
        "block": "single_description"
      },
      "style": []
    },
    {
      "id": "6dd4-89b4",
      "type": "static",
      "data": {
        "view": "single",
        "block": "single_reviews"
      },
      "style": []
    },
    {
      "id": "6dd4-89b6",
      "type": "static",
      "data": {
        "deletable": false,
        "view": "single",
        "block": "single_variable_size"
      },
      "style": []
    },
    {
      "id": "6dd4-89b7",
      "type": "static",
      "data": {
        "deletable": false,
        "view": "single",
        "block": "single_cart"
      },
      "style": []
    },
  ];
  loginView: any = [
    {
      "id": "7285-527b",
      "type": "header",
      "data": {
        "src": "https://alphabuilder.stacksclients.com/wp-content/uploads/stacks-uploads/builder/toy header-02.png",
        "type": "color",
        "bgColor": "#000000",
        "menuIcon": true,
        "searchIcon": true,
        "cartIcon": true,
        "sidemenuIcon": true
      },
      "style": {
        "color": "#ffffff"
      }
    },
    {
      "id": "6dd4-89b0",
      "type": "static",
      "data": {
        "deletable": false,
        "view": "login",
        "block": "login"
      },
      "style": []
    },
  ];
  signupView: any = [
    {
      "id": "7285-527b",
      "type": "header",
      "data": {
        "src": "https://alphabuilder.stacksclients.com/wp-content/uploads/stacks-uploads/builder/toy header-02.png",
        "type": "color",
        "bgColor": "#000000",
        "menuIcon": true,
        "searchIcon": true,
        "cartIcon": true,
        "sidemenuIcon": true
      },
      "style": {
        "color": "#ffffff"
      }
    },
    {
      "id": "6dd4-89b0",
      "type": "static",
      "data": {
        "deletable": false,
        "view": "signup",
        "block": "signup"
      },
      "style": []
    },
  ];
  forgotView: any = [
    {
      "id": "7285-527b",
      "type": "header",
      "data": {
        "src": "https://alphabuilder.stacksclients.com/wp-content/uploads/stacks-uploads/builder/toy header-02.png",
        "type": "color",
        "bgColor": "#000000",
        "menuIcon": true,
        "searchIcon": true,
        "cartIcon": true,
        "sidemenuIcon": true
      },
      "style": {
        "color": "#ffffff"
      }
    },
    {
      "id": "bf7a-82a7",
      "type": "text",
      "data": {
        "value": "<p>Enter Your Email And New Password Then You Will Receive An Email With A Link To Activate This Password</p>"
      },
      "style": {}
    },
    {
      "id": "6dd4-89b0",
      "type": "static",
      "data": {
        "deletable": false,
        "view": "forgot",
        "block": "forgot"
      },
      "style": []
    },
  ];
  myAccountView: any = [
    {
      "id": "7285-527b",
      "type": "header",
      "data": {
        "src": "https://alphabuilder.stacksclients.com/wp-content/uploads/stacks-uploads/builder/toy header-02.png",
        "type": "color",
        "bgColor": "#000000",
        "menuIcon": true,
        "searchIcon": true,
        "cartIcon": true,
        "sidemenuIcon": true
      },
      "style": {
        "color": "#ffffff"
      }
    },
    {
      "id": "6dd4-89b0",
      "type": "static",
      "data": {
        "deletable": false,
        "view": "myaccount",
        "block": "myaccount"
      },
      "style": []
    },
  ];
  singleBlogPostView: any = [
    {
      "id": "7285-527b",
      "type": "header",
      "data": {
        "src": "https://alphabuilder.stacksclients.com/wp-content/uploads/stacks-uploads/builder/toy header-02.png",
        "type": "color",
        "bgColor": "#000000",
        "menuIcon": true,
        "searchIcon": true,
        "cartIcon": true,
        "sidemenuIcon": true
      },
      "style": {
        "color": "#ffffff"
      }
    },
    {
      "id": "6dd4-89b0",
      "type": "static",
      "data": {
        "deletable": false,
        "view": "singleblogpost",
        "block": "singleblogpost",
        "webview_or_native": "native"
      },
      "style": []
    },
  ];
  cartView: any = [
    {
      "id": "7285-527b",
      "type": "header",
      "data": {
        "src": "https://alphabuilder.stacksclients.com/wp-content/uploads/stacks-uploads/builder/toy header-02.png",
        "type": "color",
        "bgColor": "#000000",
        "menuIcon": true,
        "searchIcon": true,
        "cartIcon": true,
        "sidemenuIcon": true
      },
      "style": {
        "color": "#ffffff"
      }
    },
    {
      "id": "6dd4-89b0",
      "type": "static",
      "data": {
        "deletable": false,
        "view": "cart",
        "block": "cart"
      },
      "style": []
    },
  ];
  checkoutView: any = [
    {
      "id": "7285-527b",
      "type": "header",
      "data": {
        "src": "https://alphabuilder.stacksclients.com/wp-content/uploads/stacks-uploads/builder/toy header-02.png",
        "type": "color",
        "bgColor": "#000000",
        "menuIcon": true,
        "searchIcon": true,
        "cartIcon": true,
        "sidemenuIcon": true
      },
      "style": {
        "color": "#ffffff"
      }
    },
    {
      "id": "6dd4-89b0",
      "type": "static",
      "data": {
        "deletable": false,
        "view": "checkout",
        "block": "checkout2",
        "lastname": "on",
        "company": "on",
        "email": "on",
        "phone": "on",
        "postal": "on",
        "additional": "on",
        "differentShippment": "on"
      },
      "style": []
    },
  ];
  aboutView: any = [
    {
      "data": {
        "bgColor": "#000000",
        "cartIcon": true,
        "global_header": true,
        "menuIcon": true,
        "searchIcon": true,
        "sidemenuIcon": true,
        "src": "https://alphabuilder.stacksclients.com/wp-content/uploads/stacks-uploads/builder/toy header-02.png",
        "type": "color"
      },
      "id": "7285-527b",
      "style": {
        "color": "#ffffff"
      },
      "type": "header"
    },
    {
      "data": {
        "src": "https://stacksmarket.co/assets/images/Logo.png"
      },
      "id": "f656-7f8e",
      "type": "image"
    },
    {
      "data": {
        "value": "<p><strong>Welcome</strong><br></p><p><strong><br data-mce-bogus=\"1\"></strong></p><p><span style=\"font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Open Sans&quot;, &quot;Helvetica Neue&quot;, sans-serif;\">We are your trusted partners </span></p><p><span style=\"font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Open Sans&quot;, &quot;Helvetica Neue&quot;, sans-serif;\"><br data-mce-bogus=\"1\"></span></p><p><span style=\"font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Open Sans&quot;, &quot;Helvetica Neue&quot;, sans-serif;\">We believe in empowering you in today’s digital-first world. Our revolutionary products make it simple for anyone to design, customize, and launch mobile applications without any coding experience.</span></p><p><span style=\"font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Open Sans&quot;, &quot;Helvetica Neue&quot;, sans-serif;\"><br data-mce-bogus=\"1\"></span></p><p><span style=\"font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Open Sans&quot;, &quot;Helvetica Neue&quot;, sans-serif;\"><br data-mce-bogus=\"1\"></span></p><p><strong>Together, let's build a better future</strong></p>"
      },
      "id": "f358-24bb",
      "type": "text"
    },
    {
      "data": {
        "bgColor": "#ffffff",
        "footerMenu": "active",
        "gFooterBanners": [
          {
            "bannerAdId": "",
            "gFooterBannerPage": "home",
            "index": 0,
            "status": "inactive"
          }
        ],
        "global_footer": true,
        "menuItems": [
          {
            "color": "#000000",
            "icon": "home",
            "index": 0,
            "link": "home",
            "menuText": "Home"
          }
        ],
        "readGlobalFooter": true,
        "src": "",
        "type": "color"
      },
      "id": "d91b-4258",
      "type": "footer"
    }
  ];
  contactView: any = [
    {
      "id": "7285-527b",
      "type": "header",
      "data": {
        "src": "https://alphabuilder.stacksclients.com/wp-content/uploads/stacks-uploads/builder/toy header-02.png",
        "type": "color",
        "bgColor": "#000000",
        "menuIcon": true,
        "searchIcon": true,
        "cartIcon": true,
        "sidemenuIcon": true
      },
      "style": {
        "color": "#ffffff"
      }
    },
    {
      "id": "6dd4-89b0",
      "type": "static",
      "data": {
        "deletable": false,
        "view": "contact",
        "block": "contact"
      },
      "style": []
    },
  ];
  termsView: any = [
    {
      "id": "7285-527b",
      "type": "header",
      "data": {
        "src": "https://alphabuilder.stacksclients.com/wp-content/uploads/stacks-uploads/builder/toy header-02.png",
        "type": "color",
        "bgColor": "#000000",
        "menuIcon": true,
        "searchIcon": true,
        "cartIcon": true,
        "sidemenuIcon": true
      },
      "style": {
        "color": "#ffffff"
      }
    },
    {
      "id": "6dd4-89b0",
      "type": "static",
      "data": {
        "view": "terms",
        "block": "terms"
      },
      "style": []
    },
  ];

  blankView: any = [
    {
      "data": {
        "global_header": false,
        "search_opened": "no",
        "src": "https://sites.stacksmarket.co/wp-content/uploads/stacks-uploads/builder/12.png",
        "sticky_header": "yes"
      },
      "id": "43f1-e172",
      "style": {
        "cart_icon": "",
        "color": "#486b1a",
        "search_icon": "search"
      },
      "type": "header"
    },
    {
      "data": {
        "bgColor": "#ffffff",
        "footerMenu": "active",
        "footerStyle": "classic",
        "global_footer": true,
        "menuItems": [],
        "src": "",
        "type": "color"
      },
      "id": "280f-ca1a",
      "type": "footer"
    }
  ];
  constructor() {
    this.columnsIds = [];
    this.blocksData = [];
    this.mappedBlocksData = [];
    this.currentBlockData = {};
  }
  /**
   * Sync Block Component on click to be propagated inside the mobile preview and Data Widget
   * @param BlockComponent 
   */
  sendBlockData(BlockComponent: any ) {
    this.blockComponentSubject.next(BlockComponent);
  }

  getBlockData():Observable<any> {
    return this.blockComponentSubject.asObservable();
  }

  /**
   * Sync Block Component on click to be render JSON Object
   * @param BlockComponent
   */
  getAllBlocksData = () => {
    return this.blocksData;
  }

  getSelectedBlockData = (id?: any) => {
    if( !id && id !== 0 ) {
      // Get the Latest
      id = this.blocksData.length - 1;
    }
    return this.checkBlockAvailability(id);
  }

  removeBlockData = (blockId: any) => {
    let blocksObject = this.blocksData;
    this.builderBlocksIndex++;
    for (const key in blocksObject) {
      if (Object.prototype.hasOwnProperty.call(blocksObject, key)) {
        const element = blocksObject[key];
        // It is a Section
        if (element.elements) {
          if (element.id == blockId) {
            this.blocksData.splice(key, 1);
            this.removeBlockSettings();
          }
          // Loop on the Section
          for (const keyCol in element.elements) {
            if (Object.prototype.hasOwnProperty.call(element.elements, keyCol)) {
              const elementCol = element.elements[keyCol].elements;
              // Loop on the Columns
              for (const keyColElements in elementCol) {
                if (Object.prototype.hasOwnProperty.call(elementCol, keyColElements)) {
                  const elementColElements = elementCol[keyColElements];
                  if (elementColElements.id == blockId) {
                    this.blocksData[key].elements[keyCol].elements.splice(keyColElements, 1);
                    this.removeBlockSettings();
                  }
                }
              }
            }
          }
        } else {
          if (element.id == blockId) {
            let element = document.getElementById("blockid" + blockId);
            element?.closest('.stacksBlockContainer')?.parentElement?.remove();
            this.blocksData.splice(key, 1);
            this.removeBlockSettings();
          }
        }
      }
    }
    return false;
  }

  removeBlockSettings = () => {
    let elementData = document.getElementById("blockDataSettings");
    while (elementData?.querySelector('.ng-star-inserted')?.firstChild) {
      elementData?.querySelector('.ng-star-inserted')?.firstChild?.remove()
    }

    let elementStyle = document.getElementById("blockStyleSettings");
    while (elementStyle?.querySelector('.ng-star-inserted')?.firstChild) {
      elementStyle?.querySelector('.ng-star-inserted')?.firstChild?.remove()
    }
  }

  /**
   * Check Block Availability in the large json object
   * @param blockId
   * @returns block or false
   */

  checkBlockAvailability = (blockId: any) => {
    let blocksObject = this.blocksData;
    for (const key in blocksObject) {
      if (Object.prototype.hasOwnProperty.call(blocksObject, key)) {
        const element = blocksObject[key];
        if (element.type == 'section') {
          /**
           * Loop on the items inside the section Colums
           */
          for (const columsKeys in element.elements) {
              const columns = element.elements[columsKeys];
            if (this.checkBlockColAvailability(blockId, columns.elements)) {
              return this.checkBlockColAvailability(blockId, columns.elements);
              }
          }
        }
        if (element.id == blockId) {
          return element;
        }
      }
    }
    return false;
  }

  /**
   * Check Blocks Availability inside the columns of the sections
   * @returns block or false
   */
  checkBlockColAvailability = (blockId: any, blocksObject: any) => {
    for (const key in blocksObject) {
      if (Object.prototype.hasOwnProperty.call(blocksObject, key)) {
        const element = blocksObject[key];
        if (element.id == blockId) {
          return element;
        }
      }
    }
    return false;
  }
  /**
   * Returns the latest block in the blocks object
   */
  getLatestBlockId = () => {
    if (this.checkBlockAvailability(this.blocksData.length - 1) )
      return this.blocksData[this.blocksData.length - 1 ].id
  }

  getAllBlocksIds = () => {
    let allblocksdata = this.blocksData;
    let allblocksIds = 0;
    for (const key in allblocksdata) {
      if (Object.prototype.hasOwnProperty.call(allblocksdata, key)) {
        const element = allblocksdata[key];
        if( element.type == 'section' ) {
          allblocksIds++; // increment for the section
          for (const columnskeys in element.elements) {
              const columns = element.elements[columnskeys];
              allblocksIds += columns.elements.length; // increment for the columns inside the section
          }
          
        }
        else {
          allblocksIds++;
        }
      }
    }
    return allblocksIds;
  }
  /**
   * append the block and increment the block id
   * @param data 
   * @returns block id
   */
  appendBlocksData = ( data: any ) => {
    this.blocksData.push( data );
    this.blockId = this.getUniqueId(2);
    this.blocksData[this.blocksData.length - 1].id = this.blockId;
    return this.blockId;
  }

  /**
   * sync current block on click
   */
  sendCurrentBlock = ( data: object ) => {
    this.blockDataSubject.next( data );
  }

  
  getCurrentBlock = () => {
    return this.blockDataSubject.asObservable();
  }

  /**
   * Fire initial Blocks Drawer
   */

  sendRenderAllBlocks = () => {
    this.blockRenderSubject.next();
  }

  getRenderAllBlocks = () => {
    return this.blockRenderSubject.asObservable();
  }

  /**
   * Removes the blocks from the mobile preview
   */
   resetPreview = () => {
    document.getElementById("mainMobileAppPreview");
  };

  /**
   * Generate a list of all the available columnsIds
   * @returns array of columnsIds
   */
  getColumnIds = () => {
    let allColumns = document.querySelectorAll('.columnBlockContainer');
    let columnsList = [];
    for (let i = 0; i < allColumns.length; i++) {
      columnsList.push(allColumns[i].id);
      this.columnsIds = [...new Set(columnsList)];
    }
    return this.columnsIds;
  }
  /**
   * Map all the blocks to be with the old Elementor Style
   */
  mapBlocksData = () => {
    const mappedBlocksData = JSON.parse(JSON.stringify(this.blocksData));
    for (const index in mappedBlocksData) {
      let element = mappedBlocksData[index];
      if (element.type == 'products') {
        delete mappedBlocksData[index].productsData;
        delete mappedBlocksData[index].categories;
      } else if (element.type == 'categories') {
        delete mappedBlocksData[index].categoriesData;
      }
    }
    return mappedBlocksData;
  }

  changeObjectKey = (object: any, oldKey: string, newKey: string) => {
    delete Object.assign(object, { [newKey]: object[oldKey] })[oldKey];
  }

  /**
  * generate groups of 4 random characters
  * @example getUniqueId(1) : 607f
  * @example getUniqueId(2) : 95ca-361a-f8a1-1e73
  */
  getUniqueId = (parts: number): string  => {
    const stringArr = [];
    for (let i = 0; i < parts; i++) {
      // tslint:disable-next-line:no-bitwise
      const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      stringArr.push(S4);
    }
    return stringArr.join('-');
  }


  /**
   * Sync Token to initialize the whole project
   * @param token 
   */
  sendTokenData(token: any ) {
    this.tokenSubject.next(token);
  }

  getTokenData():Observable<any> {
    return this.tokenSubject.asObservable();
  }
  
  /**
   * Sync projectId to initialize the whole project
   * @param projectId 
   */
  sendProjectData(projectId: any ) {
    this.projectIdSubject.next(projectId);
  }

  getProjectData():Observable<any> {
    return this.projectIdSubject.asObservable();
  }

  sendProjectCached(projectReponse: any ) {
    this.projectCachedSubject.next(projectReponse);
  }

  getProjectCached():Observable<any> {
    return this.projectCachedSubject.asObservable();
  }
  
  sendPostsCached(postsResponse: any ) {
    this.postsCachedSubject.next(postsResponse);
  }

  getPostsCached():Observable<any> {
    return this.postsCachedSubject.asObservable();
  }

  sendProductsCached(productsResponse: any ) {
    this.productsCachedSubject.next(productsResponse);
  }

  getProductsCached():Observable<any> {
    return this.productsCachedSubject.asObservable();
  }

  sendCategoriesCached(categoriesResponse: any ) {
    this.categoriesCachedSubject.next(categoriesResponse);
  }

  getCategoriesCached():Observable<any> {
    return this.categoriesCachedSubject.asObservable();
  }

  sendSelectedView(viewData: any ) {
    this.viewDataSubject.next(viewData);
    this.selectedView = viewData;
    localStorage.setItem('selectedView', JSON.stringify(viewData));
  }

  getSelectedView():Observable<any> {
    return this.viewDataSubject.asObservable();
  }

  sendGlobalHeader(header: any) {
    this.globalHeaderSubject.next(header);
  }

  getGlobalHeader():Observable<any> {
    return this.globalHeaderSubject.asObservable();
  }

  sendGlobalFooter(header: any) {
    this.globalFooterSubject.next(header);
  }

  getGlobalFooter():Observable<any> {
    return this.globalFooterSubject.asObservable();
  }

  sendStripeData(stripeSubID: any ) {
    this.stripeSubscriptionSubject.next(stripeSubID);
  }

  getStripeData():Observable<any> {
    return this.stripeSubscriptionSubject.asObservable();
  }
}

<form
  [formGroup]="firebaseSettingsForm"
  id="firebaseSettingsWrapper"
  class="settingsWrapper"
  (ngSubmit)="onSubmit()"
>
  <h1 *ngIf="!this.builderService.getTour()">
    {{ "Firebase Settings" | translate }}
  </h1>
  <button
    *ngIf="this.builderService.getTour()"
    class="tourNextActionUpper stacksBtn green"
    type="button"
    (click)="generalSettingsNext()"
  >
    {{ "Next" | translate }}
  </button>
  <div *ngIf="this.builderService.getTour()" class="generalSettingsTourHeader">
    <h1>{{ "Initial Setup" | translate }}</h1>
    <p>{{ "You can leave the below settings" | translate }}</p>
  </div>
  <ul>
    <!--this is the firebase Api key-->
    <li>
      <h2>{{ "Api Key" | translate }}</h2>
      <mat-form-field appearance="outline">
        <mat-label>{{ "Api Key" | translate }}</mat-label>
        <input
          matInput
          placeholder="{{
            'AIzWatzv_pNQq9YAHqjfJw8wVtmsdNfrsD8zc00' | translate
          }}"
          formControlName="stacks_user_apiKey"
          value="{{ this.firebaseSettingsData?.stacks_user_apiKey }}"
        />
      </mat-form-field>
    </li>
    <!--this is the firebase Auth Domain-->
    <li>
      <h2>{{ "Auth Domain" | translate }}</h2>
      <mat-form-field appearance="outline">
        <mat-label>{{ "Auth Domain" | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'stacks-887ad.firebaseapp.com' | translate }}"
          formControlName="stacks_user_authDomain"
          value="{{ this.firebaseSettingsData?.stacks_user_authDomain }}"
        />
      </mat-form-field>
    </li>

    <!--this is the firebase database URL-->
    <li>
      <h2>{{ "Database URL" | translate }}</h2>
      <mat-form-field appearance="outline">
        <mat-label>{{ "Database URL" | translate }}</mat-label>
        <input
          matInput
          placeholder="{{
            'https://stacks-d88fd-default-rsbc.firebaseio.com' | translate
          }}"
          formControlName="stacks_user_databaseURL"
          value="{{ this.firebaseSettingsData?.stacks_user_databaseURL }}"
        />
      </mat-form-field>
    </li>

    <!--this is the firebase project Id-->
    <li>
      <h2>{{ "Project Id" | translate }}</h2>
      <mat-form-field appearance="outline">
        <mat-label>{{ "Project Id" | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'stacks-d88fd' | translate }}"
          formControlName="stacks_user_projectId"
          value="{{ this.firebaseSettingsData?.stacks_user_projectId }}"
        />
      </mat-form-field>
    </li>

    <!--this is the firebase storage Bucket-->
    <li>
      <h2>{{ "Storage Bucket" | translate }}</h2>
      <mat-form-field appearance="outline">
        <mat-label>{{ "Storage Bucket" | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'stacks-d88fd.firebasestorage.app' | translate }}"
          formControlName="stacks_user_storageBucket"
          value="{{ this.firebaseSettingsData?.stacks_user_storageBucket }}"
        />
      </mat-form-field>
    </li>

    <!--this is the firebase messaging Sender Id-->
    <li>
      <h2>{{ "Messaging SenderId" | translate }}</h2>
      <mat-form-field appearance="outline">
        <mat-label>{{ "Messaging SenderId" | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'stacksSenderId' | translate }}"
          formControlName="stacks_user_messagingSenderId"
          value="{{ this.firebaseSettingsData?.stacks_user_messagingSenderId }}"
        />
      </mat-form-field>
    </li>

    <!--this is the firebase app Id-->
    <li>
      <h2>{{ "App Id" | translate }}</h2>
      <mat-form-field appearance="outline">
        <mat-label>{{ "App Id" | translate }}</mat-label>
        <input
          matInput
          placeholder="{{
            '3:329767666114:web:75fb111256742034141x43' | translate
          }}"
          formControlName="stacks_user_appId"
          value="{{ this.firebaseSettingsData?.stacks_user_appId }}"
        />
      </mat-form-field>
    </li>

    <!--this is the firebase measurement Id-->
    <li>
      <h2>{{ "Measurement Id" | translate }}</h2>
      <mat-form-field appearance="outline">
        <mat-label>{{ "Measurement Id" | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'Q-QM7GAFGNP5' | translate }}"
          formControlName="stacks_user_measurementId"
          value="{{ this.firebaseSettingsData?.stacks_user_measurementId }}"
        />
      </mat-form-field>
    </li>

    <!--this is the uploading google service file-->
    <li>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h2>{{ "Google service file" | translate }}</h2>
          </mat-panel-title>
          <mat-panel-description>
            {{ "Click to know more" | translate }}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p>{{ "This is the google service  file upload for android" | translate }}</p>
      </mat-expansion-panel>
      <label class="fileUpload" for="google_filepath"
        >{{ "Choose google file" | translate }}
        <input
          type="file"
          formControlName="google_filepath"
          id="google_filepath"
          (change)="processFile($event, 'application/octet-stream', 'google_filepath') "
          accept="application/octet-stream"
        />
      </label>
        <span *ngIf="googleServiceJsonFile">{{ googleServiceJsonFile }}</span>
        <button
          *ngIf="googleServiceJsonFile"
          mat-icon-button
          (click)="clearFileName('google_filepath')"
        >
          <mat-icon>close</mat-icon>
        </button>
        <mat-spinner
          *ngIf="isGoogleServiceJsonUploading"
          diameter="24"
        ></mat-spinner>
 
    </li>    

    <!--this is the uploading pilist file-->
    <li>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h2>{{ "Plist file" | translate }}</h2>
          </mat-panel-title>
          <mat-panel-description>
            {{ "Click to know more" | translate }}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p>{{ "This is the pilist file upload for ios" | translate }}</p>
      </mat-expansion-panel>
      <label class="fileUpload" for="plist_filepath"
        >{{ "Choose plist file" | translate }}
        <input
          type="file"
          formControlName="plist_filepath"
          id="plist_filepath"
          (change)="
            processFile($event, 'application/octet-stream', 'plist_filepath')
          "
          accept="application/octet-stream"
        />
      </label>
    <span *ngIf="plistFile">{{ plistFile }}</span>
    <button
      *ngIf="plistFile"
      mat-icon-button
      (click)="clearFileName('plist_filepath')"
    >
      <mat-icon>close</mat-icon>
    </button>
        <mat-spinner *ngIf="isPlistUploading" diameter="24"></mat-spinner>
 
    </li>


    </ul>
    <button
      *ngIf="this.builderService.getTour()"
      id="tourNextAction"
      type="button"
      (click)="generalSettingsNext()"
    >
      {{ "Next" | translate }}
    </button>
</form>

import { Injectable } from '@angular/core'
import { BuilderService } from '../builder-services/builder.service'
import { stripeService } from '../pages/payment/stripe.service'
import { BlockDataService } from '../block-services/block-data.service'
import { DatabaseService } from '../builder-services/database.service'
import { Observable } from 'rxjs'
import { HelpersService } from '../helpers.service'
import { LoginService } from '../login/login.service'
import { environment } from 'src/environments/environment'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { ToastService } from '../pages/toastr/toast.service'
import { Router } from '@angular/router'
import { WorksDialogComponent } from '../pages/works-dialog/works-dialog.component'
import { MatDialog } from '@angular/material/dialog'

@Injectable({
  providedIn: 'root'
})
export class SubscriptionFlowService {
  builderUrl!: string
  user_email!: string
  token: any
  today: Date = new Date()
  options = {
    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  }
  public stripe: any = {}
  constructor(
    private dialog: MatDialog,
    public builderService: BuilderService,
    public stripeService: stripeService,
    private blockDataService: BlockDataService,
    private db: DatabaseService,
    private helpers: HelpersService,
    private loginService: LoginService,
    private http: HttpClient,
    private toastService: ToastService,
    private router: Router
  ) {
    this.builderService.showBillingProgress = true
    this.subscriptionFlowHandler()
  }
  /**
   * Initializes the user's email and builder URL using helper services.
   */
  private initializeUserEmailAndBuilderUrl(): void {
    this.builderUrl = this.helpers.getBuilderUrl()
    this.user_email = this.loginService.getCurrentUser()
  }

  private subscriptionFlowHandler = () => {
    // initialize user email and builder URL
    this.initializeUserEmailAndBuilderUrl()
    // Get customer data from Stripe
    this.fetchStripeData().subscribe((processedCustomerData: any) => {
      if (this.stripe?.subscriptions?.length !== 0) {
        // Check if the subscription is on hold
        this.checkOnholdStatus().subscribe((subscriptionStatus: string) => {
          if (subscriptionStatus === 'onhold') {
            // Check if the last subscription status was "onhold" and handle expiration if it exceeds 30 days
            this.checkLastSubscriptionStatus().subscribe((diffInDays: number) => {
              this.builderService.showBillingProgress = false
              if (diffInDays >= 30) {
                this.handleExpiredOnHoldStatus()
                this.statusPopup()
              } else {
                // Nothing to do, the subscription is still on hold
                this.statusPopup()
              }
            })
          } else if (subscriptionStatus === 'deleted' || this.builderService.premium_status === 'deleted' || this.builderService.premium_status === 'cancelled') {
            this.builderService.showBillingProgress = false
            this.statusPopup()
          } else {
            this.builderService.showBillingProgress = false
          }
        })
      } else {
        // No subscription found
        this.builderService.showBillingProgress = false
      }
    })
  }

  /**
   * Fetches Stripe data from the backend
   */
  public fetchStripeData() {
    return new Observable<any>((observer) => {
      this.blockDataService.getStripeData().subscribe((subId: any) => {
        this.stripeService.retrieveCustomerData(subId).subscribe((customerData: any) => {
          let processedCustomerData = this.processStripeResponse(customerData)
          observer.next(processedCustomerData)
        })
      })
    })
  }

  /**
   * Processes the response from Stripe, extracting the latest payment methods and invoices.
   * @param customerData The response object from Stripe API.
   */
  private processStripeResponse(customerData: any): void {
    this.stripe = {
      ...customerData,
      payment_methods: this.helpers.getLastItem(customerData?.payment_methods),
      invoices: customerData?.invoices[0]
    }
    this.builderService.invoices = customerData.invoices
    return this.stripe
  }

  /**
   * Checks if the subscription is onhold or not and return the status
   */
  checkOnholdStatus() {
    return new Observable<any>((observer) => {
      const nextPaymentDate = new Date(this.stripe?.upcoming_invoice?.next_payment_attempt * 1000)
      const daysLeft = Math.ceil((nextPaymentDate.getTime() - this.today.getTime()) / (1000 * 60 * 60 * 24))
      if (daysLeft <= 3 && daysLeft >= 0) {
        setTimeout(() => {
          this.daysLeftToast(daysLeft)
        }, 5000)
      }

      if (this.today.getTime() > nextPaymentDate.getTime()) {
        console.log('The subscription has ended. Setting project status to "onhold".')
        this.setProjectStatus('onhold').subscribe(() => {
          // this is an onhold subscription
          observer.next('onhold')
        })
      } else {
        // This is an active susbcription
        observer.next('active')
      }
    })
  }
  /**
   * Displays a toast notification when a subscription is about to expire.
   */
  daysLeftToast(daysLeft: any) {
    this.toastService.initiate({
      type: 'daysLeft',
      title: 'Reminder',
      content: `Your service will be updated ${daysLeft === 0 ? 'today' : `in ${daysLeft} day${daysLeft > 1 ? 's' : ''}`}. Stay updated and enjoy uninterrupted access to all premium features!`,
      imgPath: '../assets/images/subscriptionFlow/toastOnholdImg.png',
      onClick: () => {
        this.router.navigate(['/billing'])
      }
    })
  }

  /**
   * Cancels the current subscription using the Stripe service.
   */
  cancelSubscription() {
    return new Observable<any>((observer) => {
      this.builderService.showUpdateProgress = true
      this.stripeService.cancelSubscription(this.stripe.invoices.subscription!).subscribe(
        (response: any) => {
          if (response) {
            console.log('Subscription canceled successfully:', response)
            this.builderService.showUpdateProgress = false
            // this.setProjectStatus('onhold')
            observer.next(response)
          } else {
            console.error('Error canceling subscription:', response)
            observer.error(response)
          }
        },
        (error) => {
          this.builderService.showUpdateProgress = false
          console.error('Error canceling subscription:', error)
          observer.error(error)
        }
      )
    })
  }

  /**
   * Updates the project's subscription status in the database if the status has changed.
   * @param status The new subscription status.
   */
  private setProjectStatus(status: string) {
    return new Observable<any>((observer) => {
      const subscriptionNewStatus = { status, date: this.today.toLocaleDateString() }
      this.db.getDatabase(`projects/${this.builderService.selectedProject}/subscription/subscriptionStatus`).subscribe((subscriptionData: any) => {
        if (subscriptionData && subscriptionData.status !== status) {
          this.db.setDatabase(`projects/${this.builderService.selectedProject}/subscription/subscriptionStatus`, [subscriptionNewStatus]).subscribe(() => {
            console.log('Subscription changes updated in the database:', subscriptionNewStatus)
            observer.next(subscriptionNewStatus)
          })
        } else {
          observer.next('No changes in subscription status.')
        }
      })
    })
  }

  /**
   * Checks if the last subscription status was "onhold" and handles expiration if it exceeds 30 days.
   */
  private checkLastSubscriptionStatus() {
    return new Observable<any>((observer) => {
      this.db.getDatabase(`projects/${this.builderService.selectedProject}/subscription/subscriptionStatus`).subscribe((subscriptionStatuses: any) => {
        const lastChange = this.helpers.getLastItem(subscriptionStatuses)
        if (lastChange.status === 'onhold') {
          const diffInDays = this.calculateDaysSince(lastChange.date)
          observer.next(diffInDays)
        }
      })
    })
  }

  /**
   * Calculates the number of days since a given date.
   * @param date The reference date in string format.
   * @returns The difference in days.
   */
  private calculateDaysSince(date: string): number {
    const lastChangeDate = new Date(date)
    return Math.floor((this.today.getTime() - lastChangeDate.getTime()) / (1000 * 60 * 60 * 24))
  }

  /**
   * Handles expiration of "onhold" subscription status by canceling and deleting the project status.
   */
  private handleExpiredOnHoldStatus(): void {
    this.cancelSubscription().subscribe(() => {
      this.setProjectStatus('deleted').subscribe(() => {})
    })
  }

  /**
   * Retrieves all subscriptions .
   * @returns An Observable that emits an array of subscription objects.
   */
  getAllSubscriptions(): Observable<any> {
    return this.http.get(this.builderUrl + 'retrieve_all_subscribers', this.options)
  }
  /**
   * Retrieves the subscriber details for the given Stripe subscription ID.
   * @param stripe_subscription_id - The Stripe subscription ID .
   */
  getSubscriberDetails(stripe_subscription_id: string) {
    let postData = new URLSearchParams()
    postData.set('stripe_subscription_id', stripe_subscription_id)
    return this.http.post(this.builderUrl + 'retrieve_subscriber_by_id', postData.toString(), this.options)
  }
  /**
   * Retrieves a pricing package by its ID from the environment configuration.
   * @param id - The unique identifier of the pricing package to retrieve.
   * @returns The pricing package object if found, or `null` if not found.
   */
  getPricingPackageById(id: string): any {
    let pricingPackagesByRegion = [...environment.pricing_packages, ...environment.egypt_pricing_packages, ...environment.emirates_pricing_packages, ...environment.saudi_pricing_packages]
    const result = pricingPackagesByRegion.find((pkg) => pkg.pricing_id === id)
    if (result) {
      return result
    } else {
      return null
    }
  }

  /**
   * Opens a dialog to display the status of an active subscription.
   * The dialog width is set to 900px or 90% of the screen width, whichever is smaller.
   * After the dialog is closed, a message is logged to the console.
   */
  statusPopup = () => {
    const isNonClosable = this.builderService.premium_status === 'deleted' || this.builderService.premium_status === 'cancelled'

    let dialogRef = this.dialog.open(WorksDialogComponent, {
      width: '900px',
      maxWidth: '100%',
      data: { name: 'subscriptionStatus' },
      disableClose: isNonClosable
    })

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed')
    })
  }
  /**
   * Cancels the auto-renewal of a Stripe subscription by switching it to manual invoicing.
   * @param stripe_subscription_id - The unique identifier of the Stripe subscription to cancel auto-renewal for.
   * @returns An observable representing the HTTP POST request to switch the subscription to manual invoicing.
   */
  cancelAutoRenewSubscription(stripe_subscription_id: string) {
    let postData = new URLSearchParams()
    postData.set('stripe_subscription_id', stripe_subscription_id)
    return this.http.post(this.builderUrl + 'switch_subscription_to_manual_invoicing', postData.toString(), this.options)
  }
}

<div class="containerWrapper">
  <mat-tab-group>
    <!-- Tab 1: Active Trials -->
    <mat-tab label="Active trials">
      <div class="tableWrapper">
        <div class="tableHeader">
          <h1 *ngIf="!mobile">{{ 'Active trials' | translate }}</h1>
          <div class="headerBtns"></div>
          <input (keyup)="searchTab1()" type="text" class="search" matInput [(ngModel)]="searchValTab1">
          <mat-paginator #paginator1 [pageSizeOptions]="[5, 10, 20]"></mat-paginator>
        </div>

        <table mat-table [dataSource]="dataSourceTab1" class="mat-elevation-z8">
          <!-- ID Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef class="firstTitle"> ID </th>
            <td mat-cell *matCellDef="let element"> {{ element.id }} </td>
          </ng-container>

          <!-- Email Column -->
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> Email </th>
            <td mat-cell *matCellDef="let element"> {{ element.email }} </td>
          </ng-container>

          <!-- Access Column -->
          <ng-container matColumnDef="access">
            <th mat-header-cell *matHeaderCellDef> Access </th>
            <td mat-cell *matCellDef="let element"> {{ element.access }} </td>
          </ng-container>

          <!-- Domain Column -->
          <ng-container matColumnDef="domain">
            <th mat-header-cell *matHeaderCellDef> Domain </th>
            <td mat-cell *matCellDef="let element"> {{ element.domain }} </td>
          </ng-container>

          <!-- Premium Package Column -->
          <ng-container matColumnDef="premium_package">
            <th mat-header-cell *matHeaderCellDef> Premium Package </th>
            <td mat-cell *matCellDef="let element"> {{ element.premium_package }} </td>
          </ng-container>

          <!-- Premium Status Column -->
          <ng-container matColumnDef="premium_status">
            <th mat-header-cell *matHeaderCellDef> Premium Status </th>
            <td mat-cell *matCellDef="let element"> {{ element.premium_status }} </td>
          </ng-container>

          <!-- Start Date Column -->
          <ng-container matColumnDef="start_date">
            <th mat-header-cell *matHeaderCellDef> Start Date </th>
            <td mat-cell *matCellDef="let element"> {{ element.start_date }} </td>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element"> {{ element.status }} </td>
          </ng-container>

          <!-- Type Column -->
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef> Type </th>
            <td mat-cell *matCellDef="let element"> {{ element.type }} </td>
          </ng-container>

          <!-- Header and Row Definitions -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </mat-tab>

    <!-- Tab 2: Completed Trials -->
    <mat-tab label="Active subscriptions">
      <div class="tableWrapper">
        <div class="tableHeader">
          <h1 *ngIf="!mobile">{{ 'Completed trials' | translate }}</h1>
          <div class="headerBtns"></div>
          <input (keyup)="searchTab2()" type="text" class="search" matInput [(ngModel)]="searchValTab2">
          <mat-paginator #paginator2 [pageSizeOptions]="[5, 10, 20]"></mat-paginator>
        </div>

        <table mat-table [dataSource]="dataSourceTab2" class="mat-elevation-z8">
          <!-- ID Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef class="firstTitle"> ID </th>
            <td mat-cell *matCellDef="let element"> {{ element.id }} </td>
          </ng-container>

          <!-- Email Column -->
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> Email </th>
            <td mat-cell *matCellDef="let element"> {{ element.email }} </td>
          </ng-container>

          <!-- Access Column -->
          <ng-container matColumnDef="access">
            <th mat-header-cell *matHeaderCellDef> Access </th>
            <td mat-cell *matCellDef="let element"> {{ element.access }} </td>
          </ng-container>

          <!-- Domain Column -->
          <ng-container matColumnDef="domain">
            <th mat-header-cell *matHeaderCellDef> Domain </th>
            <td mat-cell *matCellDef="let element"> {{ element.domain }} </td>
          </ng-container>

          <!-- Premium Package Column -->
          <ng-container matColumnDef="premium_package">
            <th mat-header-cell *matHeaderCellDef> Premium Package </th>
            <td mat-cell *matCellDef="let element"> {{ element.premium_package }} </td>
          </ng-container>

          <!-- Premium Status Column -->
          <ng-container matColumnDef="premium_status">
            <th mat-header-cell *matHeaderCellDef> Premium Status </th>
            <td mat-cell *matCellDef="let element"> {{ element.premium_status }} </td>
          </ng-container>

          <!-- Start Date Column -->
          <ng-container matColumnDef="start_date">
            <th mat-header-cell *matHeaderCellDef> Start Date </th>
            <td mat-cell *matCellDef="let element"> {{ element.start_date }} </td>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element"> {{ element.status }} </td>
          </ng-container>

          <!-- Type Column -->
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef> Type </th>
            <td mat-cell *matCellDef="let element"> {{ element.type }} </td>
          </ng-container>

          <!-- Header and Row Definitions -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { DatabaseService } from 'src/app/builder-services/database.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private builderService: BuilderService, private db: DatabaseService, private http: HttpClient) { }

  storedStandaloneProducts:any;

  submitProduct = (product: any, product_id: string = '') => {
    let project_id = this.builderService.selectedProject;
    product = JSON.parse(JSON.stringify(product));
    return new Observable<any>((observer) => {
      if(!product_id) {
        this.db.setDatabase('projects/' + project_id + '/products/', product, true).subscribe((response) => {
          observer.next(response); // Notify the observer that the operation is complete
          observer.complete(); // Complete the Observable
        }, (error) => {
          observer.error(error); // Notify the observer of an error
        });
      } else {
        this.db.setDatabase('projects/' + project_id + '/products/' + product_id, product).subscribe((response) => {
          observer.next(response); // Notify the observer that the operation is complete
          observer.complete(); // Complete the Observable
        }, (error) => {
          observer.error(error); // Notify the observer of an error
        });
      }
      
    });
  }


  getProducts = () => {
    let project_id = this.builderService.selectedProject;
    return new Observable<any>((observer) => {
      this.db.getDatabase('projects/' + project_id + '/products/').subscribe((response) => {
        if(!response) {
          console.log("No data available");
          observer.next({});
          observer.complete(); // Complete the Observable
        } else {
          const modifiedResponse = Object.keys(response).map(key => ({
            Id: key,
            path: 'projects/' + project_id + '/products/' + key,
            ...response[key]
          }));
          observer.next(modifiedResponse); // Notify the observer that the operation is complete
          // console.log("Modified response with Ids:", modifiedResponse);
          observer.complete(); // Complete the Observable
        }
        }, (error) => {
        observer.error(error); // Notify the observer of an error
      });
    });
  }

  removeProduct = (product_id: string) => {
    return new Observable<any>((observer) => {
      let project_id = this.builderService.selectedProject;
      this.db.removeDatabaseEndpoint('projects/' + project_id + '/products/' + product_id).subscribe((response) => {
        observer.next(response); // Notify the observer that the operation is complete
        observer.complete(); // Complete the Observable
      });
    });
  }

  getProduct = (product_id: string) => {
    let project_id = this.builderService.selectedProject;
    return new Observable<any>((observer) => {
      this.db.getDatabase('projects/' + project_id + '/products/' + product_id).subscribe((response) => {
        if(!response) {
          console.log("No data available");
          observer.next({});
          observer.complete(); // Complete the Observable
        } else {
          observer.next(response); // Notify the observer that the operation is complete
          observer.complete(); // Complete the Observable
        }
      }, (error) => {
        observer.error(error); // Notify the observer of an error
      });
    });
  }

  getProductsByCategories = (categoryArray: any, products:any) => {
    return products.filter((product:any) => {
      // Iterate through product properties (e.g., "-NemENWD4djXIoAIAj6g")
      for (const productId in product) {
        if (product.hasOwnProperty(productId) && product.categoriesIds) {
          const productCategoriesIds = Object.values(
            product.categoriesIds
          );
          // Check if there is an intersection between categoryArray and productCategoriesIds
          if (categoryArray && 
            categoryArray.some((category:any) =>
              productCategoriesIds.includes(category)
            )
          ) {
            return true; // Product has at least one matching category
          }
        }
      }
      return false; // No matching categories for this product
    });
  }
  getProductsBySubCategories = (categoryArray: any, products:any) => {
    return products.filter((product:any) => {
      // Iterate through product properties (e.g., "-NemENWD4djXIoAIAj6g")
      for (const productId in product) {
        if (product.hasOwnProperty(productId) && product.subCategoriesIds) {
          const subCategoriesIds = Object.values(
            product.subCategoriesIds
          );
          // Check if there is an intersection between categoryArray and productCategoriesIds
          if (
            categoryArray.some((category:any) =>
            subCategoriesIds.includes(category)
            )
          ) {
            return true; // Product has at least one matching category
          }
        }
      }
      return false; // No matching categories for this product
    });
  }

  /**
   * Get all products from wooCommerce
   */
  getWoocommerceProducts = (username: string, password: string, websiteURL: string, wooCommerceItemsToFetch: any): Observable<any> => {
    let wooCommerceUrl = websiteURL;
    if( !wooCommerceItemsToFetch) {
      wooCommerceItemsToFetch = 100;
    }
    wooCommerceUrl += '/wp-json/wc/v3/stacksGetProducts?perPage=' + wooCommerceItemsToFetch;
    console.log(wooCommerceUrl);
    let encodedCredentials = btoa(username + ':' + password); // Base64 encode the credentials

    let headers = new HttpHeaders({
      'Authorization': 'Basic ' + encodedCredentials, // Set the Authorization header
      'Content-Type': 'application/x-www-form-urlencoded' // If required
  });
  wooCommerceUrl = environment.proxyUrl+wooCommerceUrl;
  console.log(wooCommerceUrl);
  let options = { headers: headers };
    return this.http.get(wooCommerceUrl, options);
  }

  /**
   * Get all categories from wooCommerce
   */
  getWoocommerceCats = (username: string, password: string, websiteURL: string, wooCommerceItemsToFetch: any): Observable<any> => {
    let wooCommerceUrl = websiteURL + '/wp-json/wc/v3/stacksGetCategories';
    if( wooCommerceItemsToFetch && wooCommerceItemsToFetch > 0) {
      wooCommerceUrl += '?perPage=' + wooCommerceItemsToFetch;
    }
    let encodedCredentials = btoa(username + ':' + password); // Base64 encode the credentials

    let headers = new HttpHeaders({
      'Authorization': 'Basic ' + encodedCredentials, // Set the Authorization header
      'Content-Type': 'application/x-www-form-urlencoded' // If required
  });
  wooCommerceUrl = environment.proxyUrl+wooCommerceUrl;
  let options = { headers: headers };
    return this.http.get(wooCommerceUrl, options);
  }
}

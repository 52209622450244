import { Component, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTable, MatTableDataSource } from '@angular/material/table'
import { ActivatedRoute, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { stripeService } from 'src/app/pages/payment/stripe.service'
import { environment } from '../../../environments/environment'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { SubscriptionFlowService } from '../subscription-flow.service'
import { SubscribersService } from 'src/app/subscribers/subscribers.service'
import { AdminDashboardService } from 'src/app/admin/admin-dashboard/admin-dashboard.service'

export interface subscribersTableColumns {
  customer_email: string
  currency: string
  status: string
  invoice_date: any
  retrials: any
  Package: any
  end_date: string
  action: string
  total_amount: any
}

@Component({
  selector: 'app-all-subscribers',
  templateUrl: './all-subscribers.component.html',
  styleUrl: './all-subscribers.component.scss'
})
export class AllSubscribersComponent {
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatTable) table!: MatTable<subscribersTableColumns>
  @ViewChild(MatSort, { static: true }) matSort!: MatSort

  public dataSource = new MatTableDataSource<subscribersTableColumns>([])
  pageLoaded = false

  ELEMENT_DATA: any[] = []
  mobile = false
  searchKey!: string
  
  subscribers: any
  subscribers_data_counts: any[] = []
  displayedColumns: string[] = ['status','customer_email', 'invoice_date', 'end_date', 'type', 'duration', 'total_amount', 'action']
  stripeSubscriptionId!: string  
  constructor(private router: Router, private route: ActivatedRoute, private subscriptionFlowService: SubscriptionFlowService, private adminDashService: AdminDashboardService, public stripeService: stripeService, private builderService: BuilderService) {}

  ngOnInit(): void {
    if (window.screen.width <= 768) {
      this.mobile = true
    }
   this.fetchSubscribers()
  }
  fetchSubscribers(){
    this.builderService.showUpdateProgress = true;
    this.subscriptionFlowService.getAllSubscriptions().subscribe((response) => {
      console.log('response', response)
      this.subscribers=response.subscribers;
      this.renderSubscribers(this.subscribers).subscribe((data: any) => {
        this.dataSource.paginator = this.paginator
        this.dataSource.sort = this.matSort
        this.builderService.showUpdateProgress = false

        if (data) {
          this.table.renderRows()
        }
      })
    })
  }

  renderSubscribers = (result: any) => {
    this.builderService.showUpdateProgress = true
    return new Observable<any>((observer) => {
      this.builderService.showUpdateProgress = true
      this.ELEMENT_DATA = []
      result.forEach((subscription: any) => {
        this.builderService.showUpdateProgress = true
        this.ELEMENT_DATA.push({
          customer_email: subscription.customer.email,
          Package: subscription.plan.id,
          type: this.subscriptionFlowService.getPricingPackageById(subscription.plan.id,)?.type ,
          duration: this.subscriptionFlowService.getPricingPackageById(subscription.plan.id,)?.duration ,
          status: subscription.status,
          start_date: subscription.current_period_start,
          end_date: subscription.current_period_end,
          retrials: subscription.customer.next_invoice_sequence - 1,
          stripe_subscription_id: subscription.subscription_id,
          total_amount: subscription.plan.amount / 100 + ' ' + subscription.plan.currency,
          action: ''
        })
        // });
      })
      // this.dataSource = new MatTableDataSource<subscribersTableColumns>(this.ELEMENT_DATA);
      this.dataSource.data = this.ELEMENT_DATA; // Update the dataSource
      observer.next();
      observer.complete();
      this.builderService.showUpdateProgress = false;
    })
  }

  editSubscriber(id: string) {
    this.router.navigate([`/subscribers/info/${id}`], { relativeTo: this.route })
  }

 
}

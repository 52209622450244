import { Component, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BuilderService } from '../builder-services/builder.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { AppUsersService } from './app-users.service';


export interface usersTableColumns {
  date: string;
  time: any;
  email: any;
  user_id: string;
  nickname: string;
  email_verified: string;
  action: string;
}
@Component({
  selector: 'app-app-users',
  templateUrl: './app-users.component.html',
  styleUrl: './app-users.component.scss'
})
export class AppUsersComponent {

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatTable)
  table!: MatTable<usersTableColumns>;
  ELEMENT_DATA: usersTableColumns[] = [];
  public dataSource: any = [];
  pageLoaded = false;
  public userColumns: string[] = [];
  users:any = {};
  @ViewChild(MatSort, { static: true })
  matSort!: MatSort;
  usersList: any = [];
  searchText: string = '';
  statisticsData:any[]=[];
  constructor(private route: ActivatedRoute,
    private builderService: BuilderService, private router: Router,private AppUsersService:AppUsersService) { }

  ngOnInit(): void {
    this.getAllData();
  }

  getAllData = () => { 
    let projectId = String(localStorage.getItem("selectedProject"));
 
    this.AppUsersService.getUsers(projectId).subscribe((users: any) => {      
      this.ELEMENT_DATA = [];
      this.dataSource = users;
      this.statisticsData = [
        {name:'Total Users',value: users.length},
        {name:'Total Verified Users',value: users.filter((user:any) => user.email_verified).length},
        {name:'Total Non Verified Users',value: users.length - users.filter((user:any) => user.email_verified).length}
      ];
      this.pageLoaded = true;
      this.builderService.showUpdateProgress = true;
      this.userColumns = ['nickname','email','date','email_verified','action'];
      this.users = users;
      this.renderUsers(this.users).subscribe((response: any) => {
        this.dataSource = new MatTableDataSource<usersTableColumns>(this.ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.matSort;
        this.table.renderRows();
      });
    });
  };
  
  
  convertDate = (dateString: string): string => {
    // Extract the date part (before the 'T')
    const isoDate = dateString.split('T')[0]; // "2024-12-24"
    // Split the date into parts
    const parts = isoDate.split('-'); // ["2024", "12", "24"]
    // Rearrange to DD-MM-YYYY
    return `${parts[2]}-${parts[1]}-${parts[0]}`; // "24-12-2024"
  };
  
  renderUsers = (result: any) => {
    return new Observable<any>((observer) => {
      this.builderService.showUpdateProgress = false;
      let usersList:any = Object.values(result);      
      for (const key in usersList) {
        if(usersList[key].sub){
        if (Object.prototype.hasOwnProperty.call(usersList, key)) {
          const element = usersList[key];
            this.ELEMENT_DATA.push(
              { 
                email: element.email, 
                user_id: element.sub,
                nickname: element.nickname, 
                email_verified: element.email_verified,
                date: this.convertDate(element.updated_at),
                time: element.time,
                action: '',
              },
            );
          }
        }
        }

        this.dataSource = new MatTableDataSource<usersTableColumns>(this.ELEMENT_DATA);
        setTimeout(() => {
          observer.next(); // Notify the observer that the operation is complete
          observer.complete(); // Complete the Observable
        }, 100);
    });
      
  }

  editOrder = ( id: string ) => {
   this.router.navigate([`/app/users/${id}`], { relativeTo: this.route });
  }

  /**
   * Deletes a user after confirming the action with the user.
   * @param user_id - The unique identifier of the user to be deleted.
   * @param user_name - The name of the user to be deleted, used in the confirmation message.
   * @returns void
   */
  deleteOrder = (user_id: string, user_name: string) => {
    if (confirm("Are you sure to delete user " + user_name + " ? This step is unrecoverable")) {
      this.AppUsersService.removeUser(user_id).subscribe((response: any) => {
          alert("User deleted successfully");
          this.getAllData();
      });
    }
  }
  
  /**
   * Applies a filter to the data source of the user table.
   * The filter is applied to the name, email, and date columns of the table.
   * The filter value is taken from the `searchText` property, which is trimmed and converted to lowercase.
   */
  applyFilter() {
    const filterValue = this.searchText
      ? this.searchText.trim().toLowerCase()
      : '';

    this.dataSource.filterPredicate = (data: any, filter: string) => {
      return (
        data.nickname?.toLowerCase().includes(filter) ||
        data.email?.toLowerCase().includes(filter) ||
        data.date.includes(filter)
      );
    };

    this.dataSource.filter = filterValue;
  }
}

import { AppPreviewService } from './app-preview.service';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/builder-services/database.service';
import { MatDialog } from '@angular/material/dialog';
import { MixpanelService } from 'src/app/tracking/mixpanel.service';
import { Router } from '@angular/router';
import { WorksDialogComponent } from '../works-dialog/works-dialog.component';

/**
 * The `AppPreviewComponent` is responsible for displaying the app preview page.
 * It allows users to:
 * - View their project ID and sample app password.
 * - Download sample apps via QR codes for Android and iOS.
 * - Interact with dialogs for "How It Works" and onboarding feedback.
 * - Navigate to the subscription page and manage app-related settings.
 *
 * Key Features:
 * - Retrieves project settings (password and ID) from the backend.
 * - Generates random passwords if none are available.
 * - Tracks events using Mixpanel for analytics.
 * - Opens modal dialogs for additional user interaction.
 */

@Component({
  selector: 'app-preview',
  templateUrl: './app-preview.component.html',
  styleUrls: ['./app-preview.component.scss'],
})
export class AppPreviewComponent implements OnInit {
  public public_key = '';
  public src: any;
  value = 0;
  error = false;
  checkingStatus = true;
  buildStart = false;
  newSamplePw: string | null = null;
  prodjectId: string | null = null;
  appurl = '';
  // appurl = 'https://demo.stacksclients.com/wp-json/avaris-wc-rest/v3/';
  self_ltd = this.builderService.user_type == 'self_ltd' ? 'true' : 'false';

  public sampleAppQrCodes = {
    playStore:
      'https://qrcode.tec-it.com/API/QRCode?data=https://play.google.com/store/apps/details?id=com.stacksappsamples.www',
    appleStore: 'https://qrcode.tec-it.com/API/QRCode?data=https://apps.apple.com/app/stacks-apps/id6738991114', // Add the Apple Store QR code URL here
  };
  constructor(
    public AppPreviewService: AppPreviewService,
    public builderService: BuilderService,
    private router: Router,
    public dialog: MatDialog,
    private db: DatabaseService,
    private mixPanel: MixpanelService
  ) {}

  ngOnInit(): void {
    this.getInitialSettings();
  }

  /**
   * Opens a dialog explaining how the app works.
   * The dialog uses the `WorksDialogComponent` with specific configurations.
   */

  howItWorksDialog = () => {
    const dialogRef = this.dialog.open(WorksDialogComponent, {
      width: '700px',
      maxWidth: '80%',
      data: { name: 'appPreview' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  };

  /**
   * Tracks the "Subscribe Now" action and navigates the user to the payment page.
   * Also, opens a feedback dialog for onboarding-related feedback.
   */

  subscribeNow = () => {
    this.mixPanel.track('Subscribe Now', {
      projectid: this.builderService.getSelectedProject(),
    });
    this.builderService.setTour(false).subscribe((response) => {
      const dialogRef = this.dialog.open(WorksDialogComponent, {
        width: '700px',
        maxWidth: '80%',
        data: { name: 'onboardingFeedback' },
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log('The dialog was closed');
      });
      // this.builderService.selected_coupon_id = "o7ZwUU4C";
      this.router.navigate(['/billing']);
    });
  };

  /**
   * Fetches the initial settings for the project, including:
   * - The sample app password (`newSamplePw`).
   * - The project ID (`prodjectId`).
   * If the password is missing, it generates a new one.
   */

  getInitialSettings = () => {
    this.db
      .getDatabase(
        `/projects/${this.builderService.selectedProject}/settings/general_settings`
      )
      .subscribe(
        (settings) => {
          this.prodjectId = this.builderService.selectedProject;
          let randomPassword = settings.samplesPw || '';
          this.newSamplePw = randomPassword;
          if (!settings.samplesPw) {
            randomPassword = this.generateRandomPassword(6);
            const updatedPassword = {
              ...settings,
              samplesPw: randomPassword,
            };
            this.db
              .setDatabase(
                `/projects/${this.builderService.selectedProject}/settings/general_settings`,
                updatedPassword
              )
              .subscribe(() => {
                console.log('updated Password saved successfully.');
              });
          }
        },
        (err) => {
          console.log(err);
        }
      );
  };

  /**
   * Generates a random password of the specified length.
   * If an existing password is available, it returns the same.
   * @param length The length of the password to generate.
   * @returns The generated password or the existing one.
   */

  generateRandomPassword(length: number): string {
    if (this.newSamplePw && this.newSamplePw.trim().length > 0) {
      return this.newSamplePw;
    }

    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    this.newSamplePw = result;
    return result;
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProductsService } from '../products.service';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoriesService } from '../../categories/categories.service';



@Component({
  selector: 'new-product',
  templateUrl: './new-product.component.html',
  styleUrls: ['./new-product.component.scss']
})
export class NewProductComponent implements OnInit {

  panelOpen = true;
  attributeOpen = true;
  variationOpen = true;
  varDescOpen = false;
  productProgress = false;
  mobile = false;
  productDescription:any;
  selectedFile: File | null = null;
  imageUrl :any = [];
  showUploadProgress = false;
  showVarUploadProgress :any = [];
  productIdFromRoute:any;

  productAttributes:any = [{
    'name' : '',
    'value': ''
  }];

  productVariations: any = [{
    'values' : '',
    'image': '',
    'stock': '',
    'regularPrice': '',
    'salePrice': '',
  }];

  categories: any = [];
  selectedCategory:any;
  selectedSubCategory:any;
  possibileVariations:any = [];
  allowedSubcategories:any = [];
  constructor(private formBuilder: FormBuilder, private productsService: ProductsService, private router: Router, public builderService: BuilderService,
    private route: ActivatedRoute, private categoriesService: CategoriesService) {
    }

  productForm:any = this.formBuilder.group({
    productName: '',
    productDescription: '',
    regularPrice: '',
    salePrice: '',
    productSKU: '',
    imageUrl: [],
    productStock: '',
    productAttributes: [],
    categoriesIds: [], // Initialize as an empty array
    subCategoriesIds: [],
    productVariations: [],
    // minVarPrice: '',
    // maxVarPrice: '',
    // minVarSale:'',
    // maxVarSale:'',
    date: '',
  });

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.productIdFromRoute = routeParams.get('productId');
  
    this.categoriesService.getCategories().subscribe((categories: any) => {
      this.categories = categories;
      if(this.productIdFromRoute) {
        this.productsService.getProduct(this.productIdFromRoute).subscribe((product: any) => {
          // Create an empty FormGroup
          this.productForm = new FormGroup({});
  
          // Iterate through the properties of the product object and add them as form controls
          for (const key of Object.keys(product)) {
            
            if(key == 'categoriesIds' || key == 'subCategoriesIds') {
              product[key] = product[key].map(function(number:any) {
                return String(number);
              });
            }
            this.productForm.addControl(key, new FormControl(product[key]));
          }
          if(!product['categoriesIds']) {
            this.productForm.addControl('categoriesIds', new FormControl(product['categoriesIds']));
          }
          if(!product['subCategoriesIds']) {
            this.productForm.addControl('subCategoriesIds', new FormControl(product['subCategoriesIds']));
          }

          // !product['minVarSale'] ? this.productForm.addControl('minVarSale', new FormControl(product['minVarSale'])) : '';
          // !product['maxVarSale'] ? this.productForm.addControl('maxVarSale', new FormControl(product['maxVarSale'])) : '';
          // !product['minVarPrice'] ? this.productForm.addControl('minVarPrice', new FormControl(product['minVarPrice'])) : '';
          // !product['maxVarPrice'] ? this.productForm.addControl('maxVarPrice', new FormControl(product['maxVarPrice'])) : '';
          if( typeof product.imageUrl == 'string' ) {
            product.imageUrl = [product.imageUrl];

          }
          this.imageUrl = product.imageUrl;
          this.productAttributes = product.productAttributes;
          this.productVariations = product.productVariations;
          if(!this.productAttributes && !this.productVariations) {
            this.initAttributesandVariations();
          }
          if(!product.productStock) {
            if(product.stockManagement == false) {
              product.productStock = -1;
            } else {
              product.productStock = 0;
            }
            
            this.productForm.addControl('productStock', new FormControl(product.productStock));
          }
          this.productDescription = product.productDescription;
          this.selectedCategory = this.categoriesService.findCategoryById(this.categories,product.categoriesIds);
          this.selectedSubCategory = this.categoriesService.findSubCategoryById(this.categories,product.subCategoriesIds);
          this.categoryChange(product.categoriesIds);
          this.initVariations();
        })
      }
    })
    

    this.productForm = this.formBuilder.group({
      productName: ['',Validators.compose([
        Validators.required,
      ])],
      regularPrice: ['',Validators.compose([
          Validators.required,
      ])],
      productDescription: [''],
      salePrice: [''],
      productSKU: [''],
      imageUrl: [''],
      categoriesIds: [''],
      productStock: ['', Validators.compose([
          Validators.required,
          // PasswordValidator.isValid
      ])],
      productAttributes: [''],
      productVariations: [''],
      subCategoriesIds: [''],
      date: [''],
      // minVarPrice: [''],
      // maxVarPrice: [''],
      // minVarSale: [''],
      // maxVarSale: [''],
  })

  if (window.screen.width <= 768) { // 768px portrait
    this.mobile = true;
  }
  }

  onSubmit = () => {
    this.productProgress = true;
    let productFormVal = this.productForm.value;
    productFormVal.productAttributes = this.productAttributes;
    productFormVal.productVariations = this.productVariations;
    productFormVal.productDescription = this.productDescription;
    productFormVal.categoriesIds = this.categoriesService.getCategoryIdFromCategories(this.selectedCategory, 'category');
    productFormVal.subCategoriesIds = this.categoriesService.getCategoryIdFromCategories(this.selectedSubCategory, 'subcategory');
    productFormVal.imageUrl = this.imageUrl;
    const hasEmptyFields = productFormVal.productVariations.some((variation: { regularPrice: string; stock: number }) => {
      const hasValidAttributes = productFormVal.productAttributes &&
                                 productFormVal.productAttributes.some((attr: { name: string; value: string }) => 
                                     attr.name.trim() !== "" && attr.value.trim() !== ""
                                 );
      
      return hasValidAttributes && (!variation.regularPrice || !variation.stock);
    });
    if(this.productForm.status == 'INVALID' || (productFormVal.productAttributes && productFormVal.productAttributes[0].name.length > 0 && productFormVal.productVariations && productFormVal.productVariations[0].regularPrice.length == 0 || hasEmptyFields) ) {
      alert("Please fill all the required fields and make sure that all the variations are filled ")
      this.productProgress = false;
      return;
    }
    if(this.productIdFromRoute) {
      this.productsService.submitProduct(productFormVal, this.productIdFromRoute).subscribe((result:any) => {
        this.productProgress = false;
        if(this.builderService.getTour()) {
          // this.router.navigate(['/']);
          this.router.navigate(['/']);
        } else {
          this.router.navigate(['/products']);
        }
      })
    } else {
      productFormVal.date = new Date().toLocaleDateString();
      this.productsService.submitProduct(productFormVal).subscribe((result:any) => {
        this.productProgress = false;
        if(this.builderService.getTour()) {
          // this.router.navigate(['/']);
          this.router.navigate(['/']);
        } else {
          this.router.navigate(['/products']);
        }
      })
    }

  }

  onFileSelected(event: any, type='simple', index=0) {
    console.log(this.imageUrl)
    this.selectedFile = event.target.files[0] as File;
    if (!this.selectedFile) {
      console.error('No file selected.');
      return;
    }
    this.showUploadProgress = true;
    this.builderService.uploadImage(this.selectedFile, this.builderService.selectedProject, 'products')?.subscribe((response) => {
      if( type == 'variation' ) {
        this.variationChange('image',response, index, '');
      } else {
        if(!this.imageUrl) {
          this.imageUrl = [];
        }
          this.imageUrl.push(response);
      }
      this.showUploadProgress = false;
    });
  }
  onVarFileSelected(event: any, type='simple', index:any) {
    this.selectedFile = event.target.files[0] as File;
    if (!this.selectedFile) {
      console.error('No file selected.');
      return;
    }
    this.showVarUploadProgress[index] = true;
    this.builderService.uploadImage(this.selectedFile, this.builderService.selectedProject, 'products')?.subscribe((response) => {
      if( type == 'variation' ) {
        this.variationChange('image',response, index, '');
      } else {
        this.imageUrl.push(response);
      }
      this.showVarUploadProgress[index] = false;
    });
  }
  removeImg(index:number){
    this.imageUrl = " ";
  }
  removeFromImgArr(index:number){
    this.imageUrl.splice(index, 1);
  }
  editImg(event: any ,index: number){
    this.showUploadProgress = true;
    this.selectedFile = event.target.files[0] as File;
    this.builderService.uploadImage(this.selectedFile, this.builderService.selectedProject, 'products')?.subscribe((response) => {
      this.imageUrl[index] = response;
    })
    this.showUploadProgress = false;
  }

  removeVariationImg(index: number) {
    this.productVariations[index]['image'] = '';
  }

  addAttr = () => {
    this.initVariations();
    this.productAttributes.push({
      'name' : '',
      'value': ''
    });
  }

  initAttributesandVariations = () => {
    this.productAttributes = [{
      'name' : '',
      'value': ''
    }];
    this.productVariations = [{
      'values' : '',
      'image': '',
      'stock': '',
      'regularPrice': '',
      'salePrice': '',
    }]
  }

  initVariations = () => {
    this.possibileVariations = [];
    for (const key in this.productAttributes) {
      if (Object.prototype.hasOwnProperty.call(this.productAttributes, key)) {
        const element = this.productAttributes[key];
        this.possibileVariations.push( this.splitAndTrimValues(element.value) );
      }
    }
  }

  public splitAndTrimValues(value: string): string[] {
    if (!value) return [];
    
    return value.split(',').map(item => item.trim());
  }

  removeAttr = (index: number) => {
    this.productAttributes.splice(index,1);
    this.initVariations();
  }

  addVar = () => {
    this.productVariations.push({
      'values' : '',
      'image': '',
      'stock': '',
      'regularPrice': '',
      'salePrice': '',
    });
  }

  removeVar = (index: number) => {
    this.productVariations.splice(index,1);
  }


  attributeChange = (name: string, event: any, index: number) => {
    let data = event.currentTarget.value;
    if (name === 'attrName') {
      this.productAttributes[index].name = data;
    } else if (name === 'attrValue') {
      // Split the input string into an array of values
      let valuesArray = this.splitAndTrimValues(data);
  
      // Trim each value and join them back with a space and comma
      data = valuesArray.map((value: string) => value.trim()).join(', ');
  
      this.productAttributes[index].value = data;
      this.initVariations();
    }
  }
  
  

  variationChange = (name: string, value: any, index: number, variationValues: any) => {
    if( name == 'values' ) {
      if (!this.productVariations[index][name]) {
        this.productVariations[index][name] = '';
      }
      // check the value across possible values to replace it, if nothing can be changed then concat the new value to the string
      // otherwise just add it
      if (this.productVariations[index][name] ) {
          this.productVariations[index][name] = this.variationAdjuster(value, variationValues);
          this.productVariations[index][name] += ',' + value;

          // Split the input string into an array of words
          let words = this.splitAndTrimValues(this.productVariations[index][name]);

          // Use Set to get unique values and convert it back to an array
          let uniqueWords = Array.from(new Set(words));

          // Convert the unique array back to a string
          this.productVariations[index][name] = uniqueWords.join(",");
          
      } else {
          this.productVariations[index][name] = value.toString();
      }
    } else {
      this.productVariations[index][name] = value;
    }

    // console.log(this.productVariations, index, name, this.productVariations[index][name], value);
  }

  variationLookup = (variations:string, variationLookup: any) => {
    const variationsArray = this.splitAndTrimValues(variations);
    return variationsArray.filter(element => variationLookup.includes(element))[0];
  }

  /**
   * 
   * Example for how this works
   * selected values =  ["large", "red"]

      possible values = [ "large", " small"] ["red", "blue"]

      The following function takes a selected value
      example variationAdjuster("small");

      the selected_values array will be ["small","red"]

      the function replaced the large with small since both combinations can replace each other
   * @param newValue the new value selected in the variation
   * @param variationValues all the values in the variation
   * 
   * return true: means value adjusted
   * return false: means there is nothing to change
   */
  variationAdjuster(newValue:any, variationValues: any) {
    // Define the possible value combinations
  
    // Iterate through each possible value combination
    for (const combination of this.possibileVariations) {
      // Check if the newValue is in the combination
      if (combination.includes(newValue)) {
        let arrayVarValues = this.splitAndTrimValues(variationValues);
        // Replace the old value with the new value
        arrayVarValues = arrayVarValues.map((value: any) =>
          combination.includes(value) ? newValue : value
        );
        variationValues = arrayVarValues.join(',');
      }
    }
    return variationValues;
  }


  categoryChange = (value: string) => {
    this.selectedCategory = this.categoriesService.findCategoryById(this.categories, value);
    this.allowedSubcategories = [];
    for (const category of this.selectedCategory) {
      if(!category.subcategories || category.subcategories.length == 0) {
        continue;
      }
      for (const subcategory of category.subcategories) {
        this.allowedSubcategories.push(subcategory);
      }
    }
  }

  subCategoryChange = (value: string) => {
    this.selectedSubCategory = value;
  }

  getVariationStock = (variation: any) => {
    if( (variation.stockManagement == false && variation.stock_status == 'instock') 
    || (variation.stockManagement == true && (!variation.stock))
    ) {
      return -1;
    } else if( (variation.stock_status == 'outofstock') 
    || variation.stockManagement == true && (variation.stock == 0)) {
      return 0;
    } else {
      return variation.stock;
    }
  }
}

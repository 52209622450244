import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HelpersService } from 'src/app/helpers.service';
import { BuilderService } from '../../builder-services/builder.service';
import { timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AndroidbuildService {
  
  buildingAndroid = false;

  private builderUrl: string;

  options = {
    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  };
  token: any;

  constructor(private http: HttpClient, private helper: HelpersService, private builderService: BuilderService) {
    this.builderUrl = this.helper.getBuilderUrl();
    this.token = this.builderService.getToken();
    this.buildingAndroid = true;
  }

  startBuild = (id: any, version: any, versionCode:any, type: string = '', timeout_val: number = 0) => {
    this.token = this.builderService.getToken();
    let postData = new URLSearchParams();
    postData.set('process', "sync_ui");
    postData.set('projectId', id);
    postData.set('standalone', this.builderService.standalone ? 'true' : 'false');
    postData.set('type', type);
    postData.set('version', JSON.stringify(version));
    postData.set('versionCode', JSON.stringify(versionCode));
    postData.set('token', this.token);

    if(timeout_val !== 0) {
      return this.http.post(this.builderUrl + 'build-android', postData.toString(), this.options).pipe(timeout(timeout_val));
    } else {
      return this.http.post(this.builderUrl + 'build-android', postData.toString(), this.options);
    }
    
  }

  getProgress = (id: any, version: any) => {
    this.token = this.builderService.getToken();
    let postData = new URLSearchParams();
    postData.set('process', "sync_ui");
    postData.set('projectId', id);
    postData.set('version', JSON.stringify(version));
    postData.set('type', 'android_status');
    postData.set('token', this.token);

    return this.http.post(this.builderUrl + 'get-build-progress', postData.toString(), this.options);
    
  }

  getBuildError = (id: any) => {
    this.token = this.builderService.getToken();
    let postData = new URLSearchParams();
    postData.set('projectId', id);
    postData.set('token', this.token);
    postData.set('platform', 'android');

    return this.http.post(this.builderUrl + 'get-build-error', postData.toString(), this.options);
  }
}

import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { BuilderService } from '../../builder-services/builder.service'
import { Observable } from 'rxjs'
import { DatabaseService } from 'src/app/builder-services/database.service'
import { DiscordService } from 'src/app/builder-services/discord.service'
import { MixpanelService } from 'src/app/tracking/mixpanel.service'
import { LoginService } from 'src/app/login/login.service'
import { Router } from '@angular/router'
import { SubscriptionFlowService } from 'src/app/subscription-flow/subscription-flow.service'
import { BlockDataService } from 'src/app/block-services/block-data.service'

export interface DialogData {
  name: string
}

@Component({
  selector: 'works-dialog',
  templateUrl: './works-dialog.component.html',
  styleUrls: ['./works-dialog.component.scss']
})
export class WorksDialogComponent {
  message = ''
  currentGifIndex = 0

  gifList = [
    'assets/gifs/new gifs/choose temp.gif',
    'assets/gifs/new gifs/add block and edits.gif',
    'assets/gifs/new gifs/add product and category.gif',
    'assets/gifs/new gifs/analytics.gif',
    'assets/gifs/new gifs/build apk.gif'
    // Add more paths to your GIFs as needed
  ]
  gifRemoved: boolean = false
  premiumData: any
  projectData: any
  showMore = false
  displayedFeatures: any[] = []
  hiddenFeatures: any[] = []
  constructor(
    public dialogRef: MatDialogRef<WorksDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public builderService: BuilderService,
    private db: DatabaseService,
    private discordService: DiscordService,
    public mixPanel: MixpanelService,
    public loginService: LoginService,
    private router: Router,
    public SubscriptionFlow: SubscriptionFlowService,
    private blockDataService: BlockDataService
  ) {}

  ngOnInit() {
    this.getProjectActiveFeatures()

    setTimeout(() => {
      this.gifRemoved = true
    }, 2500)
    this.loadNextGif()
  }

  getProjectActiveFeatures(): void {
    const cacheResponse = this.builderService.cacheProjectReq.response
    this.projectData = this.getProjectData(cacheResponse)

    if (cacheResponse) {
      this.premiumData = this.parsePremiumPackage(cacheResponse)
      const matchingPackages = this.getMatchingPackages(cacheResponse, this.premiumData.type)

      if (matchingPackages) {
        const filteredFeatures = this.getFilteredFeatures(matchingPackages[0].features)

        this.displayedFeatures = this.getDisplayedFeatures(filteredFeatures)
        this.hiddenFeatures = this.getHiddenFeatures(filteredFeatures)
      }
    }
  }

  private getProjectData(cacheResponse: any): any {
    return cacheResponse?.premium?.[0] || null
  }

  private parsePremiumPackage(cacheResponse: any): any {
    const premium = cacheResponse?.premium?.[0]?.package
    return premium ? JSON.parse(premium) : null
  }

  private getMatchingPackages(cacheResponse: any, packageType: string): any[] {
    return cacheResponse?.package?.filter((pkg: any) => pkg.type === packageType) || []
  }

  private getFilteredFeatures(features: any[]): any[] {
    const checkIconPath = 'assets/images/PricingPage/check.png'
    return features.filter((feature: any) => feature.icon === checkIconPath)
  }

  private getDisplayedFeatures(features: any[]): any[] {
    return features.slice(0, 4)
  }

  private getHiddenFeatures(features: any[]): any[] {
    return features.slice(4)
  }

  toggleFeatures() {
    this.showMore = !this.showMore
  }

  onNoClick(): void {
    this.dialogRef.close()
  }

  get currentGifPath() {
    return this.gifList[this.currentGifIndex]
  }

  loadNextGif() {
    this.currentGifIndex = (this.currentGifIndex + 1) % this.gifList.length
  }

  completeTour(dialogOrder: any) {
    this.builderService.checklist[dialogOrder].done = true
    this.dialogRef.close()
    localStorage.setItem('checklist', JSON.stringify(this.builderService.checklist))
  }

  closeDialog = (dialogName: string) => {
    localStorage.setItem(dialogName, 'closed')
    this.dialogRef.close()
  }
  claimOffer = (dialogName: string) => {
    localStorage.setItem(dialogName, 'closed')
    this.dialogRef.close()
    this.router.navigate(['/billing'])
  }

  submitFeedback(comment: string) {
    this.message = comment
    if (this.message === '') {
      this.message = 'N/A'
    }
    if (this.builderService.starRating === 0) {
      this.builderService.starRating = 'N/A'
    }
    // this.sendMessageToDiscord();
    this.setFeedback(this.message).subscribe((result: any) => {
      this.mixPanel.track('onboarding_feedback ', {
        comment: this.message,
        rating: this.builderService.starRating
      })
      this.dialogRef.close()
    })
  }

  // sendMessageToDiscord() {
  //   // console.log(this.message)
  //   let content = 'rating : ' + this.builderService.starRating + ' and comment : ' + this.message + ' from ' + this.loginService.getCurrentUser().username
  //   this.discordService.sendMessage( content, "Feedback" )
  //     .subscribe(
  //       () => {
  //         console.log('Message sent to Discord successfully!');
  //         // Handle success
  //       },
  //       error => {
  //         console.error('Error sending message to Discord:', error);
  //         // Handle error
  //       }
  //     );
  // }

  setFeedback(comment: string) {
    return new Observable<any>((observer) => {
      this.db.setDatabase('projects/' + this.builderService.selectedProject + '/onboarding/feedback/', { comment: comment, rating: this.builderService.starRating }).subscribe(
        (response) => {
          observer.next(response) // Notify the observer that the operation is complete
          observer.complete() // Complete the Observable
        },
        (error) => {
          observer.error(error) // Notify the observer of an error
        }
      )
    })
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { DatabaseService } from 'src/app/builder-services/database.service';
import { HelpersService } from 'src/app/helpers.service';

@Injectable({
  providedIn: 'root',
})
export class AppSettingsService {
  private builderUrl: string;

  options = {
    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
  };
  token: any;

  constructor(private http: HttpClient, private helper: HelpersService, private builderService: BuilderService, private db: DatabaseService) {
    this.builderUrl = this.helper.getBuilderUrl();
    this.token = this.builderService.getToken();
  }
  /**
   * Uploads an image to the backend server along with metadata.
   * The backend handles the image storage and processing.
   *
   * @param image The image file to be uploaded.
   * @param id The unique project identifier for associating the image.
   * @param type The type of the image (e.g., "thumbnail", "banner").
   * @returns An Observable that emits the HTTP response from the backend API.
   */

  public uploadImage(image: File, id: any, type: string): Observable<any> {
    //     console.log(image.name, id, type);
    //     // Initialize Firebase
    // //     // Create a root reference
    //     const storage = getStorage();
    // //     // Create a reference to 'mountains.jpg'
    //     const mountainsRef = refStorage(storage, '/project_assets/images/'+id+'/'+image.name);
    //     // 'file' comes from the Blob or File API
    //     uploadBytes(mountainsRef, image).then((snapshot) => {
    //       const formData = new FormData();
    //       this.token = this.builderService.getToken();
    //       formData.append('image', image);
    //       formData.append('token', this.token);
    //       formData.append('projectId', id);
    //       formData.append('image_type', type);

    //       return this.http.post(this.builderUrl + 'receive-image', formData);
    //     });

    // // // Create a reference to 'images/mountains.jpg'
    // const mountainImagesRef = refStorage(storage, 'images/mountains.jpg');

    const formData = new FormData();
    this.token = this.builderService.getToken();
    formData.append('image', image);
    formData.append('token', this.token);
    formData.append('projectId', id);
    formData.append('image_type', type);

    return this.http.post(this.builderUrl + 'receive-image', formData);
  }

  /**
   * Uploads a file to the backend server along with metadata.
   * The backend handles the file storage and processing.
   *
   * @param file The file to be uploaded.
   * @param id The unique project identifier for associating the file.
   * @param type The type of the file (e.g., "document", "image").
   * @param name The name of the file (optional, defaults to an empty string).
   * @returns An Observable that emits the HTTP response from the backend API.
   */

  public uploadFile(file: File, id: any, type: string, name: string = ''): Observable<any> {
    const formData = new FormData();
    this.token = this.builderService.getToken();
    formData.append('file', file);
    formData.append('token', this.token);
    formData.append('projectId', id);
    formData.append('file_type', type);
    formData.append('file_name', name);

    return this.http.post(this.builderUrl + 'receive-file', formData);
  }

  /**
   * Receives the encryption key from google play console and sends to the brain
   * the brain will then process the pepk java tool and send back the release key
   * @param id the project id
   * @returns
   */
  public generateReleaseKeys(id: any): Observable<any> {
    const formData = new FormData();
    this.token = this.builderService.getToken();
    formData.append('token', this.token);
    formData.append('projectId', id);

    return this.http.post(this.builderUrl + 'generate-release-keys', formData);
  }

  /**
   * Updates the general settings of a project in Firebase and synchronizes them with the WordPress backend.
   *
   * This function performs the following steps:
   * 1. Updates the general settings in the Firebase database.
   * 2. Fetches existing general settings from the WordPress backend.
   * 3. Merges the new general settings with the existing ones from WordPress.
   * 4. Sends the updated settings back to the WordPress backend for synchronization.
   *
   * @param id The unique project identifier.
   * @param generalSettings The general settings object to be updated and synchronized.
   * @returns An Observable that emits the response after the settings are successfully updated, or an error if the process fails.
   */
  setGeneralSettings = (id: any, generalSettings: any) => {
    generalSettings = JSON.parse(JSON.stringify(generalSettings));
    return new Observable<any>((observer) => {
      this.db.setDatabase('projects/' + id + '/settings/general_settings', generalSettings).subscribe(
        (response) => {
          // Save the General Settings to the WP Database, in order for the splash and icon to be updated
          // @TODO make the splash and icon update to be done from the firebase database
          let getData = new URLSearchParams();
          this.token = this.builderService.getToken();
          getData.set('process', 'sync_ui');
          getData.set('projectId', id);
          getData.set('token', this.token);
          let newGeneralSettings = '';
          this.http.post(this.builderUrl + 'get-general-settings', getData.toString(), this.options).subscribe((responseGeneralSettings: any) => {
            for (const key in generalSettings) {
              if (Object.prototype.hasOwnProperty.call(generalSettings, key)) {
                const element = generalSettings[key];
                responseGeneralSettings[key] = element;
                console.log(key, element);
              }
            }
            newGeneralSettings = responseGeneralSettings;
            let postData = new URLSearchParams();
            this.token = this.builderService.getToken();
            postData.set('process', 'sync_ui');
            postData.set('projectId', id);
            postData.set('generalSettings', JSON.stringify(newGeneralSettings));
            postData.set('token', this.token);

            this.http.post(this.builderUrl + 'update-general-settings', postData.toString(), this.options).subscribe(
              (response) => {
                observer.next(response); // Notify the observer that the operation is complete
                observer.complete(); // Complete the Observable
              },
              (error) => {
                observer.error(error); // Notify the observer of an error
              }
            );
          });
        },
        (error) => {
          observer.error(error); // Notify the observer of an error
        }
      );
    });
  };

  /**
   * Retrieves the general settings of a project from Firebase or WordPress backend.
   *
   * This function performs the following:
   * 1. If the builder is in standalone mode, fetches the general settings directly from Firebase.
   * 2. If not in standalone mode, retrieves the general settings from the WordPress backend via an HTTP POST request.
   *
   * @param id The unique project identifier.
   * @returns An Observable that emits the general settings object or an empty object if no data is available.
   */

  getGeneralSettings = (id: any) => {
    if (this.builderService.standalone) {
      return new Observable<any>((observer) => {
        this.db.getDatabase('projects/' + id + '/settings/general_settings').subscribe(
          (response) => {
            if (response) {
              observer.next(response); // Notify the observer that the operation is complete
              observer.complete(); // Complete the Observable
            } else {
              console.log('No data available');
              observer.next({});
              observer.complete(); // Complete the Observable
            }
          },
          (error) => {
            observer.error(error); // Notify the observer of an error
          }
        );
      });
    } else {
      let postData = new URLSearchParams();
      this.token = this.builderService.getToken();
      postData.set('process', 'sync_ui');
      postData.set('projectId', id);
      postData.set('token', this.token);

      return this.http.post(this.builderUrl + 'get-general-settings', postData.toString(), this.options);
    }
  };

  /**
   * Updates the Firebase-specific settings of a project in Firebase and synchronizes them with the WordPress backend.
   *
   * This function performs the following steps:
   * 1. Saves the Firebase-specific settings to the Firebase database.
   * 2. Fetches the current general settings from the WordPress backend.
   * 3. Merges the updated Firebase settings into the existing general settings.
   * 4. Sends the merged settings back to the WordPress backend for synchronization.
   *
   * @param id The unique project identifier.
   * @param firebaseSettings The Firebase-specific settings to be updated and synchronized.
   * @returns An Observable that emits the response after the settings are successfully updated, or an error if the process fails.
   */
  setFirebaseSettings = (id: any, firebaseSettings: any) => {
    firebaseSettings = JSON.parse(JSON.stringify(firebaseSettings));
    return new Observable<any>((observer) => {
      this.db.setDatabase('projects/' + id + '/settings/general_settings/firebase_settings', firebaseSettings).subscribe(
        (response) => {
          // Save the General Settings to the WP Database, in order for the splash and icon to be updated
          // @TODO make the splash and icon update to be done from the firebase database
          let getData = new URLSearchParams();
          this.token = this.builderService.getToken();
          getData.set('process', 'sync_ui');
          getData.set('projectId', id);
          getData.set('token', this.token);
          let newGeneralSettings = '';
          this.http.post(this.builderUrl + 'get-general-settings', getData.toString(), this.options).subscribe(
            (responseGeneralSettings: any) => {
              for (const key in firebaseSettings) {
                if (Object.prototype.hasOwnProperty.call(firebaseSettings, key)) {
                  const element = firebaseSettings[key];
                  responseGeneralSettings[key] = element;
                  console.log(key, element);
                }
              }
              newGeneralSettings = responseGeneralSettings;
              let postData = new URLSearchParams();
              this.token = this.builderService.getToken();
              postData.set('process', 'sync_ui');
              postData.set('projectId', id);
              postData.set('generalSettings', JSON.stringify(newGeneralSettings));
              postData.set('token', this.token);

              this.http.post(this.builderUrl + 'update-general-settings', postData.toString(), this.options).subscribe(
                (response) => {
                  observer.next(response); // Notify the observer that the operation is complete
                  observer.complete(); // Complete the Observable
                },
                (error) => {
                  observer.error(error); // Notify the observer of an error
                }
              );
            },
            (error) => {
              observer.error(error); // Notify the observer of an error
            }
          );
        },
        (error) => {
          observer.error(error); // Notify the observer of an error
        }
      );
    });
  };

  /**
   * Retrieves the Firebase-specific settings for a given project ID from the Firebase database.
   *
   * This function performs the following steps:
   * 1. Fetches the Firebase-specific settings from the Firebase database for the given project ID.
   * 2. If the settings exist, emits them through the Observable.
   * 3. If no settings are found, emits an empty object (`{}`).
   * 4. Handles errors and propagates them to the caller.
   *
   * @param id The unique project identifier.
   * @returns An Observable that emits the Firebase settings object or an empty object if no data is available.
   */
  getFirebaseSettings = (id: any) => {
    // if(this.builderService.standalone)  {
    return new Observable<any>((observer) => {
      this.db.getDatabase('projects/' + id + '/settings/general_settings/firebase_settings').subscribe(
        (response) => {
          if (response) {
            observer.next(response); // Notify the observer that the operation is complete
            observer.complete(); // Complete the Observable
          } else {
            console.log('No data available');
            observer.next({});
            observer.complete(); // Complete the Observable
          }
        },
        (error) => {
          observer.error(error); // Notify the observer of an error
        }
      );
    });
    // }
  };

  /**
   * Updates the application settings of a project in Firebase and synchronizes them with the WordPress backend.
   *
   * This function performs the following steps:
   * 1. Saves the application settings to the Firebase database under the specified project ID.
   * 2. Prepares and sends the updated application settings to the WordPress backend for synchronization.
   * 3. Emits a response upon successful completion or propagates an error if the process fails.
   *
   * @param id The unique project identifier.
   * @param applicationSettings The application settings object to be updated and synchronized.
   * @returns An Observable that emits the response after the settings are successfully updated, or an error if the process fails.
   */

  setApplicationSettings = (id: any, applicationSettings: Object) => {
    applicationSettings = JSON.parse(JSON.stringify(applicationSettings));
    return new Observable<any>((observer) => {
      this.db.setDatabase('projects/' + id + '/settings/application_settings', applicationSettings).subscribe(
        (response) => {
          let postData = new URLSearchParams();
          this.token = this.builderService.getToken();
          postData.set('process', 'sync_ui');
          postData.set('projectId', id);
          postData.set('applicationSettings', JSON.stringify(applicationSettings));
          postData.set('token', this.token);

          this.http.post(this.builderUrl + 'update-application-settings', postData.toString(), this.options);
          observer.next(response); // Notify the observer that the operation is complete
          observer.complete(); // Complete the Observable
        },
        (error) => {
          observer.error(error); // Notify the observer of an error
        }
      );
    });
  };

  /**
   * Updates the application settings of a project in the WordPress backend.
   *
   * This function performs the following steps:
   * 1. Prepares the application settings and required metadata, including the project ID and authentication token.
   * 2. Sends the application settings to the WordPress backend for synchronization.
   * 3. Returns the HTTP response from the backend API.
   *
   * @param id The unique project identifier.
   * @param applicationSettings The application settings object to be updated in the WordPress backend.
   * @returns An Observable that emits the response from the WordPress backend.
   */

  setWPApplicationSettings = (id: any, applicationSettings: Object) => {
    let postData = new URLSearchParams();
    this.token = this.builderService.getToken();
    postData.set('process', 'sync_ui');
    postData.set('projectId', id);
    postData.set('applicationSettings', JSON.stringify(applicationSettings));
    postData.set('token', this.token);

    return this.http.post(this.builderUrl + 'update-application-settings', postData.toString(), this.options);
  };

  /**
   * Retrieves the application settings for a specific project from the WordPress backend.
   *
   * This function performs the following steps:
   * 1. Prepares the required data, including the project ID and authentication token.
   * 2. Sends a request to the WordPress backend to fetch the application settings.
   * 3. Returns the HTTP response containing the application settings.
   *
   * @param id The unique project identifier.
   * @returns An Observable that emits the application settings object retrieved from the WordPress backend.
   */

  getApplicationSettings = (id: any) => {
    // if(this.builderService.standalone) {
    //   return new Observable<any>((observer) => {
    //     this.db.getDatabase('projects/' + id + '/settings/application_settings').subscribe((response) => {
    //       if(response) {
    //         observer.next(response); // Notify the observer that the operation is complete
    //         observer.complete(); // Complete the Observable
    //       } else {
    //         console.log("No data available");
    //         observer.next({});
    //         observer.complete(); // Complete the Observable
    //       }
    //     }, (error) => {
    //       observer.error(error); // Notify the observer of an error
    //     });
    //   });
    // } else {
    let postData = new URLSearchParams();
    this.token = this.builderService.getToken();
    postData.set('process', 'sync_ui');
    postData.set('projectId', id);
    postData.set('token', this.token);

    return this.http.post(this.builderUrl + 'get-application-settings', postData.toString(), this.options);
    // }
  };

  /**
   * Updates the content settings of a project in Firebase and synchronizes them with the WordPress backend.
   *
   * This function performs the following steps:
   * 1. Saves the content settings to the Firebase database under the specified project ID.
   * 2. Prepares and sends the updated content settings to the WordPress backend for synchronization.
   * 3. Emits a response upon successful completion or propagates an error if the process fails.
   *
   * @param id The unique project identifier.
   * @param contentSettings The content settings object to be updated and synchronized.
   * @returns An Observable that emits the response after the settings are successfully updated, or an error if the process fails.
   */

  setContentSettings = (id: any, contentSettings: Object) => {
    contentSettings = JSON.parse(JSON.stringify(contentSettings));
    return new Observable<any>((observer) => {
      this.db.setDatabase('projects/' + id + '/settings/content_settings', contentSettings).subscribe(
        (response) => {
          let postData = new URLSearchParams();
          this.token = this.builderService.getToken();
          postData.set('process', 'sync_ui');
          postData.set('projectId', id);
          postData.set('contentSettings', JSON.stringify(contentSettings));
          postData.set('token', this.token);

          this.http.post(this.builderUrl + 'update-content-settings', postData.toString(), this.options).subscribe((response_content) => {});
          observer.next(response); // Notify the observer that the operation is complete
          observer.complete(); // Complete the Observable
        },
        (error) => {
          observer.error(error); // Notify the observer of an error
        }
      );
    });
  };

  /**
   * Retrieves the content settings for a specific project from the WordPress backend.
   *
   * This function performs the following steps:
   * 1. Prepares the required data, including the project ID and authentication token.
   * 2. Sends a request to the WordPress backend to fetch the content settings.
   * 3. Returns the HTTP response containing the content settings.
   *
   * @param id The unique project identifier.
   * @returns An Observable that emits the content settings object retrieved from the WordPress backend.
   */

  getContentSettings = (id: any) => {
    let postData = new URLSearchParams();
    this.token = this.builderService.getToken();
    postData.set('process', 'sync_ui');
    postData.set('projectId', id);
    postData.set('token', this.token);

    return this.http.post(this.builderUrl + 'get-content-settings', postData.toString(), this.options);
    // }
  };

  /**
   * Updates the Apple-specific settings of a project in Firebase and synchronizes them with the WordPress backend.
   *
   * This function performs the following steps:
   * 1. Saves the Apple-specific settings to the Firebase database under the specified project ID.
   * 2. Prepares and sends the updated Apple settings to the WordPress backend for synchronization.
   * 3. Emits a response upon successful completion or propagates an error if the process fails.
   *
   * @param id The unique project identifier.
   * @param appleSettings The Apple-specific settings object to be updated and synchronized.
   * @returns An Observable that emits the response after the settings are successfully updated, or an error if the process fails.
   */

  setAppleSettings = (id: any, appleSettings: Object) => {
    appleSettings = JSON.parse(JSON.stringify(appleSettings));
    return new Observable<any>((observer) => {
      this.db.setDatabase('projects/' + id + '/settings/apple_settings', appleSettings).subscribe(
        (response) => {
          let postData = new URLSearchParams();
          this.token = this.builderService.getToken();
          postData.set('process', 'sync_ui');
          postData.set('projectId', id);
          postData.set('appleSettings', JSON.stringify(appleSettings));
          postData.set('token', this.token);

          this.http.post(this.builderUrl + 'update-apple-settings', postData.toString(), this.options).subscribe((response_apple) => {});
          observer.next(response); // Notify the observer that the operation is complete
          observer.complete(); // Complete the Observable
        },
        (error) => {
          observer.error(error); // Notify the observer of an error
        }
      );
    });
  };

  /**
   * Retrieves the Apple-specific settings for a specific project from the WordPress backend.
   *
   * This function performs the following steps:
   * 1. Prepares the required data, including the project ID and authentication token.
   * 2. Sends a request to the WordPress backend to fetch the Apple-specific settings.
   * 3. Returns the HTTP response containing the Apple-specific settings.
   *
   * @param id The unique project identifier.
   * @returns An Observable that emits the Apple-specific settings object retrieved from the WordPress backend.
   */

  getAppleSettings = (id: any) => {
    let postData = new URLSearchParams();
    this.token = this.builderService.getToken();
    postData.set('process', 'sync_ui');
    postData.set('projectId', id);
    postData.set('token', this.token);

    return this.http.post(this.builderUrl + 'get-apple-settings', postData.toString(), this.options);
    // }
  };

  /**
   * Updates the global settings of a project in Firebase or synchronizes them with the WordPress backend.
   *
   * This function performs the following steps:
   * 1. If the application is in standalone mode:
   *    - Saves the global settings to the Firebase database under the specified project ID.
   *    - Emits a response upon successful completion or propagates an error if the process fails.
   * 2. If not in standalone mode:
   *    - Prepares and sends the global settings to the WordPress backend for synchronization.
   *    - Returns the HTTP response from the backend API.
   *
   * @param id The unique project identifier.
   * @param data The global settings object to be updated and synchronized.
   * @returns An Observable that emits the response after the settings are successfully updated, or an error if the process fails.
   */

  setGlobalSettings = (id: any, data: any) => {
    if (this.builderService.standalone) {
      return new Observable<any>((observer) => {
        this.db.setDatabase('projects/' + id + '/settings/global_settings', data).subscribe(
          (response) => {
            observer.next(response); // Notify the observer that the operation is complete
            observer.complete(); // Complete the Observable
          },
          (error) => {
            observer.error(error); // Notify the observer of an error
          }
        );
      });
    } else {
      let postData = new URLSearchParams();
      this.token = this.builderService.getToken();
      postData.set('process', 'sync_ui');
      postData.set('projectId', id);
      postData.set('token', this.token);
      postData.set('data', JSON.stringify(data));
      return this.http.post(this.builderUrl + 'update-global-settings', postData.toString(), this.options);
    }
  };

  /**
   * Retrieves the global settings of a project from Firebase or the WordPress backend.
   *
   * This function performs the following steps:
   * 1. If the application is in standalone mode:
   *    - Fetches the global settings from the Firebase database for the specified project ID.
   *    - Emits the settings or an empty object if no data is found.
   * 2. If not in standalone mode:
   *    - Sends a request to the WordPress backend to fetch the global settings.
   *    - Returns the HTTP response containing the settings.
   *
   * @param id The unique project identifier.
   * @returns An Observable that emits the global settings object or an empty object if no data is available, or an HTTP response from the WordPress backend.
   */

  getGlobalSettings = (id: any) => {
    if (this.builderService.standalone) {
      return new Observable<any>((observer) => {
        this.db.getDatabase('projects/' + id + '/settings/global_settings').subscribe(
          (response) => {
            if (response) {
              observer.next(response); // Notify the observer that the operation is complete
              observer.complete(); // Complete the Observable
            } else {
              console.log('No data available');
              observer.next({});
              observer.complete(); // Complete the Observable
            }
          },
          (error) => {
            observer.error(error); // Notify the observer of an error
          }
        );
      });
    } else {
      let postData = new URLSearchParams();
      this.token = this.builderService.getToken();
      postData.set('process', 'sync_ui');
      postData.set('projectId', id);
      postData.set('token', this.token);

      return this.http.post(this.builderUrl + 'get-global-settings', postData.toString(), this.options);
    }
  };

  /**
   * Retrieves the menu data for a specific project from Firebase.
   *
   * This function performs the following steps:
   * 1. Fetches the menu data from the Firebase database under the specified project ID.
   * 2. Emits the retrieved menu data if available.
   * 3. Emits an empty object (`{}`) if no data is found.
   *
   * @param id The unique project identifier.
   * @returns An Observable that emits the menu data object or an empty object if no data is available.
   */

  getMenueData(id: any) {
    return new Observable<any>((observer) => {
      this.db.getDatabase('projects/' + id + '/settings/menus').subscribe((response: any) => {
        if (response) {
          observer.next(response); // Notify the observer that the operation is complete
          observer.complete(); // Complete the Observable
        } else {
          console.log('No data available');
          observer.next({});
          observer.complete(); // Complete the Observable
        }
      });
    });
  }

  /**
   * Adds default settings for a new project in Firebase, including application, content, general, Apple, and global settings, as well as default shipping and translations.
   *
   * This function performs the following steps:
   * 1. Defines default settings for various configurations including application, content, and global settings.
   * 2. Fetches default translation data from a JSON file and processes it into a key-value object.
   * 3. Saves the default settings and shipping information to Firebase under the specified project ID.
   * 4. Saves the processed translations to Firebase under the translations path for the project.
   * 5. Emits a response upon successful completion or propagates an error if the process fails.
   *
   * @param id The unique project identifier.
   * @returns An Observable that completes when all default settings and data have been successfully saved or propagates an error if the process fails.
   */

  addProjectDefaultSettings = (id: any) => {
    return new Observable<any>((observer) => {
      let defaults = {
        application_settings: {
          mobile_title_family: 'poppins',
          mobile_title_font_weight: 'bold',
          mobile_title_font_color: '#5e5e5e',
          mobile_base_font_size: '11',
          mobile_text_font_weight: 'regular',
          mobile_text_color: '#707070',
          mobile_primary_color: '#567edc',
          mobile_secondary_color: '#44AF69',
          facebook_hash_id: '',
          facebook_app_id: '',
          facebook_app_name: '',
          android_server_key: '',
          android_sender_id: '',
          google_service_json: '',
          email_login: true,
          google_login: true,
          phone_login: true,
          twitter_login: true,
        },
        content_settings: {
          category_sub_page: '',
          header_background: '',
          contact_email: '',
          mobile_webview_link: '',
          product_title_length: '',
          product_title_dots: '',
        },
        general_settings: {
          application_name: 'Stacks',
          application_description: 'This description is for building process only and it won’t be published to your store accounts.',
          package_name: 'com.stacks.www',
          ipa_sku_number: '1',
          icon: 'demo_app_icon.png',
          splash_screen: 'Stacks_splash.png',
        },
        apple_settings: {
          email: '',
          password: '',
          phone: '',
          app_specific_pass: '',
          team_name: '',
          team_id: '',
        },
        global_settings: {
          'font-family': 'Poppins',
          'font-size': '14px',
          file: '',
        },
        currency: '$',
        standalone: true,
      };
      let default_shipping = [
        {
          countryId: '1703504692659-6573',
          countryName: 'Country 1',
          countryPrice: 50,
          governorates: [
            {
              governorateId: '1704199536604-3818',
              governorateName: 'Sub 1',
              governoratePrice: '20',
            },
          ],
        },
        {
          countryId: '1703504692659-6573',
          countryName: 'Country 2',
          countryPrice: 30,
        },
      ];
      this.http.get<string[]>('assets/i18n/txtTranslation.json').subscribe(
        (translationData) => {
          const translationsObject: { [key: string]: { value: string } } = {};
          translationData.forEach((text) => {
            const key = this.generateValidKey(text);
            translationsObject[key] = { value: text };
          });

          this.db.setDatabase('projects/' + id + '/settings/', defaults).subscribe(
            () => {
              this.db.setDatabase('projects/' + id + '/shipping/', default_shipping).subscribe(
                () => {
                  const translationsPath = `projects/${id}/translations/languages/English/texts`;
                  this.db.setDatabase(translationsPath, translationsObject).subscribe(
                    () => {
                      // let application_settings = {
                      //   "mobile_title_family" : "poppins",
                      //   "mobile_title_font_weight" : "bold",
                      //   "mobile_title_font_color" : "#5e5e5e",
                      //   "mobile_base_font_size" : "11",
                      //   "mobile_text_font_weight" : "regular",
                      //   "mobile_text_color" : "#707070",
                      //   "mobile_primary_color" : "#707070",
                      //   "mobile_secondary_color" : "#f53c3d",
                      //   "facebook_hash_id" : "",
                      //   "facebook_app_id" : "",
                      //   "facebook_app_name" : "",
                      //   "android_server_key" : "",
                      //   "android_sender_id" : "",
                      //   "google_service_json" : ""
                      // };
                      // this.setApplicationSettings(id, application_settings).subscribe((resp: any) => {

                      // });
                      observer.next(); // Notify the observer that the operation is complete
                      observer.complete(); // Complete the Observable
                    },
                    (error) => {
                      observer.error(error);
                    }
                  );
                },
                (error) => {
                  observer.error(error);
                }
              );
            },
            (error) => {
              observer.error(error);
            }
          );
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  };

  /**
   * Generates a valid key for use in Firebase by replacing invalid characters in a string.
   *
   * Firebase keys cannot include certain special characters like `.`, `#`, `$`, `/`, `[`, `]`, and spaces.
   * This function replaces these characters with a space.
   *
   * @param input The string to be transformed into a valid Firebase key.
   * @returns A sanitized string that can be used as a Firebase key.
   */

  generateValidKey(input: string): string {
    return input.replace(/[.#$\/\[\] ]/g, ' ');
  }

  /**
   * Retrieve Google Fonts to be used in the application
   */
  getGoogleFonts = () => {
    return this.http.get('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyA2Z5RS6mlbf7LwObccjvhFYjeRLKj40CE');
  };

  /**
   * Retrieve and save all the settings again
   */
  syncSettings = (id: any) => {
    return new Observable<any>((observer) => {
      // Sync the general settings
      this.getGeneralSettings(id).subscribe((generalSettings: any) => {
        this.setGeneralSettings(id, generalSettings).subscribe(() => {
          // Sync the application settings
          this.getApplicationSettings(id).subscribe((applicationSettings: any) => {
            this.setApplicationSettings(id, applicationSettings).subscribe(() => {
              // Sync the content settings
              this.getContentSettings(id).subscribe((contentSettings: any) => {
                this.setContentSettings(id, contentSettings).subscribe(() => {
                  // Sync the apple settings
                  this.getAppleSettings(id).subscribe((appleSettings: any) => {
                    this.setAppleSettings(id, appleSettings).subscribe(() => {
                      observer.next('All settings Synced'); // Notify the observer that the operation is complete
                      observer.complete(); // Complete the Observable
                    });
                  });
                });
              });
            });
          });
        });
      });
    });
  };
}

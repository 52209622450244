import { Component, OnInit } from '@angular/core'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { stripeService } from '../payment/stripe.service'
import { environment } from 'src/environments/environment'
import { BlockDataService } from 'src/app/block-services/block-data.service'
import { SubscriptionFlowService } from 'src/app/subscription-flow/subscription-flow.service'
import { AppSettingsService } from 'src/app/settings/services/app-settings.service'
import { LoginService } from 'src/app/login/login.service'
import { MatSlideToggleChange } from '@angular/material/slide-toggle'

@Component({
  selector: 'billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {
  environment = environment
  premiumData: any
  user: any
  generalSettingsData: any = {};
  autoRenewal!: boolean;
  constructor(private loginService: LoginService, private appSettingsService: AppSettingsService, public builderService: BuilderService, public stripeService: stripeService, private blockDataService: BlockDataService, public SubscriptionFlow: SubscriptionFlowService) {
    this.blockDataService.getStripeData().subscribe((subId: any) => {
      this.builderService.showUpdateProgress = true
      this.stripeService.getInvoices(subId).subscribe((response: any) => {
        this.builderService.showUpdateProgress = false
        this.builderService.subscription_package = JSON.parse(this.builderService.active_subscription_plan.package)
      })
    })
    if (this.builderService.stripe_subscription_id) {
      this.blockDataService.sendStripeData(this.builderService.stripe_subscription_id)
    }
  }
  ngOnInit(): void {
    this.getInitialSettings()
    this.builderService.getProjects(this.loginService.getCurrentUser().email).subscribe((allProjects) => {
      const firstKey = Object.keys(allProjects)[0]
      this.user = allProjects[firstKey]
    })
    /**
     * Fetches the Stripe subscription data and sets the `autoRenewal` property based on the subscription's collection method.
     * If the collection method is 'charge_automatically', `autoRenewal` is set to `true`.
     * If the collection method is 'send_invoice', `autoRenewal` is set to `false`.
     */
    this.SubscriptionFlow.fetchStripeData().subscribe(data => {
      if(data.subscriptions[0].collection_method == 'charge_automatically') {
        this.autoRenewal = true;
      }else if(data.subscriptions[0].collection_method == 'send_invoice'){
        this.autoRenewal = false;
  
      }
    })

  }

  getInitialSettings = () => {
    let project_id = this.builderService.selectedProject
    this.appSettingsService.getGeneralSettings(project_id).subscribe(
      (settings: any) => {
        this.generalSettingsData = settings
      },
      (err) => {
        console.log(err)
      }
    )
  }

  billingRedirect = () => {
    this.stripeService.gotoBilling().subscribe((response: any) => {
      window.location.href = response
    })
  }

  cancelSubscription(): void {
    const userConfirmed = confirm('Are you sure you want to cancel the subscription?')
    if (userConfirmed) {
      this.SubscriptionFlow.cancelSubscription().subscribe(
        (response) => {
          console.log('Subscription canceled successfully:', response)
        },
        (error) => {
          console.error('Error canceling subscription:', error)
        }
      )
    } else {
      console.log('Subscription cancel action was aborted by the user.')
    }
  }

  /**
   * Handles the toggle event for auto-renewal of the subscription.
   * Updates the autoRenewal property based on the toggle state and
   * disables auto-renewal if the toggle is turned off.
   * @param event - The toggle change event containing the new state of the toggle.
   */
  onAutoRenewalToggle(event: MatSlideToggleChange): void {
    this.autoRenewal = event.checked;
    if (!this.autoRenewal) {
       this.disableAutoRenewalSubscription();
    }
  }

  /**
   * Disables the auto-renewal feature for the current subscription.
   * This function calls the `cancelAutoRenewSubscription` method from the `SubscriptionFlow` service,
   * passing the current subscription ID. It logs the success or error response from the service.
   */
  disableAutoRenewalSubscription(): void {
      this.SubscriptionFlow.cancelAutoRenewSubscription(this.builderService.stripe_subscription_id).subscribe(response => {
          console.log('Subscription cancel the auto-renewal successfully:', response)
        }, error => {
          console.log('Errorcancel the auto-renewal subscription:', error)
        }
      )
  }
}

import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { WorksDialogComponent } from 'src/app/pages/works-dialog/works-dialog.component';
import { AppSettingsService } from 'src/app/settings/services/app-settings.service';
import { MixpanelService } from 'src/app/tracking/mixpanel.service';
import { BuilderService } from '../../builder-services/builder.service';
import { AndroidbuildService } from './androidbuild.service';

@Component({
  selector: 'android-build',
  templateUrl: './android-build.component.html',
  styleUrls: ['./android-build.component.scss'],
})
export class AndroidBuildComponent {

  buildStart = false;
  value = 0;
  downloadBtn = '';
  downloadAABBtn = '';
  checkingStatus = true;
  error = false;
  playType = 'aab';
  buildErrorMsg = '';
  warningMessage: string = "";
  generalSettingsData : any = {};
  webviewApp = false;
  constructor(private formBuilder: FormBuilder,
    public builderService: BuilderService,
    private androidService: AndroidbuildService, 
    public dialog: MatDialog,
    private mixPanel: MixpanelService,
    private appSettingsService: AppSettingsService) { 

    this.getBuildStatus();
    this.checkWebview();
    }

    androidBuildForm = this.formBuilder.group({
      androidVersion: ['',[
        Validators.required, 
        Validators.min(0.1), 
        Validators.max(9999), 
      ]],
      androidVersionCode:['',[
        Validators.required, 
        Validators.min(1), 
        Validators.max(999999), 
        Validators.pattern('^[0-9]*$')
      ]]  
    });
  
  /**
   * Handles input events to ensure only numeric values are entered.
   * @param event - The input event triggered by the user.
   */
  onInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    inputElement.value = inputElement.value.replace(/[^0-9]/g, '');
  }
  ngOnInit() {
    setTimeout(() => {
      this.getInitialSettings();
    }, 1000);
  }

  checkWebview = () => {
    this.appSettingsService.getContentSettings(this.builderService.selectedProject).subscribe((settings: any) => {
      if (settings.mobile_webview_link !== null) {
        this.webviewApp = true;
      }

    }, (err) => {
      console.log(err);
    });
  }

  /**
   * Submits a build request to the server
   */
  // onSubmit(): void {
  //   this.value=0;
  //   this.buildStart = true;
  //   this.builderService.headerBtn = '';
  //   this.error = false;
  //   if (!this.androidBuildForm.value.androidVersion) {
  //     this.androidBuildForm.value.androidVersion = '0.1';
  //   }
  //   this.mixPanel.track('APK Build', {
  //     projectid: this.builderService.selectedProject
  //   });
  //   this.androidService.startBuild(this.builderService.getSelectedProject(), this.androidBuildForm.value.androidVersion)
  //   .subscribe({
  //     next: (response: any) => {
  //         console.log('Response received:', response);
  //         this.getBuildStatus();
  //     },  
  //     error: (err) => {
  //       setTimeout(() => {          
  //         this.getBuildStatus();
  //         console.error('HTTP Error:', err);
  //       }, 60000);
  //     }
  //   });  
  // }

  /**
   * Generate ABB Files
   */
   generateAAB = () => {
    this.value=0;
    this.buildStart = true;
    this.builderService.headerBtn = '';
    this.error = false;
    if (!this.androidBuildForm.value.androidVersion) {
      this.androidBuildForm.value.androidVersion = '0.1';
    }
    if (!this.androidBuildForm.value.androidVersionCode) {
      this.androidBuildForm.value.androidVersionCode = '0';
    }
    this.mixPanel.track('AAB Build', {
      projectid: this.builderService.selectedProject
    });
    this.androidService.startBuild(this.builderService.getSelectedProject(), this.androidBuildForm.value.androidVersion, this.androidBuildForm.value.androidVersionCode,'aab').subscribe({
      next: (response: any) => {
          console.log('Response received:', response);
          this.getBuildStatus();
      },  
      error: (err) => {
        setTimeout(() => {          
          this.getBuildStatus();
          console.error('HTTP Error:', err);
        }, 30000);
      }
    });
  }

  /**
   * Gets the build status from the builder and brain, incase the app is still progressing then we will rerun the same function checker again after 5 seconds
   */
  getBuildStatus = () => {
    this.androidService.getProgress(this.builderService.getSelectedProject(), this.androidBuildForm.value.androidVersion).subscribe((progressRes: any) => {
      this.value = progressRes.data;
      this.checkingStatus = false;
      if(this.value === 0 && progressRes.status !== "error") {
        this.buildStart = false;
      } else {
        if (this.value <= 100) {
          if (progressRes.status !== "error" && this.value == 100) {
            this.downloadBtn = progressRes.android_url;
            this.downloadAABBtn = progressRes.aab_url;
            // this.qrLink = "https://qrcode.tec-it.com/API/QRCode?data=" + progressRes.android_url;
            this.error = false;
            this.buildStart = false;
          } else if (progressRes.status == "error") {
            this.error = true;
            this.getBuildError();
            this.builderService.headerBtn == 'android';
          } else {
            setTimeout(() => {
              this.getBuildStatus();
            }, 5000);
            this.error = false;
          }
          this.buildStart = true;
        }
      }
    })
  }

  getBuildError = () => {
    this.androidService.getBuildError(this.builderService.getSelectedProject()).subscribe((response: any) => {
      this.buildErrorMsg = decodeURIComponent(response.message.replace(/\+/g, ' '));
    })
  }

  rebuildApp = () => {
    this.buildStart = false;
    this.checkingStatus = false;
    this.error = false;
  }

  howItWorksDialog = () => {
    const dialogRef = this.dialog.open(WorksDialogComponent, {
      width: '700px',
      maxWidth: '80%',
      data: {name: "android"},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  getInitialSettings = () => {
    let project_id = this.builderService.selectedProject;
    this.appSettingsService.getGeneralSettings(project_id).subscribe(
      (settings: any) => {
        this.generalSettingsData = settings !== null ? settings : {};
        const packageName = this.generalSettingsData.package_name;
        this.appSettingsService.getApplicationSettings(project_id).subscribe(
          (appSettings: any) => {
            console.log(appSettings);
            const auth0RedirectURI = appSettings.auth0_redirect_uri || 'com.stacks.www://dev-3t3cfn38mfbo482t.us.auth0.com/capacitor/com.stacks.www/callback';
            const auth0PackageNameMatch = auth0RedirectURI.match(/capacitor\/(.*?)\/callback/);
            const auth0PackageName = auth0PackageNameMatch ? auth0PackageNameMatch[1] : "";
            if (packageName !== auth0PackageName || !auth0RedirectURI.startsWith(`${packageName}://`)) {
              this.warningMessage =
                "The package name does not match the Auth0 login configuration. Please ensure the package name in the settings matches the Auth0 redirect URI.";
            } else {
              this.warningMessage = "";
            }
          },
          (err) => {
            console.error("Error fetching application settings:", err);
          }
        );
      },
      (err) => {
        console.error("Error fetching general settings:", err);
      }
    );
  };


  processFileUpload(fileInput: any, type: string, name: string) {
    const file: File = fileInput.target.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      this.appSettingsService
        .uploadFile(file, this.builderService.selectedProject, type, name)
        .subscribe(
          (res) => {
            this.builderService.showUpdateProgress = false;
          },
          (err) => {
            this.builderService.showUpdateProgress = false;
            alert('error uploading file, please contact support');
          }
        );
    });

    reader.readAsDataURL(file);
  }

  /*
  The `generateReleaseKeys` function is responsible for triggering the generation of release keys 
  for the selected project through the `appSettingsService`. It manages a progress indicator 
  and handles success and error responses. On success, it downloads the generated keys.
*/

  generateReleaseKeys() {
    this.builderService.showUpdateProgress = true;
    this.appSettingsService
      .generateReleaseKeys(this.builderService.selectedProject)
      .subscribe(
        (res) => {
          console.log(res.body);
          this.downloadFile(res.body);
          this.builderService.showUpdateProgress = false;
          alert('Release keys generated successfully');
        },
        (err) => {
          this.builderService.showUpdateProgress = false;
          alert('Error generating release keys');
        }
      );
  }

  /*
  The `downloadFile` function facilitates downloading a file from a given URL. 
  It dynamically creates an anchor element, triggers the download, and cleans up the DOM after.
*/
  
  downloadFile(fileUrl: string) {
    const anchor = document.createElement('a');
    anchor.href = fileUrl;
    anchor.download = 'release_keys.zip';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockDataService } from '../block-services/block-data.service';
import { CategoriesService } from '../blocks-wrapper/block-categories/categories.service';
import { BuilderService } from '../builder-services/builder.service';
import { LoginService } from '../login/login.service';
import { environment } from 'src/environments/environment';
import { BlockFieldsService } from '../block-services/block-fields.service';
import { AppSettingsService } from '../settings/services/app-settings.service';
import { CountriesService } from '../builder-services/countries/countries.service';
import { MixpanelService } from '../tracking/mixpanel.service';
import { LanguageService } from '../language.service';
import { TranslateService } from '@ngx-translate/core';
import { DatabaseService } from '../builder-services/database.service';
import { WorksDialogComponent } from '../pages/works-dialog/works-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SubscriptionFlowService } from '../subscription-flow/subscription-flow.service';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  objectValues = Object.values;
  private user_email;
  projectUrl = '';
  mobile = false;
  environment = environment;
  multiProjects = false;
  projectsLimit:any = 1;
  projectsLength: any = 1;
  trialsCount = 0;
  premiumCount = 0;
  spinnerVal = 0;
  mobile_webview_link = '';
  finishedFetchingProject = false;
  selectedCode: any;
  siteLangLocalStorage:any = '';
  toggleSidebar = false;
  constructor( 
    public builderService: BuilderService,
    private dialog:MatDialog,
    public blockDataService: BlockDataService,
    private blockFieldsService: BlockFieldsService,
    public loginService: LoginService,
    public languageService: LanguageService,
    private router: Router,
    private route: ActivatedRoute,
    private categoriesService: CategoriesService,
    private appSettingsService: AppSettingsService,
    public countries: CountriesService,
    private translate: TranslateService,
    private mixPanel: MixpanelService,
    private db: DatabaseService,
    public subFlow: SubscriptionFlowService
    ) { 
      let code = navigator.language;
      if( this.countries.countryListAllIsoData.find(obj => obj.code === code) ) {
        this.countries.selectedCountry = [
          this.countries.countryListAllIsoData.find(obj => obj.code === code)
        ];
      }
      
      if(code !== 'en') {
        this.countries.selectedCountry.push(this.countries.countryListAllIsoData.find(obj => obj.code === 'en'));
      }
    this.user_email = loginService.getCurrentUser().email;
    /**
     * Get All Projects in the constructor
     */
    this.getAllProjects();
    

    /**
     * Get Projects limit to display for the customer
     */
    this.builderService.getProjectsLimit().subscribe( (projectsLimit) => {
      this.projectsLimit = projectsLimit;
      this.spinnerVal = (this.premiumCount/this.projectsLimit)*100;
      this.builderService.projectsLimit = this.projectsLimit;
      this.builderService.projectsPremiumCount = this.premiumCount;
    });

    if(localStorage.getItem('token')) {
      this.blockDataService.sendTokenData(localStorage.getItem('token'))
    }
    // let projectId = String(localStorage.getItem("selectedProject"));
    // if(projectId) {
    //   this.blockDataService.sendProjectData(projectId);
    // }
  }

  /**
   * Get all projects and sets the localstorage
   */
  getAllProjects = () => {
    this.builderService.getProjects(this.user_email).subscribe(
      (allProjects) => {
        this.trialsCount = 0;
        this.premiumCount = 0;
        let projectsIds = [];
        // if(allProjects[0] == null) {
        //   alert("you don't have active subscriptions, please go to the main website to renew your subscription")
        // }
        this.builderService.projects = [];
        for (const key in allProjects) {
          if (Object.prototype.hasOwnProperty.call(allProjects, key)) {
            const element = allProjects[key];
            if(element.type == '' ) {
              element.type = 'purchase';
            }
            this.builderService.projects.push(
              { 'id': element.id, 
              "status": element.status, 
              "type": element.type,
              "domain": element.domain,
              "email": element.email,
              "first_name": element.first_name,
              "last_name": element.last_name,
              "premium_status": element.premium_status,
              "premium_package": element.premium_package,
             });
              // this.categoriesIds.push(element.term_id);
              projectsIds.push(element.id);
              if( element.status == 'active' ) {
                if( element.type == 'trial' ) {
                  this.trialsCount++;
                } else {
                  this.premiumCount++;
                }
              }

          }
          this.builderService.projectsPremiumCount = this.premiumCount;
        }
        this.projectsLength = allProjects.length;
        if (allProjects.length > 1) {
          this.multiProjects = true;
        }
        /**
         * If there is a selected project in the localstorage then retrieve
         */
        let projectId;
        if (!projectsIds.includes(String(localStorage.getItem("selectedProject")))) {
          for (const key in allProjects) {
            if (Object.prototype.hasOwnProperty.call(allProjects, key)) {
              const element = allProjects[key];
              if(element.status == 'active') {
                projectId = element.id;
                localStorage.setItem("selectedProject", projectId);
              }
            }
          }
        } else {
          projectId = String(localStorage.getItem("selectedProject"));
        }
        this.blockDataService.sendProjectData(projectId);
        this.getProject(projectId);
      },
      (err) => {
      }
    );
  }

  ngOnInit () {
    if (window.screen.width <= 768) { // 768px portrait
      this.mobile = true;
    }
  }
  /**
   * Send getProject Request after selection
   * @param projectId 
   */
  getProject = ( projectId: any, type: string = 'default') => {
    this.builderService.showUpdateProgress = true;
    this.builderService.selectedProject = projectId;
    localStorage.setItem("selectedProject", projectId);
    localStorage.removeItem("categories");
    this.blockDataService.getProjectCached().subscribe((project: any) => {
      // Cache the response
      this.builderService.cacheProjectReq.response = project;
      if(type == 'clicked') {
        this.blockDataService.blocksData = JSON.parse(project["data"]);
      }
      // Update the Builder View
      if( this.blockDataService.blocksData == null ) {
        this.blockDataService.blocksData = [];
        let headerData = this.blockFieldsService.getHeaderBlockFields();
        headerData.id = this.blockDataService.getUniqueId(2);
        this.blockDataService.blocksData.unshift(headerData);
      }
      this.blockDataService.sendRenderAllBlocks();
      // Update the Settings
      this.builderService.is_premium = false;
      if(project.premium.length) {
        this.builderService.is_premium = true;
        for (let index = 0; index < project.premium.length; index++) {
          const element = project.premium[index];
          this.builderService.premium_status = element.status;
          this.builderService.stripe_subscription_id = element.stripe_subscription_id;
          this.blockDataService.sendStripeData(element.stripe_subscription_id);
          this.builderService.premium_start_date = element.start_date;
          if(element.status == 'active') {
            this.builderService.active_subscription_plan = element;
            break;
          }
        }
      }
      // if(this.builderService.is_premium == true && this.builderService.premium_status !== 'active') {
      //   this.builderService.premium_status = project[project.length].status;
      // }
      this.builderService.selectedProjectDomain = project.details[0].domain;
      this.builderService.currentProjectType = project.details[0].type;
      this.builderService.user_type = project.user_type;
      this.builderService.projectAccess = project.details[0].access;
      this.builderService.projectStatus = project.details[0].status;
      this.projectUrl = this.builderService.selectedProjectDomain;
      this.categoriesService.getInitialCategories();
      this.builderService.showUpdateProgress = false;
      this.appSettingsService.getContentSettings(this.builderService.selectedProject).subscribe((settings: any) => {
        if (settings.mobile_webview_link !== null) {
          this.builderService.mobile_webview_link = settings.mobile_webview_link;
        }
  
      }, (err) => {
        console.log(err);
      });
      this.finishedFetchingProject = true;
      if(this.blockDataService.selectedView == null) {
        this.blockDataService.selectedView = {
          view_name: 'home',
          view_id: 1,
          title: "home"
        }
      }
    },
    (err) => {
      console.log(err);
    });
  }

  /**
   * Send Update Builder View Request with all blocks data to server after clicking on save btn
   */
  updateBuilderView = () => {
    let allBlocks = this.blockDataService.getAllBlocksData();
    let projectId = this.builderService.selectedProject;
    this.builderService.showUpdateProgress = true;
    this.builderService.updateProject(projectId, allBlocks).subscribe((project) => {
      this.builderService.showUpdateProgress = false;
      this.builderService.logData('Update Project', 'Update Project', 'Clicked on Update Button').subscribe((response) => {

      }, (err) => {
  
      });
      if(this.builderService.getTour()) {
        this.router.navigate(['/app-preview']);
      }
    },
      (err) => {
        console.log(err);
      });

      this.mixPanel.track('Update View', {
        projectid: this.builderService.getSelectedProject(),
      });
  }

  /**
   * Clears Token and redirects to login page
   */
  logout = () => {
    this.loginService.logout();
    this.router.navigate(['/login'], { relativeTo: this.route });
  }

  subscriptionList = () => {
    this.router.navigate(['/admin/subList'], { relativeTo: this.route });
  }

  /**
   * Reset the Tour and navigate to the first step of the tour
   */

  resetTour = () => {
    this.builderService.setTour(true).subscribe((response) => {
      // if( this.builderService.projectAccess !== 'webview' ) {
      //   this.router.navigate(['/connectivity']);
      // } else {
        this.router.navigate(['/site-type']);
        // this.router.navigate(['/templates']);
      // }
      
    }, (err) => {

    })
  }

  updateBuilderGlobals = () => {
    this.builderService.showUpdateProgress = true;
    let project_id = this.builderService.getSelectedProject();
    this.appSettingsService.setGlobalSettings(project_id, this.builderService.globalSettings).subscribe((data: any) => {
      this.builderService.showUpdateProgress = false;
    }, () => {
      this.builderService.showUpdateProgress = false;
    });
    // Get Global Header and Footer
    let global_header = this.builderService.globalHeader;
    let global_footer = this.builderService.globalFooter;
    let globalMenus = this.builderService.globalSettings.menu;
    console.log("globalMenus",globalMenus);
    this.db.setDatabase('projects/' + project_id + '/settings/menus', globalMenus).subscribe((response) => {
      
    });

    this.builderService.updateProject(String(localStorage.getItem("selectedProject")), [global_header], false, 99, 'global_header' ).subscribe((result: any) => {
    });

    this.builderService.updateProject(String(localStorage.getItem("selectedProject")), [global_footer], false, 98, 'global_footer' ).subscribe((result: any) => {
    });

  }

  resetView = () => {
    let requested_view_name = this.blockDataService.selectedView ? this.blockDataService.selectedView.view_name : 'home';
    switch (requested_view_name) {
      case 'categoryView':
        this.blockDataService.blocksData = this.blockDataService.categoryView;
        break;
      case 'singleCategoryView':
        this.blockDataService.blocksData = this.blockDataService.singleCategoryView;
        break;
      case 'singleView':
        this.blockDataService.blocksData = this.blockDataService.singleView;
        break;
      case 'loginView':
        this.blockDataService.blocksData = this.blockDataService.loginView;
        break;
      case 'signupView':
        this.blockDataService.blocksData = this.blockDataService.signupView;
        break;
      case 'forgotView':
        this.blockDataService.blocksData = this.blockDataService.forgotView;
        break;
      case 'myAccountView':
        this.blockDataService.blocksData = this.blockDataService.myAccountView;
        break;
      case 'singleBlogPostView':
        this.blockDataService.blocksData = this.blockDataService.singleBlogPostView;
        break;
      case 'cartView':
        this.blockDataService.blocksData = this.blockDataService.cartView;
        break;
      case 'checkoutView':
        this.blockDataService.blocksData = this.blockDataService.checkoutView;
        break;
      case 'aboutView':
        this.blockDataService.blocksData = this.blockDataService.aboutView;
        break;
      case 'contactView':
        this.blockDataService.blocksData = this.blockDataService.contactView;
        break;
      case 'termsView':
        this.blockDataService.blocksData = this.blockDataService.termsView;
        break;
      default:
        this.blockDataService.blocksData = this.blockDataService.blankView;
        break;
    }
    console.log(requested_view_name, this.blockDataService.blocksData, this.blockDataService.aboutView);
    this.blockDataService.sendRenderAllBlocks();
  }

  purchaseLicense = () => {
    window.open(
      'https://stacksmarket.co/?add-to-cart=15958&variation_id=72793&attribute_packages=premium&product_id='+this.builderService.getSelectedProject(),
      '_blank' // <- This is what makes it open in a new window.
    );
  }

  /**
   * retrieve the url of the admin dashboard and redirect to it
   */
  adminDashboard = () => {
    this.builderService.loginDemoSite().subscribe((response: any) => {
      window.open(
        response,
        '_blank' // <- This is what makes it open in a new window.
      );
    }, (err) => {
      console.log(err);
    });
  }

  changeLang(language: string) {
    localStorage.setItem('siteLang', language);
    this.languageService.setLanguage(language);
    this.languageService.loadTranslations(language)
    .then(() => {
      this.translate.use(language);
    });
  }

  toggleSidebarFn = () => {
    this.builderService.toggleSidebar = !this.builderService.toggleSidebar;
    this.builderService.isListShown = true;
  }
 
}
